import { CampaignType, Scope } from '../shared';

export const queryInsights = {
  id: 'query-insights',
  name: _TL_('Search insights'),
  checkPermission: permissions => permissions.IsQueryInsightsEnabled,
  campaignTypes: [CampaignType.Default],
  scope: [Scope.Account],
  showTag: {
    text: _TL_('New'),
    expDate: new Date('2022/12/31'),
  },
  children: [
    {
      id: 'account-optimization',
      name: _TL_('Account optimization'),
      route: 'accountoptimization',
      checkPermission: permissions => permissions.IsQueryInsightsEnabled,
      campaignTypes: [CampaignType.Default],
      scope: [Scope.Account],
    },
    {
      id: 'new-opportunities',
      name: _TL_('New opportunities'),
      route: 'newopportunities',
      checkPermission: permissions => permissions.IsQueryInsightsEnabled,
      campaignTypes: [CampaignType.Default],
      scope: [Scope.Account],
    },
  ],
};
