import { Scope, ScopeV2 } from '../../shared';

const scope = [Scope.Customer, Scope.Account, Scope.Campaign, Scope.AdGroup];

const scopeV2 = [ScopeV2.Customer, ScopeV2.Account, ScopeV2.Campaign, ScopeV2.AdGroup];

export const uetTags = {
  id: 'uet-tag',
  name: _TL_('UET tag'),
  oldUIScope: [Scope.Customer, Scope.Account],
  scope,
  scopeV2,
  route: 'uettag',
  children: [{
    id: 'uet-tag',
    name: _TL_('UET tag'),
    route: 'uettag',
    showTag: _TL_('New'),
    scope: [Scope.Customer, Scope.Account, Scope.Campaign, Scope.AdGroup],
    scopeV2: [ScopeV2.Customer, ScopeV2.Account, ScopeV2.Campaign, ScopeV2.AdGroup],
  }, {
    id: 'uet-tag-test',
    name: _TL_('Test your tag'),
    route: 'uettagtest',
    scope: [Scope.Customer, Scope.Account, Scope.Campaign, Scope.AdGroup],
    scopeV2: [ScopeV2.Customer, ScopeV2.Account, ScopeV2.Campaign, ScopeV2.AdGroup],
    available: permissions => permissions.dynamic.UETDashboardPhase2,
    checkPermission: permissions => permissions.dynamic.UETDashboardPhase2,
  }, {
    id: 'uet-tag-insights',
    name: _TL_('Insights'),
    route: 'uettaginsights',
    scope: [Scope.Customer, Scope.Account, Scope.Campaign, Scope.AdGroup],
    scopeV2: [ScopeV2.Customer, ScopeV2.Account, ScopeV2.Campaign, ScopeV2.AdGroup],
    available: permissions => permissions.dynamic.UETTagInsights,
    checkPermission: permissions => permissions.dynamic.UETTagInsights,
  }],
};
