import { Scope, isSmartPageStandalone } from '../../../shared';

export const homePage = {
  id: 'mmc-home',
  name: _TL_('Merchant center'),
  scope: [Scope.Account],
  children: [
    {
      id: 'mmc-home',
      name: _TL_('Merchant Center'),
      route: 'merchantcenter',
      scope: [Scope.Account],
      checkPermission: permission =>
        !isSmartPageStandalone(permission) && !permission.IsUnifiedSmartMode && !permission.IsSmartMode,
    },
  ],
};
