import { Scope } from '../shared';

export const myWebsite = {
  id: 'my-website',
  route: 'smartpage',
  default: true,
  isUnifiedProductPage: true,
  scope: [Scope.Account],
  name: _TL_('Smart Pages'),
  checkPermission: permissions => permissions.dynamic.SmartPageUPIntegration,
  metadata: {
    title: _TL_('Smart Pages'),
  },
};
