import { available, scope } from './common';

export const assets = {
  id: 'reports-v4-category-assets',
  name: _TL_('Assets'),
  title: _TL_('Assets'),
  fixedRoute: 'reports-v4/category/assets',
  route: 'reports-v4/category/assets',
  scope,
  available,
  checkPermission: /* istanbul ignore next */ permissions =>
    permissions.dynamic.DefaultAssetReport,
  children: [
    {
      id: 'reports-v4-assetperformancereport',
      name: _TL_('Asset performance'),
      title: _TL_('View the performance of your search and Performance Max assets for each account, campaign, or ad group.'),
      fixedRoute: 'reports-v4/create/assetperformancereport',
      route: 'reports-v4/create/assetperformancereport',
      searchAlias: _TL_('Asset performance report'),
      scope,
      available,
      apiKey: 'AssetPerformanceReport',
      description: _TL_('To view the performance of your search and Performance Max assets for each account, campaign, or ad group.'),
    },
  ],
};
