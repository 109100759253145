import _ from 'underscore';
import { BaseNode } from './base-node';
import { createSchemaField } from './field-creator';
import { pack, unpack } from './viewmodel-util';

const getConvertor = (custom, node) => _.defaults(custom, node.vmField.conversion);

const createDefaultFields = ({ convertor = {}, validator }) => {
  const defaultValidator = (value, node) => {
    const converted = getConvertor(convertor, node).toModel(value);
    return node.vmField.validation.validate(converted);
  };
  const defaultConvertor = {
    fromJSON: (json, node) => {
      const jsonField = unpack(json, node.field);
      _.extend(node, { value: getConvertor(convertor, node).fromModel(jsonField) });
    },
    toJSON: (node) => {
      const jsonField = getConvertor(convertor, node).toModel(node.value);
      return pack(jsonField, node.field);
    },
  };

  return {
    customValidator: _.isFunction(validator) ? validator : null,
    validator: defaultValidator,
    convertor: defaultConvertor,
  };
};

const defaultErrorProcessor = errors => _.first(_.values(errors), 1);

export class ItemNode extends BaseNode {
  constructor(params) {
    super(_.defaults(createDefaultFields(params), params));

    this.vmField = createSchemaField(this.schema, params.i18n, this.field);
    this.errorProcessor = params.errorProcessor || defaultErrorProcessor;
    this.rawValue = {};
  }

  validate(changed) {
    super.validate(changed);
    if (!_.isArray(this.messages)) {
      this.messages = this.errorProcessor(this.messages, changed, this.field, this.schema);
    }
  }

  setSkipValidation(skipValidation) {
    super.setSkipValidation(skipValidation);
    this.validate(this.rawValue);
  }

  set value(newValue) {
    if (newValue !== this.rawValue) {
      this.rawValue = newValue;
      this.validate(newValue);
      this.parent.notifyChildChange(this);
    }
  }

  get value() {
    return this.rawValue;
  }

  get default() {
    this.fromJSON(pack(this.vmField.default, this.field));
    return this.value;
  }
}
