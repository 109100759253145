import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import Uri from 'urijs';
import { withRouter } from '@bingads-webui-react/router';
import { withSettingsConsumer } from '@bingads-webui-react/settings-context';
import { memoize } from '@bingads-webui-universal/primitive-utilities';
import { siteMapPropType } from '../site-map-prop-type';
import { isRouteMatch } from '../site-map-utilities';
import { updateBaseHost } from './path-utilities';

export const updateSelected = (pathname, siteMap) => _.map(siteMap, (item) => {
  const children = updateSelected(pathname, item.children);
  const exact = _.result(item, 'exact', true);
  let route;
  if (item.fixedRoute) {
    route = item.fixedRoute;
  } else {
    route = _.findWhere(children, { route: item.route }) ?
      item.route : _.result(_.first(children), 'route', item.route);
  }
  const selected = isRouteMatch(pathname, item.routePrefix, route, exact) ||
    !!_.findWhere(children, { selected: true });
  return _.extend(item, { selected, children, route });
});

export const updateSitemapBaseHost = (siteMap, pathname, settingsContext) =>
  updateBaseHost(
    updateSelected(pathname, siteMap),
    (new Uri()).normalize(),
    settingsContext
  );

const updateSitemapBaseHostCached = memoize(updateSitemapBaseHost);

export function processPath(Component, useCache = false) {
  const Wrapper = ({ siteMap, ...props }) => {
    const { location: { pathname }, settingsContext } = props;
    const processorFunc = useCache ? updateSitemapBaseHostCached : updateSitemapBaseHost;
    const processedSiteMap = processorFunc(siteMap, pathname, settingsContext);

    return (<Component {...props} siteMap={processedSiteMap} />);
  };

  Wrapper.propTypes = {
    siteMap: siteMapPropType.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    settingsContext: PropTypes.shape({
      Urls: PropTypes.object.isRequired,
    }),
  };

  Wrapper.defaultProps = {
    settingsContext: {
      Urls: {},
    },
  };

  return withSettingsConsumer(withRouter(Wrapper));
}
