import {
  Scope,
  alwaysDisplayed,
  buildTree,
  defaultItem,
} from '../configs/shared';
import { overview } from '../configs/campaign/overview';
import { recommendations } from '../configs/campaign/recommendations';
import { campaigns } from '../configs/campaign/campaigns';
import { adGroups } from '../configs/campaign/adgroups';
import { products } from '../configs/campaign/products';
import { adsAndExtensions } from '../configs/campaign/ads-and-extensions';
import { landingPages } from '../configs/campaign/landing-pages';
import { dynamicAdTargets } from '../configs/campaign/dynamic-ad-targets';
import { feedsItems } from '../configs/campaign/feeds-items';
import { keywords } from '../configs/campaign/keywords';
import { productGroups } from '../configs/campaign/product-groups';
import { hotelGroups, propertyGroups } from '../configs/campaign/hotel-groups';
import { hotels, properties } from '../configs/campaign/hotels';
import { audiences } from '../configs/campaign/audiences';
import { demographics } from '../configs/campaign/demographics';
import { settings } from '../configs/campaign/settings';
import { locations } from '../configs/campaign/locations';
import { adSchedule } from '../configs/campaign/ad-schedule';
import { devices } from '../configs/campaign/devices';
import { changeHistory } from '../configs/campaign/change-history';
import { competition } from '../configs/campaign/competition';
import { experiments } from '../configs/campaign/experiments';
import { queryInsights } from '../configs/campaign/query-insights';
import { appAdsAssetGroups, assetGroups } from '../configs/campaign/asset-groups';
import { listingGroups } from '../configs/campaign/listing-groups';
import { assetsReport } from '../configs/campaign/assets-report';

export const advertise = {
  id: 'advertise',
  name: _TL_('Campaigns'),
  description: _TL_('View performance, account information, recommendations, and more.'),
  scope: [Scope.Account, Scope.Campaign],
  campaignTypes: alwaysDisplayed,
  processChildScope: true,
  fixed: true,
  route: 'overview',
  children: buildTree([
    overview,
    recommendations,
    queryInsights,
    campaigns,
    adGroups,
    assetGroups,
    appAdsAssetGroups,
    listingGroups,
    products,
    adsAndExtensions,
    assetsReport,
    landingPages,
    keywords,
    dynamicAdTargets,
    feedsItems,
    productGroups,
    hotelGroups,
    propertyGroups,
    hotels,
    properties,
    audiences,
    demographics,
    settings,
    locations,
    adSchedule,
    devices,
    changeHistory,
    competition,
    experiments,
  ], defaultItem, true, undefined, true),
};
