import { Scope, alwaysDisplayedItem, buildTree } from '../configs/shared';
import { labelsPage } from '../configs/tools/shared-library/labels';

const labels = {
  id: 'labels',
  name: _TL_('Labels'),
  description: _TL_('Organize your campaigns, ad groups, and more with labels.'),
  route: 'labels',
  oldUIScope: [Scope.Account, Scope.Customer],
  scope: [Scope.Account, Scope.Customer],
  children: [
    {
      id: 'labels-pages',
      ...labelsPage,
    }],
};

const tree = buildTree([labels], alwaysDisplayedItem, true, undefined, true)[0];

export { tree as labels };
