import { defaultScope, defaultScopeV2 } from '../configs/tools/setup/merchant-center/merchant-shared';

import { feeds } from '../configs/tools/setup/merchant-center/feeds';
import { importFeed } from '../configs/tools/setup/merchant-center/import';
import { promotions } from '../configs/tools/setup/merchant-center/promotions';
import { settings } from '../configs/tools/setup/merchant-center/settings';
import { storeSummary } from '../configs/tools/setup/merchant-center/summary';
import { stores } from '../configs/tools/setup/merchant-center/stores';
import { products } from '../configs/tools/setup/merchant-center/products';

// those are not shown in the L2 menu. But add them to sitemap so that L1 item is highlighted
const storesRoute = stores.children.map(store => ({
  ...store,
  searchable: false,
}));

export const merchantCenter = {
  id: 'merchant-center',
  name: _TL_('Merchant Center'),
  description: _TL_('Manage your product catalogs to run shopping campaigns.'),
  scope: defaultScope,
  scopeV2: defaultScopeV2,
  route: 'merchantcenter',
  processChildScope: true,
  children: [
    {
      ...storeSummary,
      name: _TL_('Summary'),
    },
    {
      ...feeds,
      name: _TL_('Feeds'),
    },
    {
      ...importFeed,
      name: _TL_('Import'),
    },
    {
      ...promotions,
      name: _TL_('Promotions'),
    },
    {
      ...settings,
      name: _TL_('Settings'),
    },
    {
      ...products,
      name: _TL_('Product Explorer'),
    },
    ...storesRoute,
  ],
};
