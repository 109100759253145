import { Scope } from '../shared';

export const accountsSummary = {
  id: 'accounts-summary',
  name: _TL_('Accounts summary'),
  scope: [Scope.Customer],
  children: [
    {
      id: 'performance',
      name: _TL_('Performance'),
      route: 'accounts/performance',
      available: permissions => permissions.dynamic.WebUIVnext,
      scope: [Scope.Customer],
    },
    {
      id: 'management',
      name: _TL_('Management'),
      route: 'accounts/management',
      available: permissions => permissions.dynamic.WebUIVnext,
      scope: [Scope.Customer],
    }, {
      id: 'requests',
      name: _TL_('Requests'),
      route: 'accounts/requests',
      available: /* istanbul ignore next */ permissions => permissions.dynamic.WebUIVnext,
      checkPermission: permissions => permissions.IsRequestsTabEnabled,
      scope: [Scope.Customer],
    }, {
      id: 'notifications',
      name: _TL_('Notifications'),
      route: 'accounts/notifications',
      available: permissions => permissions.dynamic.WebUIVnext,
      scope: [Scope.Customer],
    }, {
      id: 'import',
      name: _TL_('Import'),
      route: 'accounts/import',
      available: /* istanbul ignore next */ permissions => permissions.dynamic.WebUIVnext,
      checkPermission: permissions => permissions.IsAccountLevelImportStatusGridEnabled,
      scope: [Scope.Customer],
    },
  ],
};
