import React from 'react';
import { memoize } from '@bingads-webui-universal/primitive-utilities';

import { siteMapPropType } from '../site-map-prop-type';
import { filterMapItem } from './filter-map-item';

const filterMapItemBase = (siteMap) => {
  const filter = (mapItem) => {
    if (mapItem.ccuiOnlyPage) {
      return false;
    }
    return true;
  };

  return filterMapItem(siteMap, filter);
};

const filterMapItemCached = memoize(filterMapItemBase);

export function processCcuiContext(Component) {
  const Wrapper = ({ siteMap, ...props }) => {
    const processed = filterMapItemCached(siteMap);

    return (<Component {...props} siteMap={processed} />);
  };

  Wrapper.propTypes = {
    siteMap: siteMapPropType.isRequired,
  };

  return Wrapper;
}
