export const clarityWebsite = {
  id: 'clarity-website',
  name: _TL_('Microsoft Clarity'),
  children: [{
    id: 'clarity-website',
    name: _TL_('Microsoft Clarity'),
    route: 'claritywebsite',
    externalLink: 'https://clarity.microsoft.com',
  }],
};
