import { defaultScopes, alwaysDisplayed, ScopeV2 } from '../shared';

ScopeV2.add('ShareWithUserIds', 'shareWithUserIds', ScopeV2.Account);

const scopeV2 = [ScopeV2.Customer, ScopeV2.Account, ScopeV2.ShareWithUserIds];

export const importCredentialIdPages = {
  id: 'import-credential-id',
  name: _TL_('Import credential ID'),
  checkPermission: permissions => permissions.dynamic.GoogleImportAsService,
  route: 'importcredentialid',
  campaignTypes: alwaysDisplayed,
  scope: defaultScopes,
  searchable: true,
  scopeV2,
};
