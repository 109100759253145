import { Scope, ScopeV2, CampaignType } from '../shared';

ScopeV2.add('AssetGroup', 'assetGroupId', ScopeV2.Campaign);

export const pmaxDetails = {
  id: 'pmax-asset-details',
  name: _TL_('Asset details'),
  scope: [Scope.Campaign],
  scopeV2: [ScopeV2.AssetGroup],
  campaignTypes: [CampaignType.PerformanceMax],
  children: [
    {
      id: 'pmax-assets',
      name: _TL_('Assets'),
      scope: [Scope.Campaign],
      scopeV2: [ScopeV2.AssetGroup],
      campaignTypes: [CampaignType.PerformanceMax],
      route: 'assetgroups/assets',
      searchable: false,
      checkPermission: permissions => permissions.dynamic.PerformanceMaxCampaigns,
    },
    {
      id: 'pmax-combinations',
      name: _TL_('Combinations'),
      scope: [Scope.Campaign],
      scopeV2: [ScopeV2.AssetGroup],
      campaignTypes: [CampaignType.PerformanceMax],
      route: 'assetgroups/combinations',
      searchable: false,
      checkPermission: permissions => permissions.dynamic.PerformanceMaxCampaigns,
    },
  ],
};


export const assetGroupCreate = {
  id: 'asset-group-create',
  name: _TL_('Create asset group'),
  scope: [Scope.Campaign],
  scopeV2: [ScopeV2.Campaign],
  campaignTypes: [CampaignType.PerformanceMax],
  children: [
    {
      id: 'asset-group-create',
      name: _TL_('Create asset group'),
      route: 'assetgroups/create',
      checkPermission: permissions => permissions.dynamic.PerformanceMaxCampaigns,
    },
  ],
};

export const assetGroupEdit = {
  id: 'asset-group-edit',
  name: _TL_('Edit asset group'),
  scope: [Scope.Campaign],
  scopeV2: [ScopeV2.AssetGroup],
  campaignTypes: [CampaignType.PerformanceMax],
  children: [
    {
      id: 'asset-group-edit',
      name: _TL_('Edit asset group'),
      route: 'assetgroups/edit',
      checkPermission: permissions => permissions.dynamic.PerformanceMaxCampaigns,
    },
  ],
};
