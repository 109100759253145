import _ from 'underscore';
import React, { useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ServerErrorContext, ServerErrorEntityContext } from './server-error-context';
import { matchesField } from '../helpers/server-error-utils';

export const useServerError = (props) => {
  const {
    serverErrorProps,
    passiveMessages = [],
  } = props;
  const {
    clientId,
    fieldSelector,
    entity: propEntity,
    code,
  } = serverErrorProps || {};
  const { errors } = useContext(ServerErrorContext);
  const contextEntity = useContext(ServerErrorEntityContext);
  const entity = propEntity || contextEntity || '';

  return useMemo(() => {
    let matchedErrors = [];
    if (errors && errors[entity]) {
      matchedErrors =
        _.flatten(_.values(_.pick(errors[entity], (v, k) => matchesField(k, fieldSelector))));
    }
    if (code) {
      matchedErrors = _.filter(matchedErrors, e => matchesField(e.errorCode, code));
    }
    if (clientId) {
      matchedErrors = _.filter(matchedErrors, e => matchesField(e.clientId, clientId));
    }
    const errorMessages = _.map(matchedErrors, e => e.message);
    return [...passiveMessages, ...errorMessages];
  }, [clientId, entity, errors, fieldSelector, passiveMessages, code]);
};

export const withServerError = (Component) => {
  const HOC = React.memo((props) => {
    const {
      serverErrorProps,
      validationProps,
    } = props;
    const { fieldSelector, entity: propEntity } = serverErrorProps || {};
    const { passiveMessages = [], onValidate } = validationProps || {};
    const { removeErrors } = useContext(ServerErrorContext);
    const contextEntity = useContext(ServerErrorEntityContext);
    const entity = propEntity || contextEntity || '';


    const messages = useServerError({ serverErrorProps, passiveMessages });

    const validate = useCallback((item) => {
      if (_.isFunction(onValidate)) {
        onValidate(item);
      }
      removeErrors({ entity, fieldSelector });
    }, [entity, fieldSelector, onValidate, removeErrors]);

    const componentProps = {
      ..._.omit(props, 'serverErrorProps'),
      validationProps: {
        ...validationProps,
        passiveMessages: messages,
        onValidate: validate,
      },
    };

    return (
      <Component {...componentProps} />
    );
  });


  HOC.propTypes = {
    serverErrorProps: PropTypes.shape({
      fieldSelector: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      entity: PropTypes.string,
      code: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
    }),
    validationProps: PropTypes.shape({
      onValidate: PropTypes.func,
      passiveMessages: PropTypes.arrayOf(PropTypes.string),
    }),
  };

  HOC.defaultProps = {
    validationProps: {},
    serverErrorProps: { fieldSelector: '' },
  };

  return HOC;
};
