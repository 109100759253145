import { Scope, ScopeV2, CampaignType } from '../shared';

ScopeV2.add('AssetGroup', 'assetGroupId', ScopeV2.Campaign);

export const listingGroups = {
  id: 'listing-groups',
  name: _TL_('Listing groups'),
  campaignTypes: [CampaignType.PerformanceMax],
  scope: [Scope.Campaign],
  scopeV2: [ScopeV2.AssetGroup],
  checkPermission: permissions => permissions.dynamic.PerformanceMaxCampaigns,
  children: [
    {
      id: 'listing-groups',
      name: _TL_('Listing groups'),
      route: 'listinggroups/list',
      scope: [Scope.Campaign],
      scopeV2: [ScopeV2.AssetGroup],
      children: [
        {
          id: 'hierarchy',
          name: _TL_('Listing groups'),
          route: 'listinggroups/hierarchy',
        },
        {
          id: 'list',
          name: _TL_('List View'),
          route: 'listinggroups/list',
        },
      ],
    },
  ],
};
