import _ from 'underscore';
import { Scope } from '../shared';
import { planning } from './planning';
import { sharedLibrary } from './shared-library/index';
import { bulkActions } from './bulk-actions';
import { conversionTracking } from './conversion-tracking';
import { setup } from './setup/index';
import { mobileApp } from './app';

const toolsPages = [
  planning,
  ...sharedLibrary,
  bulkActions,
  ...conversionTracking,
  ...setup,
];

const flatSharedLibrary = {
  id: 'shared-library',
  name: _TL_('Shared Library'),
  oldUIScope: [Scope.Account],
};
flatSharedLibrary.children = sharedLibrary;

const flatSetup = {
  id: 'setup',
  name: _TL_('Setup'),
  oldUIScope: [Scope.Account],
};
flatSetup.children = _.flatten(_.map(setup, value => value.children[0]));

const flatConversionTracking = {
  id: 'conversion-tracking',
  name: _TL_('Conversion tracking'),
  oldUIScope: [Scope.Account],
  children: _.flatten(_.map(
    conversionTracking,
    value => value.children[0]
  )),
};

const flatToolsPages = [
  planning,
  flatSharedLibrary,
  bulkActions,
  flatConversionTracking,
  flatSetup,
];

const flatSmartToolsPages = [
  flatSetup,
  mobileApp,
];

const flatSetupPages = [
  flatSetup,
];

export { flatToolsPages, flatSmartToolsPages, toolsPages, flatSetupPages };
