import { Scope } from '../configs/shared';
import { homePage, imagesPage, videosPage, performancePage, libraryPage } from '../configs/tools/shared-library/ad-studio';

export const adStudio = {
  id: 'ad-studio-app-entry',
  name: _TL_('Ad Studio'),
  description: _TL_('Manage all of your creative assets in one place.'),
  oldUIScope: [Scope.Account],
  scope: [Scope.Account],
  route: 'adstudio',
  children: [
    homePage,
    imagesPage,
    videosPage,
    performancePage,
    libraryPage,
  ],
  checkPermission: permissions => permissions.dynamic.AdsStudioMVP,
};
