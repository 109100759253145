import { AccountLinkPermissionType, isMultiAgencyBillingEnabled } from '@bingads-webui/multi-agency-utils';

import { allScopes } from '../shared';

export const unaccessible = {
  id: 'unaccessibleStandalone',
  name: _TL_('Unaccessible'),
  scope: allScopes,
  children: [
    {
      id: 'unaccessible',
      name: _TL_('Unaccessible'),
      route: 'Unaccessible',
      scope: allScopes,
      accountLinkPermissions: [AccountLinkPermissionType.Billing],
      checkPermission: permissions => isMultiAgencyBillingEnabled(permissions) && !permissions.IsInternalUser,
    },
  ],
};
