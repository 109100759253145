import { Scope } from '../configs/shared';
import { businessLocationFeed } from '../configs/tools/shared-library/business-location-feed';

export const businessLocation = {
  id: 'business-location',
  name: _TL_('Business locations'),
  description: _TL_('Sync location data from Google My Business or Bing Places.'),
  oldUIScope: [Scope.Account],
  children: businessLocationFeed,
};
