import _ from 'underscore';
import { CampaignType, Scope } from '../shared';

export const products = {
  id: 'products',
  name: _TL_('Products'),
  campaignTypes: [CampaignType.Shopping, CampaignType.Audience, CampaignType.PerformanceMax],
  scope: [Scope.Campaign, Scope.AdGroup],
  currentCampaign: campaign => _.isEmpty(campaign) ||
    (_.result(CampaignType, campaign.CampaignType) === CampaignType.Shopping) ||
    (_.result(CampaignType, campaign.CampaignType) === CampaignType.PerformanceMax) ||
    (_.result(CampaignType, campaign.CampaignType) === CampaignType.Audience &&
    _.has(_.take(campaign.CampaignSettings), 'ProviderId') &&
    campaign.CampaignSubType !== 'AudienceDynamicFeedBased'),
  children: [
    {
      id: 'products',
      name: _TL_('Products'),
      route: 'products',
      scope: [Scope.Campaign, Scope.AdGroup],
    },
  ],
};
