import { CampaignType, Scope } from '../shared';

export const assetGroups = {
  id: 'asset-groups',
  name: _TL_('Asset groups'),
  campaignTypes: [CampaignType.PerformanceMax],
  scope: [Scope.Campaign],
  checkPermission: permissions => permissions.dynamic.PerformanceMaxCampaigns,
  children: [
    {
      id: 'asset-groups',
      name: _TL_('Asset groups'),
      route: 'assetgroups',
      scope: [Scope.Campaign],
      checkPermission: permissions => permissions.dynamic.PerformanceMaxCampaigns,
    },
  ],
};

export const appAdsAssetGroups = {
  id: 'appads-asset-groups',
  name: _TL_('Asset groups'),
  scope: [Scope.Campaign],
  campaignTypes: [CampaignType.App],
  checkPermission: permissions => permissions.dynamic.AppAdsCampaignPhaseOne,
  children: [
    {
      id: 'appads-asset-groups',
      name: _TL_('Asset groups'),
      route: 'appadsassetgroups',
      scope: [Scope.Campaign],
      checkPermission: permissions => permissions.dynamic.AppAdsCampaignPhaseOne,
    },
  ],
};
