import _ from 'underscore';
import { CampaignType, ScopeV2 } from '../shared';

ScopeV2.add('RecSrc', 'recsrc', ScopeV2.Account);
ScopeV2.add('RecSrc', 'recsrc', ScopeV2.Campaign);
ScopeV2.add('RecSrc', 'recsrc', ScopeV2.AdGroup);

const scopeV2 = [ScopeV2.Account, ScopeV2.Campaign, ScopeV2.AdGroup, ScopeV2.RecSrc];

export const competition = {
  id: 'competition',
  name: _TL_('Competition'),
  campaignTypes: [CampaignType.Default, CampaignType.Shopping],
  checkPermission: permissions => !permissions.IsHideAuctionInsightsCompetitionUI && (!permissions.IsDomesticSimplifiedChineseEnabled || (permissions.IsDomesticSimplifiedChineseEnabled && _.get(permissions, ['dynamic', 'AuctionInsightForCN'], false))),
  scopeV2,
  children: [
    {
      id: 'auction-insights',
      name: _TL_('Auction insights'),
      route: 'competitionauction',
      campaignTypes: [CampaignType.Default, CampaignType.Shopping],
      scopeV2,
    }, {
      id: 'competition-recommendations',
      name: _TL_('Deeper insights and actions'),
      route: 'competitionrecommendations',
      campaignTypes: [CampaignType.Default, CampaignType.Shopping],
      scopeV2,
    },
  ],
};
