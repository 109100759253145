import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { withScope, QueryParam } from '@bingads-webui-react/scope-service';
import { getCurrentScope } from './processor-helpers';
import { siteMapPropType } from '../site-map-prop-type';

const idToMatch = 'site-exclusion-lists-entrypoint';
const sharedLibraryId = 'shared-library';
const siteExclusionId = 'website-exclusion';
const brandSafetyControls = 'brand-safety-controls';

export function processSharedLibrary(Component) {
  const Wrapper = ({ siteMap, ...props }) => {
    const {
      scope: {
        values: {
          aid, campaignId, adGroupId,
        },
      },
    } = props;

    const currentScope = getCurrentScope(campaignId, adGroupId, aid);

    const updatedSiteMap = _.map(siteMap, (item) => {
      if (item.id === sharedLibraryId || item.id === siteExclusionId || item.id === brandSafetyControls) {
        const children = _.reject(
          _.map(
            item.children,
            child => ({ ...child.children[0], name: child.name })
          ),
          i => i.id === idToMatch && !_.contains(i.scope, currentScope)
        );
        return _.defaults({ children }, item);
      }
      return item;
    });

    return (<Component {...props} siteMap={updatedSiteMap} />);
  };

  Wrapper.propTypes = {
    siteMap: siteMapPropType.isRequired,
    scope: PropTypes.instanceOf(QueryParam).isRequired,
  };

  return withScope(Wrapper);
}
