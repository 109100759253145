import { available, scope } from './common';

export const advancedInsights = {
  id: 'reports-v4-category-advancedinsights',
  name: _TL_('Advanced insights'),
  title: _TL_('Find advanced insights into how your advertisers are performing'),
  fixedRoute: 'reports-v4/category/advancedinsights',
  route: 'reports-v4/category/advancedinsights',
  scope,
  available,
  checkPermission: /* istanbul ignore next */ permissions =>
    !!permissions.IsInternalUser,
  children: [
    {
      id: 'reports-v4-sixweekaccountactivityreport',
      name: _TL_('Account - 6 weeks'),
      title: _TL_('See how individual accounts are performing with excel charts and graphs'),
      fixedRoute: 'reports-v4/create/sixweekaccountactivityreport',
      route: 'reports-v4/create/sixweekaccountactivityreport',
      searchAlias: _TL_('Account - 6 weeks report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'AccountPerformanceReport-SixWeeks',
      description: _TL_('See how individual accounts are performing with excel charts and graphs'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.IsInternalUser,
    }, {
      id: 'reports-v4-twomonthaccountactivityreport',
      name: _TL_('Account - 2 months (MoM)'),
      title: _TL_('See how individual accounts are performing with excel charts and graphs'),
      fixedRoute: 'reports-v4/create/twomonthaccountactivityreport',
      route: 'reports-v4/create/twomonthaccountactivityreport',
      searchAlias: _TL_('Account - 2 months (MoM) report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'AccountPerformanceReport-TwoMonths',
      description: _TL_('See how individual accounts are performing with excel charts and graphs'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.IsInternalUser,
    }, {
      id: 'reports-v4-twoyearaccountactivityreport',
      name: _TL_('Account - 2 years (YoY)'),
      title: _TL_('See how individual accounts are performing with excel charts and graphs'),
      fixedRoute: 'reports-v4/create/twoyearaccountactivityreport',
      route: 'reports-v4/create/twoyearaccountactivityreport',
      searchAlias: _TL_('Account - 2 years (YoY) report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'AccountPerformanceReport-TwoYears',
      description: _TL_('See how individual accounts are performing with excel charts and graphs'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.IsInternalUser,
    }, {
      id: 'reports-v4-advancedinsightsshareofvoicereport',
      name: _TL_('Account - Share of voice'),
      title: _TL_('See estimated impressions you might be losing.'),
      fixedRoute: 'reports-v4/create/advancedinsightsshareofvoicereport',
      route: 'reports-v4/create/advancedinsightsshareofvoicereport',
      searchAlias: _TL_('Account - Share of voice report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'AccountPerformanceReport-ShareOfVoice',
      description: _TL_('To view impression share (%) of successful bids for each keyword, and identify opportunities to increase impression share.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.IsInternalUser,
    },
  ],
};
