import { Scope } from '../shared';

export const scriptsPage = {
  id: 'scripts',
  name: _TL_('Scripts'),
  scope: [Scope.Customer, Scope.Account],
  children: [
    {
      id: 'scripts-page',
      name: _TL_('Scripts'),
      route: 'scripts',
      scope: [Scope.Customer, Scope.Account],
    }, {
      id: 'scripts-history',
      name: _TL_('Scripts History'),
      route: 'scriptshistory',
      scope: [Scope.Customer, Scope.Account],
    },
  ],
};

