import { Scope, AccountLinkPermissionType } from '../shared';

export const billingAccountLinkPermissions = [
  AccountLinkPermissionType.Standard,
  AccountLinkPermissionType.Billing,
];

export const billingSummary = {
  id: 'billing',
  name: _TL_('Billing summary'),
  route: 'billing',
  accountLinkPermissions: billingAccountLinkPermissions,
};

export const transactions = {
  id: 'transactions',
  name: _TL_('Transactions'),
  route: 'Transactions/BillingDocuments',
  accountLinkPermissions: billingAccountLinkPermissions,
  children: [
    {
      id: 'transactionBillingDocuments',
      name: _TL_('Billing documents'),
      route: 'Transactions/BillingDocuments',
    },
    {
      id: 'transactionHistory',
      name: _TL_('Transaction history'),
      route: 'Transactions/TransactionHistory',
      scope: [Scope.Account],
    },
  ],
};

export const paymentMethods = {
  id: 'paymentMethods',
  name: _TL_('Payment methods'),
  route: 'PaymentMethods',
  accountLinkPermissions: billingAccountLinkPermissions,
};

export const xandrBaf = {
  id: 'xandrBaf',
  name: _TL_('BAF'),
  route: 'XandrBaf',
  scope: [Scope.Customer],
  checkPermission: permissions => !!permissions.IsInternalUser && !!permissions.dynamic.XandrBaf && !!permissions.IsCustomerMappedToXandrParentAccount,
  ccuiOnlyPage: true,
  children: [
    {
      id: 'xandrBaf',
      name: _TL_('BAF'),
      route: 'XandrBaf',
    },
    {
      id: 'xandrBafCreate',
      name: _TL_('Create BAF'),
      route: 'XandrBaf/Create',
      searchable: false,
    },
    {
      id: 'xandrBafEdit',
      name: _TL_('Edit BAF'),
      route: 'XandrBaf/Edit',
      searchable: false,
    },
    {
      id: 'xandrBafProfile',
      name: _TL_('BAF Profile'),
      route: 'XandrBaf/Profile',
      searchable: false,
    },
  ],
};

export const adjustments = {
  id: 'adjustments',
  name: _TL_('Adjustments'),
  route: 'Adjustments',
  scope: [Scope.Account],
  checkPermission: permissions => !!permissions.IsInternalUser,
};

export const balance = {
  id: 'balance',
  name: _TL_('Balance'),
  route: 'Balance',
  scope: [Scope.Account],
  checkPermission: permissions => !!permissions.IsInternalUser,
};

export const insertionOrders = {
  id: 'insertionOrder',
  name: _TL_('Insertion orders'),
  route: 'InsertionOrder',
  accountLinkPermissions: billingAccountLinkPermissions,
};

export const billingSettings = {
  id: 'billingSettings',
  name: _TL_('Billing settings'),
  route: 'BillingSettings',
  accountLinkPermissions: billingAccountLinkPermissions,
  ccuiOnlyPage: true,
  children: [
    {
      id: 'billingSettings',
      name: _TL_('Billing settings'),
      route: 'BillingSettings',
    },
    {
      id: 'consolidatedBilling',
      name: _TL_('Consolidated billing'),
      route: 'BillingSettings/ConsolidatedBilling',
      checkPermission: permissions => permissions.dynamic.ConsolidatedBillingV2,
    },
    {
      id: 'sapIdInformation',
      name: _TL_('SAP ID information'),
      route: 'BillingSettings/SapIdInformation',
      checkPermission: permissions => permissions.dynamic.ConsolidatedBillingV2,
    },
  ],
};

export const billingPayments = {
  id: 'billingHistory',
  name: _TL_('Billing & Payments'),
  route: 'Billing/History',
  fixedRoute: 'Billing/History',
  children: [
    billingSummary,
    paymentMethods,
    insertionOrders,
    billingSettings,
    adjustments,
    balance,
    transactions,
    xandrBaf,
  ],
};
