import { ChangeSource, PluginEventType } from 'roosterjs';

import { getLastClipboardData } from '../editor-ribbon/get-last-clipboard-data';

export class RibbonPlugin {
  initialize(editor) {
    this.editor = editor;
  }

  dispose() {
    this.editor = null;
  }

  getEditor() {
    return this.editor;
  }

  registerRef = (ref) => {
    this.ribbon = ref && ref.current;
  };

  onPluginEvent(event) {
    if (this.ribbon) {
      // Update the paste data only when user has tried to paste something, otherwise set it to null
      if (
        event.eventType === PluginEventType.KeyDown ||
        event.eventType === PluginEventType.MouseUp
      ) {
        const clipboardDataWrapper = getLastClipboardData(this.editor);
        clipboardDataWrapper.data = null;
        this.ribbon.forceUpdate();
      } else if (event.eventType === PluginEventType.ContentChanged) { // Only trigger by paste
        const clipboardDataWrapper = getLastClipboardData(this.editor);

        if (event.source === ChangeSource.Paste) {
          clipboardDataWrapper.data = event.data;
        } else {
          clipboardDataWrapper.data = null;

          if (event.source === ChangeSource.CreateLink || event.source === ChangeSource.AutoLink) {
            // Replace the node target attr directly
            const hyperLinkNode = event.data;
            hyperLinkNode.target = '_blank';
          }
        }

        this.ribbon.forceUpdate();
      } else if (event.eventType === PluginEventType.EditorReady) {
        this.ribbon.forceUpdate();
      }
    }
  }
}
