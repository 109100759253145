import { defaultUpItemProperties, isUnifiedProductEnabled, isSmartPageStandalone } from '../../../shared';

const privacyAndCookiesLink = 'https://privacy.microsoft.com/en-us/PrivacyStatement';

export const upPrivacyCookies = {
  id: 'up-privacy-cookies',
  name: _TL_('Privacy and cookies'),
  children: [
    {
      ...defaultUpItemProperties,
      id: 'up-privacy-cookies',
      name: _TL_('Privacy and cookies'),
      externalLink: privacyAndCookiesLink,
      checkPermission: p => isUnifiedProductEnabled(p) || isSmartPageStandalone(p),
    },
  ],
};
