import { useEffect, useRef, useState } from 'react';
import { ResponsiveSizeBreakpoints } from './responsive-sizes';

/*
The latest version 2xx has useMedia hook from 'react-meida'
Due to the possible break change between react-media@1.9.2 to react-meida@2.0.0-rc.1, we decide to directly import the useMedia hook from 'react-media'.
Once we are clear to upgrade, the line 9 to line 88 can be replaced with import { useMedia } from "react-media";
*/

class MediaQueryListener {
  constructor(targetWindow, query, listener) {
    this.nativeMediaQueryList = targetWindow.matchMedia(query);
    this.active = true;
    // Safari doesn't clear up listener with removeListener
    // when the listener is already waiting in the event queue.
    // Having an active flag to make sure the listener is not called
    // after we removeListener.
    this.cancellableListener = (...args) => {
      this.matches = this.nativeMediaQueryList.matches;
      if (this.active) {
        listener(...args);
      }
    };
    this.nativeMediaQueryList.addListener(this.cancellableListener);
    this.matches = this.nativeMediaQueryList.matches;
  }

  cancel() {
    this.active = false;
    this.nativeMediaQueryList.removeListener(this.cancellableListener);
  }
}

const useMedia = ({ queries, targetWindow, onChange }) => {
  const activeQueries = useRef([]);
  const getMatches = () => {
    const result = activeQueries.current.reduce(
      (acc, { name, mqListener }) => ({ ...acc, [name]: mqListener.matches }),
      {}
    );
    return result;
  };

  const updateMatches = () => {
    // eslint-disable-next-line no-use-before-define
    setMatches(getMatches());
  };

  const setUpMQLs = () => {
    const activeTargetWindow = targetWindow || window;

    const queryObject = queries;

    activeQueries.current = Object.keys(queryObject).map((name) => {
      const currentQuery = queryObject[name];
      const qs = currentQuery;
      const mqListener = new MediaQueryListener(
        activeTargetWindow,
        qs,
        updateMatches
      );

      return { name, mqListener };
    });
  };

  const [matches, setMatches] = useState(() => {
    if (typeof window !== 'object') {
      /* if (props.queries) */
      return Object.keys(queries).reduce(
        (acc, key) => ({ ...acc, [key]: true }),
        {}
      );
    }
    setUpMQLs();
    return getMatches();
  });


  useEffect(
    () => () => activeQueries.current.forEach(({ mqListener }) => mqListener.cancel()),
    []
  );

  useEffect(
    () => {
      if (onChange) {
        onChange(matches);
      }
    },
    [matches, onChange]
  );
  return matches;
};

export const useResponsiveSize = (sizes = ResponsiveSizeBreakpoints, offset = 0) => {
  const sortedSizes = sizes.sort((a, b) => b.minWidth - a.minWidth);
  let currentSize = sortedSizes[sortedSizes.length - 1];

  const queries = sizes.reduce((dict, query) => ({ ...dict, [query.name]: `(min-width: ${query.minWidth + offset}px)` }), {});

  const innerMatches = useMedia({ queries, targetWindow: window });

  for (let i = 0; i < sortedSizes.length; i += 1) {
    if (innerMatches[sortedSizes[i].name]) {
      currentSize = sortedSizes[i];
      break;
    }
  }

  return currentSize.name;
};
