import { landingPage } from './landingpage';
import { landingPageDetail } from './landingpage-detail';
import { propertiesPage } from './properties';
import { propertyPage } from './property';
import { pricingStatusPage } from './pricing-status';
import { pricingSettingPage } from './pricing-settings';

const propertiesPages = [propertiesPage, propertyPage];
const pricingPages = [pricingStatusPage, pricingSettingPage];
const landingPages = [landingPage, landingPageDetail];

export const feedsPages = {
  id: 'property-center-feeds',
  name: _TL_('Feeds'),
  route: 'propertycenter/feeds',
  children: [
    {
      id: 'property-center-properties',
      name: _TL_('Properties'),
      route: 'propertycenter/properties',
      children: propertiesPages,
    },
    {
      id: 'property-center-pricing',
      name: _TL_('Pricing'),
      route: 'propertycenter/pricing-status',
      children: pricingPages,
    },
    {
      id: 'property-center-landing',
      name: _TL_('Landing page'),
      route: 'propertycenter/landingpage',
      children: landingPages,
    },
  ],
};
