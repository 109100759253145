import { Scope } from '../../shared';

export const landingPage = {
  id: 'ad-studio-landing-page',
  name: _TL_('Ad Studio'),
  route: 'adstudio',
  scope: [Scope.Account],
  apexViewName: 'adstudio',
  searchable: true,
};

export const homePage = {
  id: 'ad-studio-home-page',
  name: _TL_('Home'),
  route: 'adstudio/home',
  scope: [Scope.Account],
  apexViewName: 'adstudio',
  searchable: true,
};

export const generateImagePage = {
  id: 'ad-studio-generate-image-page',
  name: _TL_('Generate Image'),
  route: 'adstudio/generateimage',
  scope: [Scope.Account],
  apexViewName: 'adstudio',
  searchable: true,
};

export const homeSearchPage = {
  id: 'ad-studio-home-search-page',
  name: _TL_('Images'),
  route: 'adstudio/home/search',
  scope: [Scope.Account],
  apexViewName: 'adstudio',
  searchable: false,
};

export const imagesPage = {
  id: 'ad-studio-images-page',
  name: _TL_('Images'),
  route: 'adstudio/images',
  scope: [Scope.Account],
  apexViewName: 'adstudio',
  searchable: true,
};

export const editImagePage = {
  id: 'ad-studio-edit-image-page',
  name: _TL_('Edit Image'),
  route: 'adstudio/images/:imageId/edit',
  scope: [Scope.Account],
  apexViewName: 'adstudio',
  searchable: false,
};

export const videosPage = {
  id: 'ad-studio-videos-page',
  name: _TL_('Videos'),
  route: 'adstudio/videos',
  scope: [Scope.Account],
  apexViewName: 'adstudio',
  searchable: true,
};

export const performancePage = {
  id: 'ad-studio-performance-page',
  name: _TL_('Performance'),
  route: 'adstudio/performance',
  scope: [Scope.Account],
  apexViewName: 'adstudio',
  searchable: true,
};

export const libraryPage = {
  id: 'ad-studio-library-page',
  name: _TL_('Library'),
  route: 'adstudio/library',
  scope: [Scope.Account],
  apexViewName: 'adstudio',
  searchable: true,
};

export const adStudioPage = {
  id: 'ad-studio-app-entry',
  name: _TL_('Ad Studio'),
  oldUIScope: [Scope.Account],
  scope: [Scope.Account],
  checkPermission: permissions => permissions.dynamic.AdsStudioMVP,
  children: [
    landingPage,
    homePage,
    homeSearchPage,
    imagesPage,
    videosPage,
    performancePage,
    libraryPage,
    generateImagePage,
    editImagePage,
  ],
};
