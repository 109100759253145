import { Scope, alwaysDisplayed } from '../shared';

export const overview = {
  id: 'overview',
  name: _TL_('Overview'),
  campaignTypes: alwaysDisplayed,
  scope: [Scope.Account, Scope.Campaign],
  children: [
    {
      id: 'overview',
      name: _TL_('Overview'),
      route: 'overview',
      campaignTypes: alwaysDisplayed,
      shortcut: 'g+o',
      scope: [Scope.Account, Scope.Campaign],
    },
  ],
};
