import { Scope, defaultCCItemProperties, isUnifiedProductSubscriptionEnabled } from '../../shared';

export const accountMap = {
  id: 'account-map',
  name: _TL_('Account map'),
  oldUIScope: [Scope.Account],
  ...defaultCCItemProperties,
  children: [
    {
      ...defaultCCItemProperties,
      id: 'account-map',
      name: _TL_('Account map'),
      route: 'AccountMap',
      checkPermission: permission =>
        permission.dynamic.CCUIAccountMap && !isUnifiedProductSubscriptionEnabled(permission),
    },
  ],
};
