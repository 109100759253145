import _ from 'underscore';
import { CampaignType, Scope } from '../shared';

export const productGroups = {
  id: 'product-groups',
  name: _TL_('Product groups'),
  campaignTypes: (campaignType, permissions) =>
    // Show product groups for Shopping campaigns, All campaigns, and Audience campaigns if PAAIMConsolidation is enabled.
    _.some([CampaignType.Shopping], type => (type === campaignType) || (type & campaignType)) || // eslint-disable-line no-bitwise
    !!(_.get(permissions, ['dynamic', 'PAAIMConsolidation'], false) && campaignType === CampaignType.Audience),
  scope: [Scope.Account, Scope.Campaign, Scope.AdGroup],
  currentCampaign: (campaign, permissions) => _.isEmpty(campaign) ||
    (_.result(CampaignType, campaign.CampaignType) === CampaignType.Shopping &&
      (_.get(permissions, ['dynamic', 'ShoppingCampaignsV2'], false) ||
      // For Microsoft Store Ads scenario
      !_.get(permissions, 'IsDomesticSimplifiedChineseEnabled', false) ||
      _.get(permissions, ['dynamic', 'WindowsStoreAdsCNExpansion'], false))) ||
    (_.get(permissions, ['dynamic', 'PAAIMConsolidation'], false) &&
      _.result(CampaignType, campaign.CampaignType) === CampaignType.Audience &&
      campaign.CampaignSubType === 'AudienceFeedBased'),
  children: [
    {
      id: 'product-groups',
      name: _TL_('Product groups'),
      route: 'productgroups/hierarchy',
      children: [
        {
          id: 'hierarchy',
          name: _TL_('Product groups'),
          route: 'productgroups/hierarchy',
        },
        {
          id: 'list',
          name: _TL_('List View'),
          route: 'productgroups/list',
        },
      ],
    },
  ],
};
