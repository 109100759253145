import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { GroupNode } from '../validation-node/group-node';
import { ValidationContext } from './validation-context';
import { withDefaultI18n, i18nPropType } from './with-default-i18n';

class ValidationGroupInternal extends React.Component {
  static propTypes = {
    validationProps: PropTypes.shape({
      onValidate: PropTypes.func,
      i18n: i18nPropType,
      stopPropagation: PropTypes.bool,
      parent: PropTypes.instanceOf(GroupNode),
      schema: PropTypes.shape({
        type: PropTypes.string,
      }),
      validator: PropTypes.func,
      convertor: PropTypes.shape({
        fromJSON: PropTypes.func,
        toJSON: PropTypes.func,
      }),
      data: PropTypes.object, // eslint-disable-line
    }),
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    validationProps: {
      onValidate: _.noop,
      i18n: undefined,
      schema: undefined,
      parent: undefined,
      stopPropagation: false,
      validator: undefined,
      convertor: undefined,
    },
  };

  constructor(props) {
    super(props);

    const {
      schema,
      parent,
      validator,
      convertor,
      stopPropagation,
    } = props.validationProps;

    this.groupNode = new GroupNode({
      schema,
      parent: stopPropagation ? undefined : parent,
      validator,
      convertor,
    });

    this.parent = parent;
  }

  componentDidMount() {
    this.groupNode.register();
    this.triggerValidate();
  }

  componentWillUnmount() {
    this.groupNode.unregister();
    _.result(this.props.validationProps, 'notifyDataChange');
  }

  getGroupOptions(options) {
    const {
      schema = options.schema,
      data = options.data,
      parent = options.parent,
      i18n = options.i18n,
      validator,
      convertor,
      stopPropagation,
    } = this.props.validationProps;

    if (!stopPropagation && !_.isEqual(this.parent, parent)) {
      this.parent = parent;
      this.groupNode = new GroupNode({
        schema,
        parent,
        validator,
        convertor,
      });
    }

    const notifyDataChange = () => {
      this.triggerValidate();

      if (!stopPropagation) {
        // Do not notify change to upper level validation context provider
        _.result(options, 'notifyDataChange');
      }
    };

    return {
      parent: this.groupNode,
      schema,
      i18n,
      data,
      notifyDataChange,
    };
  }

  triggerValidate() {
    if (_.isFunction(this.props.validationProps.onValidate)) {
      this.props.validationProps.onValidate(this.groupNode.accessor);
    }
  }

  render() {
    return (
      <ValidationContext.Consumer>
        {
          options => (
            <ValidationContext.Provider value={this.getGroupOptions(options)}>
              {this.props.children}
            </ValidationContext.Provider>
          )
        }
      </ValidationContext.Consumer>
    );
  }
}

export const ValidationGroup = withDefaultI18n(ValidationGroupInternal);
