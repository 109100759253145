import { Scope } from '../../shared';

export const offlineConversionItem = {
  id: 'offline-conversions',
  name: _TL_('Offline conversions'),
  route: 'offlineconversions',
  scope: [Scope.Account],
};

export const offlineConversions = {
  id: 'offline-conversions',
  name: _TL_('Offline conversions'),
  oldUIScope: [Scope.Account],
  scope: [Scope.Account],
  children: [offlineConversionItem, {
    id: 'offline-conversions-schedule',
    name: _TL_('Offline conversion schedules'),
    route: 'offlineconversionsschedule',
    scope: [Scope.Account],
    checkPermission: permissions => permissions.IsScheduledOfflineConversionUploadAllowed,
  },
  ],
};
