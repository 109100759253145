import { allScopes, alwaysDisplayed } from '../shared';
import { defaultReports } from './default-reports';
import { customized } from './my-reports/customize';
import { shared } from './my-reports/share';
import { billingAccountLinkPermissions } from '../client-center/billing-payments';

export const defaultReportsPage = {
  id: 'reports-v4-default-reports',
  name: _TL_('Default reports'),
  route: 'reports-v4/default-reports',
  fixedRoute: 'reports-v4/default-reports',
  scope: allScopes,
  campaignTypes: alwaysDisplayed,
  children: defaultReports,
};

export const myReportsPage = {
  id: 'reports-v4-my-reports',
  name: _TL_('Saved customized reports'),
  title: _TL_('View reports that you have customized and saved'),
  fixedRoute: 'reports-v4/my-reports',
  scope: allScopes,
  campaignTypes: alwaysDisplayed,
  children: [customized, shared],
  accountLinkPermissions: billingAccountLinkPermissions,
};

export const buildReportsPage = {
  id: 'reports-v4-build-custom-report',
  name: _TL_('Build your own report'),
  title: _TL_('Build your own report'),
  route: 'reports-v4/build-custom-report',
  scope: allScopes,
  campaignTypes: alwaysDisplayed,
  available: permissions => permissions.dynamic.CustomReportBuilder,
};

export const editReportPage = {
  id: 'reports-v4-edit-report',
  name: _TL_('Edit report'),
  title: _TL_('View and edit a report'),
  route: 'reports-v4/edit/:TaskItemId',
  scope: allScopes,
  apexViewName: 'reports-v4_edit',
  campaignTypes: alwaysDisplayed,
  searchable: false,
  accountLinkPermissions: billingAccountLinkPermissions,
};

export const reportsV4Pages = [
  {
    id: 'reports-v4',
    name: _TL_('Reports'),
    route: 'reports-v4',
    fixedRoute: 'reports-v4',
    scope: allScopes,
    campaignTypes: alwaysDisplayed,
    children: [
      {
        id: 'reports-v4-all-reports',
        name: _TL_('All Reports'),
        scope: allScopes,
        searchable: false,
      },
      defaultReportsPage,
      myReportsPage,
      editReportPage,
      buildReportsPage,
    ],
  },
];
