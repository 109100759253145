import React from 'react';
import PropTypes from 'prop-types';
import { verifyRouter } from '../common/verify-router';
import { DefaultParamProvider } from '../common/default-param-provider';
import { ScopeUpdater } from './scope-updater';
import { scopeFormatter } from './scope-formatter';

export function ScopeProvider({
  location,
  history,
  scope,
  allowedKeys,
  updateQuery,
  paramFormatter,
  children,
  updateOnPathChange,
}) {
  return (
    <DefaultParamProvider
      defaults={updateQuery ? null : scope}
      allowedKeys={allowedKeys}
      paramFormatter={paramFormatter}
      location={location}
      history={history}
      updateOnPathChange={updateOnPathChange}
    >
      {updateQuery ? (<ScopeUpdater defaults={scope} />) : null}
      {children}
    </DefaultParamProvider>
  );
}

const { defaults: scopeType, allowedKeys: allowedKeysType } = DefaultParamProvider.propTypes;

ScopeProvider.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  scope: scopeType,
  allowedKeys: allowedKeysType,
  updateQuery: PropTypes.bool,
  paramFormatter: PropTypes.func,
  updateOnPathChange: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ScopeProvider.defaultProps = {
  location: undefined,
  history: undefined,
  scope: DefaultParamProvider.defaultProps.defaults,
  allowedKeys: DefaultParamProvider.defaultProps.allowedKeys,
  updateQuery: true,
  updateOnPathChange: true,
  paramFormatter: scopeFormatter,
};

export const DefaultScopeProvider = verifyRouter(ScopeProvider);
