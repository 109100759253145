import { Scope, isSmartPageStandalone } from '../configs/shared';

export const smartPage = {
  id: 'smart-page',
  name: _TL_('Smart Page'),
  description: _TL_('Create a free website for your business.'),
  scope: [Scope.Account],
  externalLink: `${window.location.origin}/campaign/sp/landing${window.location.search}`,
  checkPermission: permissions => permissions.dynamic.PMaxLite && !isSmartPageStandalone(permissions)
    && !permissions.IsUnifiedSmartMode && !permissions.IsSmartMode,
};
