import { Scope, ScopeV2 } from '../../../shared';

ScopeV2.add('Store', 'storeId', ScopeV2.Account);
ScopeV2.add('MerchantFeed', 'feedId', ScopeV2.Store);

export const defaultScope = [
  Scope.Account,
  Scope.Store,
  Scope.MerchantFeed,
];

export const defaultScopeV2 = [
  ScopeV2.Account,
  ScopeV2.Store,
  ScopeV2.MerchantFeed,
];
