import { Scope, ScopeV2 } from '../../shared';

const scope = [Scope.Customer, Scope.Account, Scope.Campaign, Scope.AdGroup];

const scopeV2 = [ScopeV2.Customer, ScopeV2.Account, ScopeV2.Campaign, ScopeV2.AdGroup];

export const audiencesLibraryPages = {
  id: 'audiences-library',
  name: _TL_('Audience library'),
  oldUIScope: [Scope.Customer, Scope.Account],
  scope,
  scopeV2,
  children: [
    {
      id: 'audiences-library',
      name: _TL_('Audience library'),
      route: 'audienceslibrary',
      scope: [Scope.Customer, Scope.Account, Scope.Campaign, Scope.AdGroup],
      scopeV2: [ScopeV2.Customer, ScopeV2.Account, ScopeV2.Campaign, ScopeV2.AdGroup],
    },
    {
      id: 'audience-signals',
      name: _TL_('Audience signals'),
      route: 'audiencesignals',
      scope: [Scope.Customer, Scope.Account, Scope.Campaign, Scope.AdGroup],
      scopeV2: [ScopeV2.Customer, ScopeV2.Account, ScopeV2.Campaign, ScopeV2.AdGroup],
      checkPermission: permissions => permissions.dynamic.PerformanceMaxAudienceSignalManagementPage,
    },
  ],
};
