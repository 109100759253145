import { AdGroupType, CampaignType, alwaysDisplayed } from '../shared';

const adGroupTypes = [AdGroupType.SearchStandard, AdGroupType.SearchDynamic];

export const recommendations = {
  id: 'recommendations',
  name: _TL_('Recommendations'),
  hotelCampaignScope: [],
  campaignTypes: type => (type !== CampaignType.App) && (type !== CampaignType.LinkedIn),
  adGroupTypes,
  shortcut: 'g+y',
  children: [
    {
      id: 'All',
      name: _TL_('All'),
      route: 'recommendations/All',
      campaignTypes: alwaysDisplayed,
    },
    {
      id: 'Repairs',
      name: _TL_('Repairs'),
      route: 'recommendations/Repairs',
      campaignTypes: alwaysDisplayed,
    },
    {
      id: 'Bids',
      name: _TL_('Bidding & budgets'),
      route: 'recommendations/Bids',
      campaignTypes: alwaysDisplayed,
    },
    {
      id: 'Keywords',
      name: _TL_('Keywords & targeting'),
      route: 'recommendations/Keywords',
      campaignTypes: alwaysDisplayed,
    },
    {
      id: 'Ads',
      name: _TL_('Ads & extensions'),
      route: 'recommendations/Ads',
      campaignTypes: alwaysDisplayed,
    },
    {
      id: 'Dismissed',
      name: _TL_('Dismissed'),
      route: 'recommendations/Dismissed',
      campaignTypes: alwaysDisplayed,
    },
  ],
};
