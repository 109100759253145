import _ from 'underscore';

export function filterMapItem(siteMap, predicate) {
  return _.reduce(siteMap, (result, item) => {
    if (predicate(item)) {
      const children = filterMapItem(item.children, predicate);
      result.push(_.defaults({ children }, item));
    }
    return result;
  }, []);
}
