import _ from 'underscore';
import { matchPath } from 'react-router-dom';
import Uri from 'urijs';

import { Scope, ScopeV2 } from './configs/shared';

export function isRouteMatch(pathname, routePrefix, route, exact) {
  const extractPattern = new RegExp(`${routePrefix}(.+)$`, 'i');
  const currentLocation = _.last(pathname.match(extractPattern));
  if (_.isEmpty(currentLocation)) {
    return false;
  }
  return route
    ? !!matchPath(currentLocation, {
      path: route,
      exact,
    })
    : false;
}

export function getRoutePathById(siteMap, id, getAbsoluteIfExists = false) {
  if (_.isEmpty(siteMap)) {
    return false;
  }
  const site = _.findWhere(siteMap, { id });
  if (site) {
    if (getAbsoluteIfExists && site.absoluteRoute) {
      return site.absoluteRoute;
    }
    return (new Uri(site.route)).is('absolute') ? site.route :
      `${site.routePrefix}${site.route}`;
  }
  return getRoutePathById(_.chain(siteMap)
    .pluck('children')
    .flatten()
    .value(), id, getAbsoluteIfExists);
}

export function findSiteMap(siteMap, condition) {
  for (let i = 0; i < siteMap.length; i += 1) {
    const item = siteMap[i];
    const result = condition(item) ? item : findSiteMap(item.children, condition);
    if (!_.isEmpty(result)) {
      return result;
    }
  }
  return null;
}

export function findAllSiteMap(siteMap, condition) {
  let result = [];
  for (let i = 0; i < siteMap.length; i += 1) {
    const item = siteMap[i];
    if (condition(item)) {
      result.push(item);
    }
    if (item.children.length > 0) {
      result = result.concat(findAllSiteMap(item.children, condition));
    }
  }
  return _.compact(result);
}

/**
 * findDeepSiteMap(map)
 * Will return deepest node who's parents and itself
 * satisfy the given condition
 * @param {map} map a site map
 * @param {function} condition  truth test
 * @returns {object} node || undefinded if not found
 */
export function findDeepSiteMap(map, condition) {
  const currentNode = _.find(map, condition);
  let selectedChild;
  if (currentNode && currentNode.children) {
    selectedChild = findDeepSiteMap(currentNode.children, condition);
  }

  return selectedChild || currentNode;
}

/**
 * findSelectedNode(map)
 * Will return deepest node who's parents and itself
 * have selected property set true
 * @param {map} map a site map
 * @param {function} condition  truth test
 * @returns {object} node || undefinded if not found
 */
export const findSelectedNode = _.partial(findDeepSiteMap, _, node => _.result(node, 'selected', false));

function filterMapByIdSegments(siteMap, segments) {
  const id = _.last(segments);
  if (id === '*') {
    return siteMap;
  }
  for (let i = 0; i < siteMap.length; i += 1) {
    const item = siteMap[i];
    if (_.result(item, 'id') === id) {
      segments.pop();
      return _.isEmpty(segments) ? [item] :
        filterMapByIdSegments(item.children, segments);
    }
    const result = filterMapByIdSegments(item.children, segments);
    if (!_.isEmpty(result)) {
      return result;
    }
  }
  return [];
}

/**
 * filterMapByRootIds(siteMap, rootIds)
 * Will return a new siteMap that unions all matching rootdIds
 * Refer to root-filter-processor-unit-test for more examples
 * @param {array} siteMap a site map
 * @param {array} rootIds ids to match child items, supports ['id', 'parent/child', 'parent/*']
 * @returns {array} new siteMap that unions all rootIds matches
 */
export const filterMapByRootIds = (siteMap, rootIds = null) => {
  if (rootIds == null) {
    return siteMap;
  }
  const rootIdArray = _.isArray(rootIds) ? rootIds : [rootIds];
  return _.chain(rootIdArray)
    .map((id) => {
      const segments = id.split('/').reverse();
      return filterMapByIdSegments(siteMap, segments);
    })
    .flatten(true)
    .compact()
    .value();
};

export const getNewScope = (currentScope, expectedScope, context) => {
  if (_.isArray(expectedScope)) {
    if (currentScope.aid) {
      if (currentScope.adGroupId && expectedScope.indexOf(Scope.AdGroup) >= 0) {
        return ScopeV2.trimToScope(ScopeV2.AdGroup, currentScope);
      }
      if (currentScope.campaignId && expectedScope.indexOf(Scope.Campaign) >= 0) {
        return ScopeV2.trimToScope(ScopeV2.Campaign, currentScope);
      }
      if (expectedScope.indexOf(Scope.Account) >= 0) {
        // we are under account scope, and page supports it
        return ScopeV2.trimToScope(ScopeV2.Account, currentScope);
      }
      if (expectedScope.indexOf(Scope.MerchantFeed) >= 0) {
        return ScopeV2.trimToScope(ScopeV2.MerchantFeed, currentScope);
      }
      if (expectedScope.indexOf(Scope.Store) >= 0) {
        return ScopeV2.trimToScope(ScopeV2.Store, currentScope);
      }
    } else if (expectedScope.indexOf(Scope.Customer) < 0) {
      // page doesn't support customer level, try to add back aid from context if available
      const aidFromContext = _.result(context.CurrentAccount, 'Id');
      if (aidFromContext) {
        const scopeWithoutAid = ScopeV2.trimToScope(ScopeV2.Customer, currentScope);
        return _.extend(scopeWithoutAid, { aid: aidFromContext });
      }
    }
  }
  // page stays on customer level or fallback to customer level from account level
  return ScopeV2.trimToScope(ScopeV2.Customer, currentScope);
};

const isAbsoluteRoute = route => new Uri(route).is('absolute');

const routeWithPrefix = (route, prefix) => `${prefix}${route}`;

export const buildSitemapItemRoute = (item) => {
  if (item.route) {
    return isAbsoluteRoute(item.route) ? item.route : routeWithPrefix(item.route, item.routePrefix);
  }

  if (item.children && item.children[0]) {
    return buildSitemapItemRoute(item.children[0]);
  }

  return '';
};

export const getSiteMapItemRoute = (params) => {
  const {
    item, scope, userContext,
  } = params;

  if (_.isEmpty(item)) {
    return null;
  }

  if (item.externalLink) {
    return {
      isExternal: true,
      pathName: item.externalLink,
      search: '',
      href: item.externalLink,
    };
  }

  const pathName = buildSitemapItemRoute(item);
  const search = scope.stringify(getNewScope(scope.values, item.scope, userContext));
  const href = Uri(pathName).search(search).toString();

  return {
    isExternal: false,
    pathName,
    search,
    href,
  };
};
