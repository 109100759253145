import { Scope } from '../../shared';

export const brandListsPage = {
  id: 'brand-lists',
  name: _TL_('Brand lists'),
  scope: [Scope.Account],
  checkPermission: permissions => (permissions.dynamic.BroadOnly && !permissions.dynamic.PredictiveMatching) || permissions.dynamic.BrandExclusion,
  children: [
    {
      id: 'brand-lists',
      name: _TL_('Brand lists'),
      scope: [Scope.Account],
      route: 'brandlists',
      showTag: {
        text: _TL_('New'),
        expDate: new Date('2024/12/31'),
      },
      checkPermission: permissions => (permissions.dynamic.BroadOnly && !permissions.dynamic.PredictiveMatching) || permissions.dynamic.BrandExclusion,
      searchAlias: _TL_('View brand lists'),
    },
    {
      id: 'brand-lists-details',
      name: _TL_('Brand lists details'),
      scope: [Scope.Account],
      route: 'brandlists/details',
      checkPermission: permissions => (permissions.dynamic.BroadOnly && !permissions.dynamic.PredictiveMatching) || permissions.dynamic.BrandExclusion,
      searchable: false,
    },
  ],
};
