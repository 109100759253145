import { Scope, defaultCCItemProperties, isSmartPageStandalone } from '../../shared';

export const accountsBilling = {
  id: 'billing-payments',
  name: _TL_('Accounts & Billing'),
  oldUIScope: [Scope.Account],
  children: [
    {
      ...defaultCCItemProperties,
      id: 'billing-payments',
      name: _TL_('Accounts & Billing'),
      route: 'billing-payments',
      checkPermission: permission =>
        !permission.IsManagementDashboardEnabled && !isSmartPageStandalone(permission),
    },
  ],
};
