import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';

import { I18nContext } from '@bingads-webui-react/i18n-context';
import { buildLegacyI18n } from '@bingads-webui/build-legacy-i18n';
import { ValidationStringMap } from './validation-string-map';

export const i18nPropType = PropTypes.shape({
  getString: PropTypes.func,
  isValidDecimal: PropTypes.func,
  parseDecimal: PropTypes.func,
  formatDecimal: PropTypes.func,
});

const getValidationProps = (validationProps, i18n) => {
  if (_.isEmpty(i18n)) {
    return validationProps;
  }
  const legacyi18n = buildLegacyI18n(i18n, [ValidationStringMap]);
  return _.extend(validationProps, { i18n: legacyi18n });
};

export function withDefaultI18n(Component) {
  const WrappedComponent = ({ validationProps, ...props }) => {
    if (_.isEmpty(_.result(validationProps, 'i18n'))) {
      return (
        <I18nContext.Consumer>
          {i18n => (
            <Component validationProps={getValidationProps(validationProps, i18n)} {...props} />
          )}
        </I18nContext.Consumer>
      );
    }
    return (<Component validationProps={validationProps} {...props} />);
  };

  WrappedComponent.propTypes = {
    validationProps: PropTypes.shape({}),
  };

  WrappedComponent.defaultProps = {
    validationProps: undefined,
  };

  return React.memo(WrappedComponent);
}
