import { Scope, ScopeV2 } from '../../../shared';

export const importFeed = {
  id: 'mmc-import',
  name: _TL_('Merchant center'),
  scope: [Scope.Store],
  scopeV2: [ScopeV2.Store],
  checkPermission: permissions => !permissions.IsDomesticSimplifiedChineseEnabled,
  children: [
    {
      id: 'mmc-import',
      name: _TL_('Import'),
      route: 'merchantcenter/import',
      scope: [Scope.Store],
      scopeV2: [ScopeV2.Store],
      searchable: false,
      checkPermission: permissions => !permissions.IsDomesticSimplifiedChineseEnabled,
      children: [
        {
          id: 'mmc-import',
          name: _TL_('Import'),
          route: 'merchantcenter/import',
          scope: [Scope.Store],
          scopeV2: [ScopeV2.Store],
          searchable: false,
        },
        {
          id: 'mmc-import-feed-label',
          name: _TL_('Feed label management'),
          route: 'merchantcenter/import-feed-label',
          scope: [Scope.Store],
          scopeV2: [ScopeV2.Store],
          searchable: false,
          checkPermission: permissions => permissions.dynamic.FeedLabel && !permissions.dynamic.FeedLabelBlockList,
        },
      ],
    },
    {
      id: 'mmc-import-steps',
      name: _TL_('Import steps'),
      route: 'merchantcenter/import-steps',
      scope: [Scope.Store],
      scopeV2: [ScopeV2.Store],
      searchable: false,
    },
    {
      id: 'mmc-import-result',
      name: _TL_('Import result'),
      route: 'merchantcenter/import-result',
      scope: [Scope.Store],
      scopeV2: [ScopeV2.Store],
      searchable: false,
    },
  ],
};
