import _ from 'underscore';

function createAccessor(node) {
  return {
    get data() {
      return node.toJSON();
    },
    get messages() {
      return node.messages;
    },
    get isValid() {
      return node.isValid;
    },
    get isDirty() {
      return node.isDirty;
    },
  };
}

export class BaseNode {
  constructor({
    parent, schema, field, validator, customValidator, convertor, skipValidation = false,
  }) {
    this.parent = parent || {
      notifyChildChange: _.noop,
      registerChild: _.noop,
      unregisterChild: _.noop,
      setDirty: _.noop,
    };
    this.schema = schema;
    this.field = field;
    this.messages = [];
    this.validator = validator;
    this.customValidator = customValidator;
    this.convertor = convertor;
    this.isDirty = false;
    this.skipValidation = skipValidation;
  }

  register() {
    this.parent.registerChild(this);
  }

  unregister() {
    this.parent.unregisterChild(this);
  }

  validate(changed) {
    this.messages = this.skipValidation ? {} : this.getValidationErrorMessages(changed);
  }

  getValidationErrorMessages(changed) {
    if (_.isFunction(this.customValidator)) {
      return this.customValidator(changed, _.partial(this.validator, _, this));
    }
    return this.validator(changed, this);
  }

  get isValid() {
    return _.isEmpty(this.messages);
  }

  fromJSON(json) {
    this.convertor.fromJSON(json, this);
  }

  toJSON() {
    return this.convertor.toJSON(this);
  }

  setDirty() {
    this.isDirty = true;
    this.parent.setDirty();
  }

  setSkipValidation(skipValidation) {
    this.skipValidation = skipValidation;
  }

  get accessor() {
    return createAccessor(this);
  }
}
