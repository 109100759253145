// TODO[new-i18n-issue]: a workaround to use build-in validation string map
// as when serveral features import the same ValidationStringMap
// currently the webpack shared bundle strategy will cause the validation string not generated
export const ValidationStringMap = {
  Validation_Base_Field_Not_Valid: _TL_('Field is not valid'),
  Validation_NotEmpty_Required_Field: _TL_('Required field'),
  Validation_String_Max_Length: _TL_('Field should not exceed {{maxlength}} characters'),
  Validation_String_Min_Length: _TL_('Field should exceed {{minlength}} characters'),
  Validation_String_Invalid_Characters: _TL_('Field contains invalid characters'),
  Validation_Number_Require_Numeric: _TL_('Field requires numeric value'),
  Validation_Number_Max_Length: _TL_('Number should not exceed max length'),
  Validation_Number_Range_Min: _TL_('Number should be larger than {{min}}'),
  Validation_Number_Range_Max: _TL_('Number should be smaller than {{max}}'),
  Validation_Number_Range_Between: _TL_('Number should be between {{min}} and {{max}}'),
  Validation_Array_Size_Max: _TL_('List should have at most {{max}} items'),
  Validation_Array_Size_Between: _TL_('List should have at least {{min}} and at most {{max}} items'),
  Validation_Array_Items_Invalid: _TL_('Some of items in list are invalid'),
  Validation_Array_Items_Duplicate: _TL_('List has duplicate items'),
  Validation_NegativeKeywords_ListName_Required: _TL_('The list name is required.'),
  Validation_NegativeKeywords_Max_Count: _TL_('Maximum of 5,000 negative keywords'),
  Validation_NegativeKeywords_Invalid_Keywords: _TL_('Some of the keyword(s) are invalid: {{invalidKeywords}}'),
  Validation_NegativeKeywords_Required: _TL_('At least 1 negative keyword is required.'),
};
