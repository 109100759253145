import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { DefaultAdapter } from '../adapters/default-adapter';
import { ValidationContext } from './validation-context';
import { ValidationItem } from './validation-item';

export function withValidationInternal(
  Component,
  PrimitiveComponent = null,
  Adapter = DefaultAdapter
) {
  const ContextConsumer = (props) => {
    const renderValidationItem = (contextProps) => {
      const onValidate = (accessor) => {
        if (_.isFunction(props.validationProps.onValidate)) {
          props.validationProps.onValidate(accessor);
        }
        _.result(contextProps, 'notifyDataChange');
      };
      const validationProps = _.defaults(
        { onValidate },
        PrimitiveComponent === null ? {} :
          { primitiveAdapter: new Adapter(PrimitiveComponent) },
        props.validationProps,
        contextProps
      );

      return (
        <Component
          {...props}
          validationProps={validationProps}
        />
      );
    };

    return (
      <ValidationContext.Consumer>
        { renderValidationItem }
      </ValidationContext.Consumer>
    );
  };

  ContextConsumer.propTypes = {
    validationProps: PropTypes.shape({
      onValidate: PropTypes.func,
    }),
  };

  ContextConsumer.defaultProps = {
    validationProps: {
      onValidate: _.noop,
    },
  };

  return ContextConsumer;
}

export const withValidation = _.partial(withValidationInternal, ValidationItem);
