import _ from 'underscore';
import { BaseNode } from './base-node';

const initialValue = {};
const validator = (changed, node) => node.messages;
const convertor = {
  fromJSON: (data, node) => { _.extend(node, { value: data }); },
  toJSON: node => node.value,
};

export class DummyNode extends BaseNode {
  constructor({ parent, initialNodeValue = initialValue }) {
    super({ parent, validator, convertor });
    this.value = initialNodeValue;
  }

  trigger(messages, value = this.value, shouldDirty = true) {
    this.messages = messages;
    if (value !== this.value) {
      this.value = value;
      if (shouldDirty) {
        this.setDirty();
      }
    }
    this.parent.notifyChildChange(this);
  }
}
