import { Scope } from '../shared';

export const socialRoot = {
  id: 'social-root',
  route: 'social/inbox',
  default: true,
  scope: [Scope.Account],
  metadata: {
    title: _TL_('Social'),
  },
};
