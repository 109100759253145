import { CampaignType, AdGroupType } from '../shared';

const campaignTypes = [CampaignType.Default, CampaignType.Audience];
const adGroupTypes = [AdGroupType.SearchStandard, AdGroupType.SearchDynamic];

export const landingPages = {
  id: 'landing-pages',
  name: _TL_('Landing pages'),
  route: 'landingpages',
  campaignTypes,
  adGroupTypes,
  children: [
    {
      id: 'landing-pages',
      name: _TL_('Landing pages'),
      route: 'landingpages',
      campaignTypes,
    },
  ],
};
