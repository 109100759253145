import { Scope } from '../shared';

export const bulkUploadsBuildSpreadsheet = {
  id: 'bulk-uploads-build-spreadsheet',
  name: '',
  scope: [Scope.Customer],
  children: [
    {
      id: 'build-spreadsheet',
      name: _TL_('Build spreadsheet'),
      route: 'bulkuploads/buildspreadsheet',
      checkPermission: permissions => permissions.IsCampaignBAMEnabled,
      scope: [Scope.Customer],
    },
    {
      id: 'build-spreadsheet-account-numbers',
      name: '', // Not to display this item
      route: 'bulkuploads/buildspreadsheet/:account_numbers',
      checkPermission: permissions => permissions.IsCampaignBAMEnabled,
      scope: [Scope.Customer],
    },
  ],
};
