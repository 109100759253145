import _ from 'underscore';
import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

import { getDisplayName } from '@bingads-webui-react/hoc-utils';

import { Route } from './route';

/**
 * HOC that passes the mapped React Route route props to the wrapped component.
 * @param {object} WrappedComponent - Component to receive mapped route props
 * @returns {object} - Component with mapped route props
 */
export const withRouter = (WrappedComponent) => {
  const RoutedComponent = (props) => {
    /* eslint-disable react/prop-types */
    const rest = _.omit(props, 'forwardedRef');

    return (
      <Route>
        {routeComponentProps => (
          <WrappedComponent
            {...rest}
            {...routeComponentProps}
            ref={props.forwardedRef}
          />
          )
        }
      </Route>
    );
  };

  RoutedComponent.displayName = `withBingAdsRouter(${getDisplayName(WrappedComponent)})`;

  hoistNonReactStatics(RoutedComponent, WrappedComponent);

  return React.forwardRef((props, ref) => <RoutedComponent {...props} forwardedRef={ref} />);
};
