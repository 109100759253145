import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useMemo, useEffect } from 'react';

import { RibbonDropdown } from './ribbon-dropdown';

export const RibbonButton = ({
  classes,
  button,
  plugin,
  format,
  onClicked,
  className,
  i18n,
}) => {
  const [isDropdownShown, setIsDropdownShown] = useState(false);

  const editor = useMemo(() => plugin.getEditor(), [plugin]);
  const editorDocument = useMemo(() => plugin.getEditor().getDocument(), [plugin]);

  const isImageButton = useMemo(() => !!button.image, [button]);
  const buttonClassName = useMemo(() => {
    const isChecked = format && button.checked && button.checked(format, editor);

    return classNames(
      isChecked ? 'checked' : '',
      isImageButton ? classes.imgButton : classes.textButton,
      'ribbon-button-wrapper',
      `${className}-button`
    );
  }, [button, className, classes, editor, format, isImageButton]);

  const onHideDropdown = useCallback(() => {
    // Shadow edit mode is enabled when hover on dropdown item, need to reset it
    // ShadowEdit is a live preview when applying fomat style
    editor.stopShadowEdit();
    editorDocument.removeEventListener('click', onHideDropdown);
    setIsDropdownShown(false);
  }, [editorDocument, editor]);

  const onShowDropdown = useCallback((e) => {
    if (!button.preserveOnClickAway) {
      editorDocument.addEventListener('click', onHideDropdown);
    }

    // For React 17 not dismiss the dropdown
    e.stopPropagation();
    setIsDropdownShown(true);
  }, [button, editorDocument, onHideDropdown]);

  const handleOnClick = useCallback((value) => {
    onHideDropdown();

    if (button.onClick) {
      button.onClick(editor, value);
    }

    onClicked();
  }, [button, editor, onHideDropdown, onClicked]);

  // Make sure event listener is cleaned up
  useEffect(() => () => editorDocument.removeEventListener('click', onHideDropdown), [editorDocument, onHideDropdown]);

  return (
    <span className={classNames(classes.buttonContainer)}>
      <button
        className={buttonClassName}
        disabled={!!button.isDisabled && button.isDisabled(editor, format)}
        onClick={button.dropdownItems ? onShowDropdown : handleOnClick}
      >
        {
          isImageButton ?
            <img
              src={button.image}
              width={32}
              height={32}
              title={button.title}
              alt={i18n.getString(button.title)}
            />
            :
            i18n.getString(button.title)
        }
      </button>
      {
        button.dropdownItems && isDropdownShown &&
        <RibbonDropdown
          classes={classes}
          i18n={i18n}
          editor={editor}
          button={button}
          onHideDropdown={onHideDropdown}
        />
      }
    </span>
  );
};

RibbonButton.propTypes = {
  className: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    getString: PropTypes.func,
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
  // button configuration input
  button: PropTypes.shape({}).isRequired,
  // related ribbon plugin to access editor instance
  plugin: PropTypes.shape({}).isRequired,
  // current editor format state object
  format: PropTypes.shape({}).isRequired,
  // callback when button has been clicked
  onClicked: PropTypes.func.isRequired,
};
