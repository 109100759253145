import { audiencesLibraryPages } from './audiences-library';
import { businessLocationFeedPages } from './business-location-feed';
import { ProductFeedPage, SponsoredProductsPage, MicrosoftStoreAdsPageGA } from './product-feed';
import { labelsPage } from './labels';
import { negativeKeywordListsLibraryPage } from './negative-keyword-lists-library';
import { performanceTargetsPage } from './performance-targets';
import { siteExclusionListsAccountLevelViewListsPage } from './site-exclusion-lists-account-level-view-lists';
import { siteExclusionListsCustomerLevelCreateListsPage } from './site-exclusion-lists-customer-level-create-lists';
import { contentExclusionAccountLevelPage } from './content-exclusion-account-level';
import { sharedBudgetsPage } from './shared-budgets';
import { portfolioBidStrategyPage } from './portfolio-bid-strategy';
import { seasonalityAdjustmentPage } from './seasonality-adjustment';
import { creativeStudioAssetLibraryPage } from './creative-studio-asset-library';
import { brandListsPage } from './brand-lists';
import { adStudioPage } from './ad-studio';
import { liftMeasurement } from './lift-measurement';

export const sharedLibrary = [
  creativeStudioAssetLibraryPage,
  ProductFeedPage,
  SponsoredProductsPage,
  MicrosoftStoreAdsPageGA,
  audiencesLibraryPages,
  businessLocationFeedPages,
  labelsPage,
  negativeKeywordListsLibraryPage,
  performanceTargetsPage,
  siteExclusionListsAccountLevelViewListsPage,
  siteExclusionListsCustomerLevelCreateListsPage,
  contentExclusionAccountLevelPage,
  brandListsPage,
  sharedBudgetsPage,
  portfolioBidStrategyPage,
  seasonalityAdjustmentPage,
  adStudioPage,
  liftMeasurement,
];
