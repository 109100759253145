import _ from 'underscore';
import { CampaignType, AdGroupType, Scope } from '../shared';

const campaignTypes = [CampaignType.Default, CampaignType.Shopping, CampaignType.Audience,
  CampaignType.DynamicSearchAds, CampaignType.Smart, CampaignType.Hotel];
const adGroupTypes = [AdGroupType.SearchStandard, AdGroupType.SearchDynamic];

export const MS_STORE_PROVIDER_ID_MAP = {
  SI: [10624440, 5104030, 5353239],
  PROD: [182978],
  CI: [108],
};

export function getMSStoreIdByEnv(url) {
  if (url.includes('redmond.corp.microsoft.com')) {
    return MS_STORE_PROVIDER_ID_MAP.CI;
  }
  if (url.includes('ads-int.microsoft.com') || url.includes('si.bingads.microsoft.com')) {
    return MS_STORE_PROVIDER_ID_MAP.SI;
  }
  return MS_STORE_PROVIDER_ID_MAP.PROD;
}


export const devices = {
  id: 'devices',
  name: _TL_('Devices'),
  campaignTypes,
  adGroupTypes,
  smartShoppingCampaignScope: [Scope.Account],
  currentCampaign: (campaign) => {
    if (campaign == null) {
      return true;
    }

    const msStoreIds = getMSStoreIdByEnv(window.location.href);
    const providerId = _.chain(campaign).result('CampaignSettings').result('0').result('ProviderId')
      .value();
    const result = !msStoreIds.some(id => String(id) === String(providerId));
    return result;
  },
  children: [
    {
      id: 'devices',
      name: _TL_('Devices'),
      children: [
        {
          id: 'devices',
          name: _TL_('Devices'),
          route: 'devices',
        },
        {
          id: 'devices-campaign',
          name: _TL_('Devices'),
          route: 'devices/campaign',
          searchable: false,
        },
        {
          id: 'devices-adgroup',
          name: _TL_('Devices'),
          route: 'devices/adgroup',
          searchable: false,
        },
      ],
    },
  ],
};
