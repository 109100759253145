import { defaultUpItemProperties, isUnifiedProductEnabled, isSmartPageStandalone, isUnifiedProductSubscriptionEnabled } from '../../../shared';

const mobileAppLink = 'https://go.microsoft.com/fwlink/?linkid=2173918';

export const upMobileApp = {
  id: 'up-mobile-app',
  name: _TL_('Microsoft Advertising mobile app'),
  children: [
    {
      ...defaultUpItemProperties,
      id: 'up-mobile-app',
      name: _TL_('Microsoft Advertising mobile app'),
      externalLink: mobileAppLink,
      checkPermission: p => isUnifiedProductEnabled(p) && !isSmartPageStandalone(p) && !isUnifiedProductSubscriptionEnabled(p),
    },
  ],
};
