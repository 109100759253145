import { Scope } from '../shared';

export const recommendations = {
  id: 'recommendations',
  name: _TL_('Recommendations'),
  scope: [Scope.Customer],
  children: [
    {
      id: 'All',
      name: _TL_('All'),
      route: 'accounts/recommendations/All',
      scope: [Scope.Customer],
    },
    {
      id: 'Repairs',
      name: _TL_('Repairs'),
      route: 'accounts/recommendations/Repairs',
      scope: [Scope.Customer],
    },
    {
      id: 'Bids',
      name: _TL_('Bidding & budgets'),
      route: 'accounts/recommendations/Bids',
      scope: [Scope.Customer],
    },
    {
      id: 'Keywords',
      name: _TL_('Keywords & targeting'),
      route: 'accounts/recommendations/Keywords',
      scope: [Scope.Customer],
    },
    {
      id: 'Ads',
      name: _TL_('Ads & extensions'),
      route: 'accounts/recommendations/Ads',
      scope: [Scope.Customer],
    },
  ],
};
