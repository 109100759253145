import _ from 'underscore';

import React from 'react';
import PropTypes from 'prop-types';

import { Route as ReactRoute } from 'react-router-dom';

import { PropTypesExt } from './prop-types-ext';
import { RouterConsumer } from './router';

const convertProps = ({
  routerConfig: {
    parseRoute,
  },
  routeProps,
}) => {
  const params = parseRoute(routeProps);
  return _.defaults({}, routeProps, params);
};

/**
 * Extension of the React Router <Route /> component that maps the default React Router
 * route props into new props according to the router config, then passes these to the
 * wrapped component.
 * Similar to React Router <Route />, the wrapped component can be passed in through the
 * "component", "render", and "children" props.
 * All other props will be passed through down to React Router <Route />.
 * @param {object} props - Route component props
 * @returns {object}     - Component with route context
 */
export const Route = props => (
  <RouterConsumer>
    {(routerConfig) => {
      const updatedProps = _.extend({}, props);

      if (props.component) {
        updatedProps.component = routeProps =>
          <props.component {...convertProps({ routerConfig, routeProps })} />;
      }

      if (props.render) {
        updatedProps.render = routeProps =>
          props.render(convertProps({ routerConfig, routeProps }));
      }

      if (props.children) {
        updatedProps.children = routeProps =>
          props.children(convertProps({ routerConfig, routeProps }));
      }

      return (
        <ReactRoute {...updatedProps} />
      );
    }}
  </RouterConsumer>
);

Route.propTypes = {
  children: PropTypes.func,
  // Bug in eslint: https://github.com/yannickcr/eslint-plugin-react/issues/1389
  // eslint-disable-next-line react/no-typos
  component: PropTypesExt.component,
  render: PropTypes.func,
};

Route.defaultProps = {
  children: null,
  component: null,
  render: null,
};
