(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@fluentui/react"));
	else if(typeof define === 'function' && define.amd)
		define(["@fluentui/react"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@fluentui/react")) : factory(root["@fluentui/react"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__49__) {
return 