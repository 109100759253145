import { defaultUpItemProperties, isUnifiedProductEnabled, isSmartPageStandalone } from '../../../shared';

export const upConversionTracking = {
  id: 'up-conversion-goals',
  name: _TL_('Conversion tracking'),
  children: [
    {
      ...defaultUpItemProperties,
      id: 'up-conversion-goals',
      name: _TL_('Conversion tracking'),
      route: 'ads/conversion-goals',
      isUnifiedProductPage: true,
      checkPermission: p => isUnifiedProductEnabled(p) && !isSmartPageStandalone(p)
                            && !p.dynamic.SCv2,
    },
  ],
};
