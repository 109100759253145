import { available, scope } from './common';

export const extensions = {
  id: 'reports-v4-category-extensions',
  name: _TL_('Ad extensions'),
  title: _TL_('Ad extension reports give you a closer look at how each ad extension is performing based on click type for ads and keywords and ad extension properties for ad groups.'),
  fixedRoute: 'reports-v4/category/extensions',
  route: 'reports-v4/category/extensions',
  scope,
  available,
  children: [
    {
      id: 'reports-v4-extensionkeywordreport',
      name: _TL_('Ad extension by keyword'),
      title: _TL_('See how your extensions perform for your keywords.'),
      fixedRoute: 'reports-v4/create/extensionkeywordreport',
      route: 'reports-v4/create/extensionkeywordreport',
      searchAlias: _TL_('Ad extension by keyword report'),
      scope,
      available,
      apiKey: 'AdExtensionByKeywordReport',
      description: _TL_('To understand how different click types are performing for each keyword when different ad extensions are shown in the ad.'),
    }, {
      id: 'reports-v4-extensionadreport',
      name: _TL_('Ad extension by ad'),
      title: _TL_('See how your extensions perform for your ads.'),
      fixedRoute: 'reports-v4/create/extensionadreport',
      route: 'reports-v4/create/extensionadreport',
      searchAlias: _TL_('Ad extension by ad report'),
      scope,
      available,
      apiKey: 'AdExtensionByAdReport',
      description: _TL_('To understand how different click types are performing for each ad when the ad is served with different ad extensions.'),
    }, {
      id: 'reports-v4-adextensiondetailreport',
      name: _TL_('Ad extension details'),
      title: _TL_('See how engaging your ad extension items are.'),
      fixedRoute: 'reports-v4/create/adextensiondetailreport',
      route: 'reports-v4/create/adextensiondetailreport',
      searchAlias: _TL_('Ad extension details report'),
      scope,
      available,
      apiKey: 'AdExtensionDetailReport',
      description: _TL_('To understand how different ad extension properties are performing when different ad extensions are shown in the ad.'),
    }, {
      title: _TL_('See duration and phone spend details for each forwarded call.'),
      id: 'reports-v4-callforwarddetailreport',
      // Duplicate
      name: _TL_('Call forwarding detail'),
      fixedRoute: 'reports-v4/create/callforwarddetailreport',
      route: 'reports-v4/create/callforwarddetailreport',
      searchAlias: _TL_('Call forwarding detail report'),
      scope,
      available,
      apiKey: 'CallDetailReport',
      description: _TL_('To understand how the accounts, campaigns, or ad groups are performing with call extensions.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.dynamic.CallMetering,
    },
  ],
};
