import _ from 'underscore';
import { defaultScopes } from '../../../shared';
import { intelligencePages } from './intelligence';

export const overviewPages = {
  id: 'hotel-center-overview',
  name: _TL_('Overview'),
  route: 'hotelcenter/overview',
  checkPermission: permissions => permissions.dynamic.HotelAds,
  children: [
    {
      id: 'hotel-center-overview',
      name: _TL_('Overview'),
      route: 'hotelcenter',
      checkPermission: permissions => permissions.dynamic.HotelAds,
      searchable: false,
    },
    {
      id: 'hotel-center',
      name: _TL_('Overview'),
      route: 'hotelcenter/overview',
      checkPermission: permissions => permissions.dynamic.HotelAds,
      searchable: false,
    },
    {
      id: 'hotel-center-overview-hotelsubaccountgrid',
      name: _TL_('Subacount'),
      route: 'hotelcenter/overview/hotelsubaccountgrid',
      checkPermission: permissions => permissions.dynamic.HotelAds && !permissions.dynamic.HotelCenterDeprecation,
    },
    {
      id: 'hotel-center-overview-hotelidgrid',
      name: _TL_('Hotel'),
      route: 'hotelcenter/overview/hotelidgrid',
      checkPermission: permissions => permissions.dynamic.HotelAds,
    },
    {
      id: 'hotel-center-overview-hotelgroupgrid',
      name: _TL_('Hotel group'),
      route: 'hotelcenter/overview/hotelgroupgrid',
      checkPermission: permissions => permissions.dynamic.HotelAds,
    },
    {
      id: 'hotel-center-overview-advancedbookinggrid',
      name: _TL_('Advanced booking window'),
      route: 'hotelcenter/overview/advancedbookinggrid',
      checkPermission: permissions => permissions.dynamic.HotelAds,
    },
    {
      id: 'hotel-center-overview-checkindaygrid',
      name: _TL_('Check in day'),
      route: 'hotelcenter/overview/checkindaygrid',
      checkPermission: permissions => permissions.dynamic.HotelAds,
    },
    {
      id: 'hotel-center-overview-hoteldategrid',
      name: _TL_('Date'),
      route: 'hotelcenter/overview/hoteldategrid',
      checkPermission: permissions => permissions.dynamic.HotelAds,
    },
    {
      id: 'hotel-center-overview-datetypegrid',
      name: _TL_('Date type'),
      route: 'hotelcenter/overview/datetypegrid',
      checkPermission: permissions => permissions.dynamic.HotelAds,
    },
    {
      id: 'hotel-center-overview-hoteldevicetypegrid',
      name: _TL_('Device type'),
      route: 'hotelcenter/overview/hoteldevicetypegrid',
      checkPermission: permissions => permissions.dynamic.HotelAds,
    },
    {
      id: 'hotel-center-overview-hotelcountrygrid',
      name: _TL_('Hotel country/region'),
      route: 'hotelcenter/overview/hotelcountrygrid',
      checkPermission: permissions => permissions.dynamic.HotelAds,
    },
    {
      id: 'hotel-center-overview-hotellengthofstaygrid',
      name: _TL_('Length of stay'),
      route: 'hotelcenter/overview/hotellengthofstaygrid',
      checkPermission: permissions => permissions.dynamic.HotelAds,
    },
    {
      id: 'hotel-center-overview-sitetypegrid',
      name: _TL_('Site type'),
      route: 'hotelcenter/overview/sitetypegrid',
      checkPermission: permissions => permissions.dynamic.HotelAds,
    },
    {
      id: 'hotel-center-overview-hotelslottypegrid',
      name: _TL_('Slot type'),
      route: 'hotelcenter/overview/hotelslottypegrid',
      checkPermission: permissions => permissions.dynamic.HotelAds,
    },
    {
      id: 'hotel-center-overview-hotelusercountrygrid',
      name: _TL_('User country/region'),
      route: 'hotelcenter/overview/hotelusercountrygrid',
      checkPermission: permissions => permissions.dynamic.HotelAds,
    }],
};

const setupPage = {
  id: 'hotel-center-setup',
  name: _TL_('Setup'),
  route: 'hotelcenter/setup',
  checkPermission: permissions => permissions.dynamic.HotelAds,
  searchable: false,
};

const assignmentPage = {
  id: 'hotel-center-assignment',
  name: _TL_('Assignment'),
  route: 'hotelcenter/assignment',
  checkPermission: permissions => permissions.dynamic.HotelAds && !permissions.dynamic.HotelCenterDeprecation,
};

export const hotelCenterPages = [
  overviewPages,
  assignmentPage,
  intelligencePages,
  setupPage,
];

export const flatHotelCenterPages = [
  overviewPages,
  assignmentPage,
  ...intelligencePages.children,
  setupPage,
];

const hotelCenterScope = {
  scope: defaultScopes,
};

function addScope(items) {
  _.each(items, (item) => {
    _.defaults(item, hotelCenterScope);
    if (item.children) {
      addScope(item.children);
    }
  });
}

addScope(hotelCenterPages);
addScope(flatHotelCenterPages);

export const hotelCenter = {
  id: 'hotel-center',
  name: _TL_('Hotel Center'),
  route: 'hotelcenter/overview',
  scope: defaultScopes,
  checkPermission: permissions => permissions.dynamic.HotelAds,
  children: [{
    id: 'hotel-center',
    name: _TL_('Hotel center'),
    route: 'hotelcenter/overview',
    children: hotelCenterPages,
    checkPermission: permissions => permissions.dynamic.HotelAds,
  }],
};
