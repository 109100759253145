import { Scope } from '../shared';

export const recommendationDemo = {
  id: 'recommendation-demo',
  name: _TL_('Demo only'),
  scope: [Scope.Customer, Scope.Account, Scope.Campaign, Scope.AdGroup],
  children: [{
    id: 'recommendation-demo',
    name: _TL_('Demo only'),
    route: 'recommendations/demo',
    scope: [Scope.Customer, Scope.Account, Scope.Campaign, Scope.AdGroup],
    checkPermission: permissions => permissions.IsRecommendationDemoEnabled,
  }],
};
