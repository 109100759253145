import { Scope } from '../shared';

export const bulkUploads = {
  id: 'bulk-uploads',
  name: _TL_('Bulk uploads'),
  checkPermission: permissions => permissions.IsCampaignBAMEnabled,
  scope: [Scope.Customer],
  children: [
    {
      id: 'bulk-download-history',
      name: _TL_('Download history'),
      route: 'bulkuploads/downloadhistory',
      checkPermission: permissions => permissions.IsCampaignBAMEnabled,
      scope: [Scope.Customer],
    }, {
      id: 'bulk-scheduled-downloads',
      name: _TL_('Scheduled downloads'),
      route: 'bulkuploads/scheduleddownloads',
      checkPermission: permissions => permissions.IsCampaignBAMEnabled,
      scope: [Scope.Customer],
    }, {
      id: 'bulk-uploads',
      name: _TL_('Uploads'),
      route: 'bulkuploads/uploads',
      checkPermission: permissions => permissions.IsCampaignBAMEnabled,
      scope: [Scope.Customer],
    },
  ],
};
