import { available, scope } from './common';

export const changeHistory = {
  id: 'reports-v4-category-changehistory',
  name: _TL_('Change history'),
  title: _TL_('See the changes that have been made to your campaigns and users within an account, over a period you specify.'),
  fixedRoute: 'reports-v4/category/changehistory',
  route: 'reports-v4/category/changehistory',
  scope,
  available,
  children: [
    {
      id: 'reports-v4-changehistoryreport',
      name: _TL_('Change history'),
      title: _TL_('See a list of changes that have been made to your campaigns.'),
      fixedRoute: 'reports-v4/create/changehistoryreport',
      route: 'reports-v4/create/changehistoryreport',
      searchAlias: _TL_('Change history report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'SearchCampaignChangeHistoryReport',
      description: _TL_('To discover when changes to an account were made, as well as which user made the changes.'),
    }, {
      id: 'reports-v4-userrolechangehistoryreport',
      name: _TL_('User role change history'),
      title: _TL_('See what changes have been made to individual user profiles in your accounts.'),
      fixedRoute: 'reports-v4/create/userrolechangehistoryreport',
      route: 'reports-v4/create/userrolechangehistoryreport',
      searchAlias: _TL_('User role change history report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'UserRoleChangeHistoryReport',
      description: _TL_('See what changes have been made to individual user profiles in your accounts.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.IsInternalUser,
    }, {
      id: 'reports-v4-fraudchangehistoryreport',
      name: _TL_('Fraud change history by user'),
      title: _TL_('See a list of changes, filtered by user, that shows potentially fraudulent activity.'),
      fixedRoute: 'reports-v4/create/fraudchangehistoryreport',
      route: 'reports-v4/create/fraudchangehistoryreport',
      searchAlias: _TL_('Fraud change history by user report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'FraudChangeHistoryReport',
      description: _TL_('See a list of changes, filtered by user, that shows potentially fraudulent activity.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.IsInternalUser,
    },
  ],
};
