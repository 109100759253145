import { Scope } from '../shared';

export const recommendationAutoApplyBulkUpload = {
  id: 'recommendation-auto-apply-bulk-upload',
  name: _TL_('AAR Bulk Update Tool'),
  scope: [Scope.Customer],
  children: [{
    id: 'recommendation-auto-apply-bulk-upload',
    name: _TL_('AAR Bulk Update Tool'),
    route: 'accounts/recommendations/AutoApplyBulkUpload',
    scope: [Scope.Customer],
  }],
};
