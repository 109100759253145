/* eslint-disable no-bitwise */
/* istanbul ignore file */
import _ from 'underscore';
import { ScopeV2 } from './scope';

export { AccountLinkPermissionType } from '@bingads-webui/multi-agency-utils';

export const CampaignType = {
  None: 0,
  Default: 1 << 0,
  Shopping: 1 << 1,
  DynamicSearchAds: 1 << 2,
  Audience: 1 << 3,
  Smart: 1 << 4,
  Hotel: 1 << 5,
  PerformanceMax: 1 << 6,
  App: 1 << 7,
  LinkedIn: 1 << 8,
  All: 0xFFFF,
};

export const AdGroupType = {
  SearchStandard: 'SearchStandard',
  SearchDynamic: 'SearchDynamic',
};

export const Scope = {
  Customer: 0,
  Account: 1,
  Campaign: 2,
  AdGroup: 3,
  Ad: 4,
  PerformanceTarget: 5,
  Store: 6,
  MerchantFeed: 7,
};

ScopeV2.add('User', 'uid');
ScopeV2.add('Customer', 'cid', ScopeV2.User);
ScopeV2.add('Account', 'aid', ScopeV2.Customer);
ScopeV2.add('Campaign', 'campaignId', ScopeV2.Account);
ScopeV2.add('AdGroup', 'adGroupId', ScopeV2.Campaign);
ScopeV2.add('Feed', 'feedId', ScopeV2.Account);

export { ScopeV2 };

// refer to settingContext.Url for bastHost details
export const BaseHost = {
  ClientCenter: 'ClientCenterBaseUrl',
  Campaign: 'CampaignBaseUrl',
  Smart: 'SmartBaseUrl',
  AdInsight: 'AdInsightBaseUrl',
  UnifiedProduct: 'UnifiedProductBaseUrl',
};

export const RoutePrefix = {
  ClientCenter: '/',
  Campaign: '/campaign/vnext/',
  Smart: '/campaign/smart/',
  AdInsight: '/research/vnext/',
  UnifiedProduct: '/campaign/mca/',
};

export const alwaysDisplayed = [CampaignType.None, CampaignType.All];

export const defaultScopes = [
  Scope.Account,
  Scope.Campaign,
  Scope.AdGroup,
];

export const accountScopes = [
  Scope.Customer,
  Scope.Account,
];

export const allScopes = [
  Scope.Customer,
  Scope.Account,
  Scope.Campaign,
  Scope.AdGroup,
  Scope.Ad,
  Scope.PerformanceTarget,
  Scope.Store,
  Scope.MerchantFeed,
];

export const defaultItem = {
  id: '',
  name: '',
  route: '',
  routePrefix: RoutePrefix.Campaign,
  children: [],
  scope: defaultScopes,
  campaignTypes: [CampaignType.All],
  available: true,
  selected: false,
  checkPermission: () => true,
  searchable: true,
  baseHost: BaseHost.Campaign,
};

export const defaultCCItemProperties = {
  baseHost: BaseHost.ClientCenter,
  routePrefix: RoutePrefix.ClientCenter,
  scope: accountScopes,
  available: true,
  checkPermission: () => true,
};

export const alwaysDisplayedItem = _.defaults({
  campaignTypes: alwaysDisplayed,
}, defaultItem);

const isNewSmartEnabled
  = permissions => permissions.dynamic.SmartNewUI && permissions.IsSmartCampaignPhase2Enabled;
export const defaultSmartItemProperties = {
  baseHost: BaseHost.Smart,
  routePrefix: RoutePrefix.Smart,
  scope: accountScopes,
  isSmartPage: true,
  available: true,
  checkPermission: isNewSmartEnabled,
};

// permissions.dynamic.IsSmartPageStandalone is set only by standalone app ui side.
// So it will be undefined in other apps.
export const isSmartPageStandalone
  = permissions => permissions.dynamic.IsSmartPageStandalone;

export const isUnifiedProductEnabled
  = permissions => permissions.dynamic.UnifiedProductPhase2 && permissions.IsUnifiedSmartMode;

export const isUnifiedProductSubscriptionEnabled
  = permissions => permissions.dynamic.UnifiedProductSubscription;

export const defaultUpItemProperties = {
  baseHost: BaseHost.UnifiedProduct,
  routePrefix: RoutePrefix.UnifiedProduct,
  scope: accountScopes,
  searchable: false,
  available: true,
  checkPermission: isUnifiedProductEnabled,
};

const defaultAdInsightItemProperties = {
  baseHost: BaseHost.AdInsight,
  routePrefix: RoutePrefix.AdInsight,
  scope: accountScopes,
  available: true,
  checkPermission: () => false,
};

export const adInsightItem = {
  ...alwaysDisplayedItem,
  ...defaultAdInsightItemProperties,
};

// todo [rayduan]: below section is for migration period only (from scope to scope V2)
// Will clean up when we deprecate all current Scope and rename ScopeV2 to Scope
const scopeMapping = {
  [Scope.Customer]: ScopeV2.Customer,
  [Scope.Account]: ScopeV2.Account,
  [Scope.Campaign]: ScopeV2.Campaign,
  [Scope.AdGroup]: ScopeV2.AdGroup,
};

const addV2Scopes = (item) => {
  if (_.has(item, 'scope') && !_.has(item, 'scopeV2')) {
    const scopeV2 = _.compact(_.map(item.scope, scope => _.result(scopeMapping, scope, null)));
    _.extend(item, { scopeV2 });
  }
};
// end of todo

export function buildTree(children, defaults, addParent, parent, clone = false) {
  return _.map(children, (item) => {
    let withDefaults;
    let withParent;
    if (clone) {
      withDefaults = _.defaults({}, item, defaults);
      withParent = _.extend({}, withDefaults, { parent });
    } else {
      withDefaults = _.defaults(item, defaults);
      withParent = _.extend(withDefaults, { parent });
    }
    withParent.children = buildTree(
      withParent.children,
      defaults,
      addParent,
      addParent ? item : null,
      clone
    );

    addV2Scopes(withParent);

    return withParent;
  });
}
