import { Scope, defaultCCItemProperties } from '../../shared';

export const accountAccess = {
  id: 'account-access',
  name: _TL_('Account access'),
  oldUIScope: [Scope.Account],
  children: [
    {
      ...defaultCCItemProperties,
      id: 'account-access',
      name: _TL_('Account access'),
      route: 'Users',
      checkPermission: permission => permission.IsManagementDashboardEnabled,
    },
  ],
};
