import { buildTree, allScopes, alwaysDisplayed } from '../configs/shared';
import { ccAlwaysDisplayedItem } from '../configs';

import {
  accountSettings,
  emailSettings,
  managerAccountSettings,
  mySettings,
  advancedSettings,
  verificationHub,
  viewDefaults,
} from '../configs/client-center/settings';

import { directManager, userManagement } from '../configs/client-center/account-access';

// hide children them from nav menus, while keeping the parent visible
const accountSettingsProcessed = {
  ...accountSettings,
  searchable: true,
  children: accountSettings.children.map(child => ({
    ...child,
    searchable: false,
  })),
};
const managerAccountSettingsProcessed = {
  ...managerAccountSettings,
  searchable: true,
  children: managerAccountSettings.children.map(child => ({
    ...child,
    searchable: false,
  })),
};
const advancedSettingsProcessed = {
  ...advancedSettings,
  searchable: true,
  children: advancedSettings.children.map(child => ({
    ...child,
    searchable: false,
  })),
};

const settings = {
  id: 'settings',
  name: _TL_('Settings'),
  route: 'Settings/ViewDefaults',
  scope: allScopes,
  campaignTypes: alwaysDisplayed,
  processChildScope: true,
  fixed: true,
  tail: true,
  children: [
    viewDefaults,
    accountSettingsProcessed,
    emailSettings,
    managerAccountSettingsProcessed,
    mySettings,
    advancedSettingsProcessed,
    verificationHub,
    userManagement,
    directManager,
  ],
};

const tree = buildTree([settings], ccAlwaysDisplayedItem, true, undefined, true)[0];

export { tree as settings };
