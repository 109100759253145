import PropTypes from 'prop-types';

const itemPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  route: PropTypes.string.isRequired,
  checkPermission: PropTypes.func.isRequired,
  accountLinkPermissions: PropTypes.arrayOf(PropTypes.number),
  scope: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.func]),
  campaignTypes: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.func]),
  available: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  selected: PropTypes.bool,
  showTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  tagClass: PropTypes.string,
});

const siteMapPropType = PropTypes.arrayOf(itemPropType);

itemPropType.children = PropTypes.arrayOf(siteMapPropType);

export { siteMapPropType };
