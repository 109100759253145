import { isNavigationRefreshOn } from '@bingads-webui-react/preference-utility';
import { Scope } from '../shared';

export const accountSettings = {
  id: 'accountSettings',
  name: _TL_('Account settings'),
  route: 'Settings/Account',
  scope: [Scope.Account],
  searchable: false,
  children: [
    {
      id: 'accountSettingsView',
      name: _TL_('Account settings'),
      route: 'Settings/Account',
      scope: [Scope.Account],
    },
    {
      id: 'accountSettingsEdit',
      name: _TL_('Account settings edit'),
      route: 'Settings/Account/Edit',
      scope: [Scope.Account],
      searchable: false,
    },
  ],
};
export const emailSettings = {
  id: 'emailSettings',
  name: _TL_('Email preferences'),
  route: 'Settings/EmailPreferences',
};
export const managerAccountSettings = {
  id: 'managerAccountSettings',
  name: _TL_('Manager account settings'),
  route: 'Settings/ManagerAccount',
  searchable: false,
  children: [
    {
      id: 'managerAccountSettingsView',
      name: _TL_('Manager account settings'),
      route: 'Settings/ManagerAccount',
    },
    {
      searchable: false,
      id: 'managerAccountSettingsEdit',
      name: _TL_('Manager account settings edit'),
      route: 'Settings/ManagerAccount/Edit',
    },
  ],
};
export const mySettings = {
  id: 'mySettings',
  name: _TL_('My settings'),
  route: 'Settings/MySettings',
};
export const advancedSettings = {
  id: 'advancedSettings',
  name: _TL_('Advanced settings'),
  route: 'Settings/AdvancedSettings',
  searchable: false,
  ccuiOnlyPage: true,
  children: [
    {
      id: 'advancedSettingsView',
      name: _TL_('Advanced settings'),
      route: 'Settings/AdvancedSettings',
      searchable: false,
    },
    {
      id: 'advancedSettingsEdit',
      name: _TL_('Advanced settings edit'),
      route: 'Settings/AdvancedSettings/Edit',
      searchable: false,
    },
  ],
};
export const verificationHub = {
  id: 'verificationHub',
  name: _TL_('Account verifications'),
  route: 'Settings/VerificationHub',
  scope: [Scope.Account],
};
export const viewDefaults = {
  id: 'viewDefaults',
  name: _TL_('Customize view'),
  route: 'Settings/ViewDefaults',
  checkPermission: permissions => isNavigationRefreshOn(permissions),
};
export const settings = {
  id: 'defaultSettings',
  name: _TL_('Preferences'),
  route: 'Settings',
  fixedRoute: 'Settings',
  children: [
    accountSettings,
    emailSettings,
    managerAccountSettings,
    mySettings,
    advancedSettings,
    verificationHub,
    viewDefaults,
  ],
};
