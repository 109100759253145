export const ResponsiveSizeBreakpoints = [
  {
    name: 's',
    minWidth: 360,
  },
  {
    name: 'm',
    minWidth: 480,
  },
  {
    name: 'l',
    minWidth: 640,
  },
  {
    name: 'xl',
    minWidth: 1024,
  },
  {
    name: 'xxl',
    minWidth: 1366,
  },
];
