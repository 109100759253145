import _ from 'underscore';
import Uri from 'urijs';

const getBaseHost = (item, settings = {}) => {
  const baseUrl = _.result(settings.Urls, item.baseHost, null);
  return _.isEmpty(baseUrl) ? null : (new Uri(baseUrl)).normalize();
};

const isAbsoluteRouteRequired = (baseHost, currentUrl) => {
  if (_.isEmpty(baseHost)) {
    return false;
  }

  if (currentUrl.host() !== baseHost.host()) {
    return true; // if host name is different, we need absolute route
  }

  const basePath = baseHost.path().toLowerCase();
  const currentPath = currentUrl.path().toLowerCase();

  // if basePath is not a sub-path of current path, we need absolute route
  return Uri.commonPath(basePath, currentPath) !== basePath;
};

export const getRouteWithBaseHost = (route, baseHost, currentUrl, forceAbsolute = false) =>
  (
    (forceAbsolute || isAbsoluteRouteRequired(baseHost, currentUrl)) &&
    !_.isEmpty(baseHost) &&
    !(new Uri(route)).is('absolute')
      ? baseHost + route
      : route);

export const updateBaseHost = (siteMap, currentUrl, settings) =>
  _.map(siteMap, item => _.defaults({
    children: updateBaseHost(item.children, currentUrl, settings),
    route: getRouteWithBaseHost(item.route, getBaseHost(item, settings), currentUrl),
    absoluteRoute: getRouteWithBaseHost(item.route, getBaseHost(item, settings), currentUrl, true),
  }, item));
