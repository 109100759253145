import { accountsBilling } from './accounts-billing';
import { accountAccess } from './account-access';
import { accountsSummary } from './accounts-summary';
import { accountMap } from './account-map';
import { billingPayments } from './billing-payments';
import { businessData } from './business-data';
import { budgetCenter } from './budget-center';
import { hotelCenter } from './hotel-center';
import { hotelCenterV2 } from './hotel-center-v2';
import { merchantCenter } from './merchant-center';
import { settings } from './settings';
import {
  socialNetworks,
  upTermCondition,
  upPrivacyCookies,
  upConversionTracking,
  upClarity,
  upChangeHistory,
  upMobileApp,
} from './unified-product';

export const setup = [
  accountsSummary,
  accountsBilling,
  businessData,
  billingPayments,
  upChangeHistory,
  accountAccess,
  accountMap,
  settings,
  merchantCenter,
  hotelCenter,
  hotelCenterV2,
  socialNetworks,
  upConversionTracking,
  upClarity,
  upMobileApp,
  upPrivacyCookies,
  upTermCondition,
  budgetCenter,
];
