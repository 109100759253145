import { adExtensionPage } from './ad-extension';
import { bookingReportPage } from './booking-report';
import { feedStatusPage } from './feed-status';
import { feedUploadPage } from './feed-upload';
import { shareOfVoicePage } from './share-of-voice';
import { matchReportPage } from './match-report';
import { UploadedReconReportsPage } from './uploaded-recon-reports';
import { ProcessedReconReportsPage } from './processed-recon-reports';

const reportPages = [matchReportPage, feedStatusPage, shareOfVoicePage, bookingReportPage];
const reconcilliationReportPages = [UploadedReconReportsPage, ProcessedReconReportsPage];

export const intelligencePages = {
  id: 'hotel-center-intelligence',
  name: _TL_('Intelligence'),
  route: 'hotelcenter/feedupload',
  checkPermission: permissions => permissions.dynamic.HotelAds,
  children: [
    feedUploadPage,
    {
      id: 'report',
      name: _TL_('Reports'),
      route: 'hotelcenter/matchreport',
      checkPermission: permissions => permissions.dynamic.HotelAds,
      children: reportPages,
    },
    adExtensionPage,
    {
      id: 'reconciliationreport',
      name: _TL_('Reconciliation Report'),
      route: 'hotelcenter/reconciliationreport',
      checkPermission: /* istanbul ignore next */ (permissions) => {
        const {
          ReconciliationReport, PayPerStay, HotelAds,
        } = permissions.dynamic;
        return ReconciliationReport && PayPerStay && HotelAds;
      },
      children: reconcilliationReportPages,
    },
  ],
};
