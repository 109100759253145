import { Scope } from '../shared';

export const channelPartner = {
  id: 'channelPartnerStandalone',
  name: _TL_('Channel Partner'),
  children: [
    {
      id: 'channelPartnerAddPayment',
      name: _TL_('Channel partner add payment'),
      route: 'channelPartner/AddPayment',
      scope: [Scope.Account],
      checkPermission: permissions => permissions.dynamic.ChannelPartnerAddPI,
    },
  ],
};
