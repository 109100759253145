import { Scope, ScopeV2 } from '../../../shared';

export const products = {
  id: 'mmc-products',
  name: _TL_('Merchant center'),
  scope: [Scope.Store],
  scopeV2: [ScopeV2.Store],
  checkPermission: permissions => permissions.dynamic.MMCDynamicTab,
  children: [
    {
      id: 'mmc-products',
      name: _TL_('Product Explorer'),
      route: 'merchantcenter/products',
      scope: [Scope.Store],
      scopeV2: [ScopeV2.Store],
      searchable: false,
      checkPermission: permissions => permissions.dynamic.MMCDynamicTab,
    },
  ],
};
