import { defaultUpItemProperties, isUnifiedProductEnabled, isSmartPageStandalone } from '../../../shared';

export const socialNetworks = {
  id: 'up-social-networks',
  name: _TL_('Social networks'),
  children: [
    {
      ...defaultUpItemProperties,
      id: 'up-social-networks',
      name: _TL_('Social networks'),
      route: 'settings/social-networks',
      isUnifiedProductPage: true,
      // Currently Smart Page signup user have UP pilots flag, which makes this link display
      // so combines with smart page flag to hide it.
      checkPermission: p => isUnifiedProductEnabled(p) && !isSmartPageStandalone(p)
                            && !p.dynamic.SCv2,
    },
  ],
};
