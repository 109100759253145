import _ from 'underscore';

const aggregateParents = (node, iteratee, memo) => {
  let result = memo;
  for (let cur = node; cur !== null; cur = cur.parent) {
    result = iteratee(result, cur);
  }
  return result;
};

class ScopeNode {
  children = [];

  constructor(name, key, parent) {
    this.name = name;
    this.key = key;
    this.parent = parent;
  }

  isMatch(key) {
    return this.key === key;
  }

  isEqual(other) {
    return this.name === other.name;
  }

  isChildOf(parent) {
    return aggregateParents(this, (result, node) =>
      result || node.parent === parent, false);
  }

  get level() {
    return aggregateParents(this.parent, l => l + 1, 0);
  }

  get keys() {
    return aggregateParents(this, (result, node) => {
      if (node.key !== null) {
        result.push(node.key);
      }
      return result;
    }, []);
  }
}

class ScopeContainer {
  $root = new ScopeNode('$root', null, null);

  add = (name, key, parent = this.$root) => {
    if (_.isEmpty(this.findNode(parent))) {
      return null; // invalid parent node
    }

    const newNode = new ScopeNode(name, key, parent);

    if (!_.isEmpty(this.findNode(newNode))) {
      return null; // node with name already exists
    }

    parent.children.push(newNode);

    this[name] = newNode;
    return newNode;
  };

  find = (condition, current = this.$root) => {
    if (_.isEmpty(current)) {
      return null;
    }

    const nodes = [current];
    while (!_.isEmpty(nodes)) {
      const node = nodes.pop();
      if (condition(node)) {
        return node;
      }
      nodes.unshift(...node.children);
    }
    return null;
  };

  findAll = (condition, current = null) => {
    const result = [];
    const nodes = current == null ? [...this.$root.children] : [current];
    while (!_.isEmpty(nodes)) {
      const node = nodes.pop();
      if (condition(node)) {
        result.push(node);
        nodes.unshift(...node.children);
      }
    }
    return result;
  };

  findNode = node => this.find(current => current.isEqual(node));

  findByKey = key => this.find(current => current.isMatch(key));

  getCurrentScope = (queryParams) => {
    const scopes = this.findAll(node => _.has(queryParams, node.key), this.Customer);
    return this.findDeepest(scopes);
  };

  findDeepest = scopes =>
    _.reduce(scopes, (result, scope) => (result.level > scope.level ? result : scope), this.$root);

  trimToScope = (target, queryParams) => {
    const targetScope = _.isArray(target) ? this.findDeepest(target) : target;
    const currentScope = this.getCurrentScope(queryParams);
    const keysToRemove = _.without(currentScope.keys, ...targetScope.keys);

    return _.omit(queryParams, ...keysToRemove);
  };
}

export const Scope = new ScopeContainer();
export const ScopeV2 = Scope; // todo [rayduan]: remove ScopeV2 after finishing scope migration
