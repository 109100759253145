import { Scope, ScopeV2 } from '../shared';

const scope = [Scope.Customer, Scope.Account, Scope.Campaign, Scope.AdGroup];

const scopeV2 = [ScopeV2.Customer, ScopeV2.Account, ScopeV2.Campaign, ScopeV2.AdGroup];

export const uetTagsCreate = {
  id: 'uet-tags-create',
  name: _TL_('Uet tags create'),
  scope,
  scopeV2,
  children: [
    {
      id: 'uet-tags-create',
      name: _TL_('Uet tags create'),
      route: 'uettags/create',
    },
  ],
};

ScopeV2.add('UetTag', 'uetTagId', ScopeV2.Account);
ScopeV2.add('UetTag', 'uetTagId', ScopeV2.Customer);

export const uetTagsEdit = {
  id: 'uet-tags-edit',
  name: _TL_('Uet tags edit'),
  scope,
  scopeV2: scopeV2.concat(ScopeV2.UetTag),
  children: [
    {
      id: 'uet-tags-edit',
      name: _TL_('Uet tags edit'),
      route: 'uettags/edit',
    },
  ],
};
