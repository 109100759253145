import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import URI from 'urijs';
import { createPath } from 'history';
import { Link as ReactLink } from 'react-router-dom';

/**
 * Extension of the React Router <Link /> component that prevent absolute URL href to be overrided
 * by React Router <Link /> by rendering a basic anchor DOM
 * Usage similar to <Link /> components: https://reactrouter.com/web/api/Link
 * @param {object} props - <Link /> component props
 * @returns {object}     - <Link /> component or anchor component for absolute URLs
 */
export const Link = (props) => {
  const {
    to,
    ...rest
  } = props;

  const path = _.isObject(to) ? createPath(to) : to;
  if (_.isString(path) && URI(path).is('absolute')) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...rest} href={path} />;
  }

  return <ReactLink {...props} />;
};

Link.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
};
