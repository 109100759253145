import { Scope } from '../shared';

export const recommendationHistory = {
  id: 'recommendation-history',
  name: _TL_('Bulk Apply History'),
  scope: [Scope.Customer, Scope.Account, Scope.Campaign, Scope.AdGroup],
  children: [
    {
      id: 'baa-recommendation-history',
      name: _TL_('My applied recommendations history'),
      route: 'recommendations/history',
      scope: [Scope.Account, Scope.Campaign, Scope.AdGroup],
    },
    {
      id: 'mcc-recommendation-history',
      name: _TL_('My applied recommendations history'),
      route: 'accounts/recommendations/history',
      scope: [Scope.Customer],
    },
  ],
};
