import _ from 'underscore';

export function scopeFormatter(scope) {
  return _.mapObject(scope, (value) => {
    const pattern = /^[0-9]+$/g;
    const SAFE_INTEGER = Number.MAX_SAFE_INTEGER || 9007199254740991;
    let isInt = _.isString(value) ? value.match(pattern) : false;
    let intValue;
    if (isInt) {
      intValue = parseInt(value, 10);
      isInt = intValue.toString() === value && intValue <= SAFE_INTEGER;
    }
    return isInt ? intValue : value;
  });
}
