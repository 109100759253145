import { Scope, isUnifiedProductSubscriptionEnabled } from '../../shared';

export const accountsSummary = {
  id: 'setup-accounts',
  name: _TL_('Accounts'),
  oldUIScope: [Scope.Account],
  children: [
    {
      id: 'setup-accounts',
      name: _TL_('Accounts'),
      route: 'accounts',
      scope: [Scope.Customer],
      checkPermission: permission =>
        permission.IsManagementDashboardEnabled && !isUnifiedProductSubscriptionEnabled(permission),
    },
  ],
};
