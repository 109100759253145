import { alwaysDisplayed, Scope } from '../shared';

const keywordPlanner =
{
  id: 'adinsight-keyword-planner',
  name: _TL_('Keyword planner'),
  route: 'keywordplanner',
};

const adPreview = {
  id: 'adinsight-ad-preview',
  name: _TL_('Ad preview & diagnostics'),
  route: 'adpreview',
  scope: [Scope.Account, Scope.Campaign, Scope.AdGroup],
};

export const adInsightPages = [{
  id: 'adinsight',
  name: _TL_('Planning'),
  campaignTypes: alwaysDisplayed,
  children: [
    keywordPlanner,
    adPreview,
  ],
}];
