import classNames from 'classnames';
import _ from 'underscore';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { Editor, ContentEdit, HyperLink, Paste, HtmlSanitizer, getFormatState, ExperimentalFeatures } from 'roosterjs';

import { withDefaultStyles } from '@bingads-webui-react/with-default-styles';

import { Ribbon } from './editor-ribbon/ribbon';
import { RibbonPlugin } from './plugins/ribbon-plugin';
import { ContentStatePlugin } from './plugins/state-plugin';
import { RibbonButtons } from './editor-ribbon/ribbon-buttons';
import { getDefaultFormat } from './helper/get-default-format';
import { ContentFilterPlugin } from './plugins/content-filter-plugin';

// Create a dangling div to call editor core api for overwrite
const tmpDiv = document.createElement('div');
const dummyEditor = new Editor(tmpDiv);

const UnstyledRichTextEditor = ({
  className,
  classes,
  onBlur,
  initialContent,
  onContentChanged,
  ribbonButtons,
  options,
  i18n,
  placeholder,
}) => {
  const editorRef = useRef(null);
  const ribbonRef = useRef(null);
  const [editor, setEditor] = useState(null);
  const [editorContent, setEditorContent] = useState(null);
  const format = (editor && getFormatState(editor)) || {};

  const allPlugins = useMemo(() => ({
    contentEdit: new ContentEdit({
      autoLink: true,
    }),
    hyperLink: new HyperLink(),
    paste: new Paste(),
    contentFilterPlugin: new ContentFilterPlugin(),
    ribbon: new RibbonPlugin(),
    contentState: new ContentStatePlugin(options),
  }), [options]);
  const allPluginsArray = useMemo(() => Object.values(allPlugins), [allPlugins]);

  const editorOptions = useMemo(() => ({
    defaultFormat: getDefaultFormat(options.defaultFormat),
    plugins: allPluginsArray,
    initialContent: HtmlSanitizer.sanitizeHtml(HtmlSanitizer.convertInlineCss(initialContent)),
    coreApiOverride: {
      // TODO refactor to use the newly added originalapi https://microsoft.github.io/roosterjs/docs/interfaces/roosterjs_editor_types.editorcore.html#originalapi
      // override this api to force pasteAsText
      createPasteFragment: (core, clipboardData, position, pasteAsText, applyCurrentStyle) =>
        dummyEditor.core.api.createPasteFragment(
          core,
          clipboardData,
          position,
          options.pasteAsText,
          applyCurrentStyle
        ),
    },
    experimentalFeatures: [
      ExperimentalFeatures.ListItemAlignment,
    ],
  }), [initialContent, allPluginsArray, options]);

  const handleContentChanged = useCallback((content, shouldNotify) => {
    setEditorContent(content);
    if (shouldNotify) {
      onContentChanged(content);
    }
  }, [onContentChanged]);

  useEffect(() => {
    allPlugins.ribbon.registerRef(ribbonRef);
  }, [ribbonRef, allPlugins]);

  useEffect(() => {
    allPlugins.contentState.registerObs(handleContentChanged);
  }, [handleContentChanged, allPlugins]);

  useEffect(() => {
    if (editorRef.current && !editor) {
      const newEditor = new Editor(editorRef.current, editorOptions);
      setEditor(newEditor);
    }
  }, [editor, editorOptions, editorRef]);

  useEffect(() => () => {
    if (editor) {
      editor.dispose();
      setEditor(null);
    }
  }, [editor]);

  return (
    <React.Fragment>
      <div className={classNames('rich-text-editor-root', classes.root)}>
        <Ribbon
          i18n={i18n}
          plugin={allPlugins.ribbon}
          ref={ribbonRef}
          buttons={ribbonButtons}
        />
        <div className={classes.editorRoot}>
          <div
            className={classNames(className, 'rich-text-editor-wrapper', classes.editorContainer)}
            onBlur={() => onBlur(editor.getContent())}
            ref={editorRef}
            aria-placeholder={placeholder}
          />
          {
            !editorContent && !format.isBullet && !format.isNumbering &&
            placeholder &&
            <div className={classNames(classes.editorPlaceholder, 'rich-text-editor-placeholder')}>
              {placeholder}
            </div>
          }
        </div>
      </div>
    </React.Fragment>
  );
};

UnstyledRichTextEditor.propTypes = {
  className: PropTypes.string,
  onBlur: PropTypes.func,
  classes: PropTypes.shape({}).isRequired,
  initialContent: PropTypes.string,
  onContentChanged: PropTypes.func,
  ribbonButtons: PropTypes.arrayOf(PropTypes.string),
  i18n: PropTypes.shape({
    getString: PropTypes.func,
  }).isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.shape({}),
};

UnstyledRichTextEditor.defaultProps = {
  className: null,
  onBlur: _.noop,
  initialContent: '',
  onContentChanged: _.noop,
  ribbonButtons: Object.values(RibbonButtons),
  placeholder: null,
  options: {
    defaultFormat: {},
    pasteAsText: true,
  },
};

export const RichTextEditor = withDefaultStyles(
  UnstyledRichTextEditor,
  (_c, { palette = {} }) => ({
    root: {
      border: '1px solid rgb(96, 94, 92)',
      borderRadius: '2px',

      '&:focus-within': {
        outline: `2px solid ${palette.accent}`,
        outlineOffset: '-1px',
      },
    },
    editorRoot: {
      position: 'relative',
      overflow: 'hidden',
    },
    editorPlaceholder: {
      position: 'absolute',
      top: '10px',
      left: '12px',
      marginRight: '12px',
      color: '#605E5C',
      pointerEvents: 'none',
    },
    editorContainer: {
      outline: 'transparent',
      minHeight: '240px',
      margin: '10px',
      lineBreak: 'anywhere',
    },
  })
);
