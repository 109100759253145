import { CampaignType, Scope } from '../shared';

export const hotels = {
  id: 'hotels',
  name: _TL_('Hotels'),
  campaignTypes: [CampaignType.Hotel],
  scope: [Scope.Campaign, Scope.AdGroup],
  checkPermission: permissions => (permissions.dynamic.HotelCampaign && !permissions.dynamic.IsRenameHotelToLodgingEnabled),
  children: [
    {
      id: 'hotels',
      name: _TL_('Hotels'),
      route: 'hotels',
      scope: [Scope.Campaign, Scope.AdGroup],
    },
  ],
};

export const properties = {
  id: 'properties',
  name: _TL_('Properties'),
  campaignTypes: [CampaignType.Hotel],
  scope: [Scope.Campaign, Scope.AdGroup],
  checkPermission: permissions => (permissions.dynamic.HotelCampaign && permissions.dynamic.IsRenameHotelToLodgingEnabled),
  children: [
    {
      id: 'properties',
      name: _TL_('Properties'),
      route: 'properties',
      scope: [Scope.Campaign, Scope.AdGroup],
    },
  ],
};
