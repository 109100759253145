import { Scope, defaultCCItemProperties } from '../../shared';
import { billingAccountLinkPermissions } from '../../client-center/billing-payments';

export const billingPayments = {
  id: 'billing-payments',
  name: _TL_('Billing & Payments'),
  oldUIScope: [Scope.Account],
  children: [
    {
      ...defaultCCItemProperties,
      id: 'billing-payments',
      name: _TL_('Billing & Payments'),
      route: 'billing',
      checkPermission: permission => permission.IsManagementDashboardEnabled,
      accountLinkPermissions: billingAccountLinkPermissions,
    },
  ],
};
