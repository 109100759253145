import _ from 'underscore';
import React from 'react';

export class DropdownAdapter {
  constructor(PrimitiveComponent) {
    this.component = PrimitiveComponent;
  }

  getValue(props) {
    return props.selectedOptions;
  }

  renderComponent(props, value, onChange) {
    const PrimitiveComponent = this.component;

    return (<PrimitiveComponent
      {...props}
      selectedOptions={value}
      onOptionSelect={(event, { selectedOptions }) => {
        onChange(selectedOptions);
        if (_.isFunction(props.onChange)) {
          props.onChange(selectedOptions);
        }
      }}
    />);
  }
}
