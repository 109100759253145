import React from 'react';
import { SettingsContext } from './settings-context';

export function withSettingsConsumer(WrappedComponent) {
  return props => (
    <SettingsContext.Consumer>
      {(settingsContext =>
        (<WrappedComponent
          {...props}
          settingsContext={settingsContext}
        />))}
    </SettingsContext.Consumer>
  );
}
