import { performance } from './performance';
import { extensions } from './extensions';
import { productAds } from './product-ads';
import { changeHistory } from './change-history';
import { targeting } from './targeting';
import { billingAndBudget } from './billing-and-budget';
import { advancedInsights } from './advanced-insights';
import { dynamicSearch } from './dynamic-search';
import { labels } from './labels';
import { hotel, property } from './hotel';
import { appAds } from './app';
import { assets } from './assets';

export const defaultReports = [
  performance,
  extensions,
  productAds,
  appAds,
  assets,
  changeHistory,
  targeting,
  billingAndBudget,
  advancedInsights,
  dynamicSearch,
  hotel,
  property,
  labels,
];
