import React from 'react';
import Media from 'react-media';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { getDisplayName } from '@bingads-webui-react/hoc-utils';

export const withResponsiveSize = (WrappedComponent, sizes) => {
  class WithResponsiveSize extends React.Component {
    constructor(props) {
      super(props);

      this.state = {};

      // sort descending by minWidth
      const sortedSizes = sizes.sort((a, b) => b.minWidth - a.minWidth);

      // The smallest does not need a query since we can assumed we are in the smallest if
      // all other states are false.
      this.queries = sortedSizes.slice(0, -1);
      this.smallestSizeName = sortedSizes[sortedSizes.length - 1].name;

      for (let i = 0; i < this.queries.length; i += 1) {
        this.state[this.queries[i].name] = false;
      }
    }

    getCurrentSize() {
      // Queries are sorted in descending order by minWdith, so take the first one that is true
      for (let i = 0; i < this.queries.length; i += 1) {
        if (this.state[this.queries[i].name] === true) {
          return this.queries[i].name;
        }
      }

      // If all others are false, that means we are at the smallest size
      return this.smallestSizeName;
    }

    handleChange(name, match) {
      const newState = {};
      newState[name] = match;
      this.setState(newState);
    }

    render() {
      const { offset, ...wrappedComponentProps } = this.props;

      return (
        <React.Fragment>
          {this.queries.map(query => (<Media
            key={`${query.name}${offset}`}
            query={`(min-width: ${query.minWidth + offset}px)`}
            onChange={matches => this.handleChange(query.name, matches)}
          />))}

          <WrappedComponent
            responsiveSize={this.getCurrentSize()}
            {...wrappedComponentProps}
          />
        </React.Fragment>
      );
    }
  }

  WithResponsiveSize.displayName = `withResponsiveSize(${getDisplayName(WrappedComponent)})`;

  hoistNonReactStatics(WithResponsiveSize, WrappedComponent);

  WithResponsiveSize.defaultProps = {
    offset: 0,
  };

  WithResponsiveSize.propTypes = {
    offset: PropTypes.number,
  };

  return WithResponsiveSize;
};
