import { alwaysDisplayed } from '../configs/shared';
import { adPreview } from '../configs/tools/planning';

export const preview = {
  ...adPreview,
  id: 'preview',
  name: _TL_('Ad preview'),
  description: _TL_('Preview your ad location in Microsoft Bing search results.'),
  campaignTypes: alwaysDisplayed,
};
