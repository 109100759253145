import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { withSettingsConsumer } from '@bingads-webui-react/settings-context';
import { withPermissions } from '@bingads-webui-react/hoc-with-permissions';
import { memoize } from '@bingads-webui-universal/primitive-utilities';
import {
  AccountLinkPermissionType as AccountLinkPermissionTypeEnum,
  isMultiAgencyBillingEnabled,
  isValidLinkPermission,
} from '@bingads-webui/multi-agency-utils';
import { siteMapPropType } from '../site-map-prop-type';

const filterByAccountLinkPermission = (siteMap, settingsContext) => {
  const { AccountLinkPermissionType } = settingsContext;

  if (isValidLinkPermission(settingsContext)) {
    return _.reduce(siteMap, (result, item) => {
      if (_.isEmpty(item.accountLinkPermissions)) {
        if (_.isEmpty(item.children)) {
          if (AccountLinkPermissionType === AccountLinkPermissionTypeEnum.Standard) {
            result.push(item);
          }
        } else {
          const children = filterByAccountLinkPermission(item.children, settingsContext);
          if (!_.isEmpty(children)) {
            result.push(_.defaults({ children }, item));
          }
        }
      } else if (_.isArray(item.accountLinkPermissions) && item.accountLinkPermissions.includes(AccountLinkPermissionType)) {
        result.push(item);
      }
      return result;
    }, []);
  }

  return siteMap;
};

const filterByAccountLinkPermissionCached = memoize(filterByAccountLinkPermission);

export function processAccountLinkPermission(Component, useCache = false) {
  const Wrapper = ({ siteMap, ...props }) => {
    const { settingsContext, permissions } = props;

    if (!isMultiAgencyBillingEnabled(permissions) || permissions.IsInternalUser) {
      return (<Component {...props} siteMap={siteMap} />);
    }

    const processorFunc = useCache ? filterByAccountLinkPermissionCached : filterByAccountLinkPermission;
    const processedSiteMap = processorFunc(siteMap, settingsContext);

    return (<Component {...props} siteMap={processedSiteMap} />);
  };

  Wrapper.propTypes = {
    siteMap: siteMapPropType.isRequired,
    settingsContext: PropTypes.shape({
      AccountLinkPermissionType: PropTypes.number,
    }),
    permissions: PropTypes.objectOf(PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.objectOf(PropTypes.bool),
      PropTypes.number,
    ])).isRequired,
  };

  Wrapper.defaultProps = {
    settingsContext: {},
  };

  return withPermissions(withSettingsConsumer(Wrapper));
}
