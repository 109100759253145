import { accountsSummary } from '../configs/accounts/accounts-summary';
import { overview as accountsOverview } from '../configs/accounts/overview';
import { recommendations as accountsRecommendations } from '../configs/accounts/recommendations';
import { auctionInsights } from '../configs/accounts/auction-insights';
import { Scope } from '../configs/shared';

export const allAccounts = {
  id: 'all-accounts',
  name: _TL_('All accounts'),
  description: _TL_('Manage your account-level settings.'),
  oldUIScope: [Scope.Customer],
  scope: [Scope.Customer],
  route: 'accounts',
  children: [
    accountsOverview,
    accountsSummary,
    accountsRecommendations,
    auctionInsights,
  ],
};
