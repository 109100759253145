import { Scope } from '../shared';

export const accountIdentityVerificationAppeal = {
  id: 'accountIdentityVerificationAppealStandalone',
  name: _TL_('Account identity verification appeal'),
  children: [
    {
      id: 'accountIdentityVerificationAppeal',
      name: _TL_('Account identity verification appeal'),
      route: 'account/IdentityVerificationAppeal',
      scope: [Scope.Account],
    },
  ],
};
