import _ from 'underscore';
import { CampaignType, Scope } from '../shared';

export const feedsItems = {
  id: 'feeds-items',
  name: _TL_('Feed items'),
  scope: [Scope.Campaign],
  campaignTypes: [CampaignType.Audience],
  currentCampaign:
    campaign => _.isEmpty(campaign)
      || (_.result(CampaignType, campaign.CampaignType) === CampaignType.Audience
        && campaign.CampaignSubType === 'AudienceDynamicFeedBased'
        && campaign.CampaignSettings.length > 0
        && (_.has(campaign.CampaignSettings[0], 'FeedId') ||
          _.has(campaign.CampaignSettings[0], 'ProviderId'))),
  children: [
    {
      id: 'feeds-items',
      name: _TL_('Feed items'),
      route: 'feeds/feeditems',
      checkPermission: permissions => permissions.IsEnabledForDynamicFeedCampaign,
    },
  ],
};
