import { Scope, ScopeV2, alwaysDisplayed } from '../shared';

ScopeV2.add('BulkEditDetail', 'bulkEditSessionId', ScopeV2.Account);

export const bulkEditDetail = {
  id: 'bulk-edits-detail',
  name: _TL_('Create association'),
  scope: [Scope.Account],
  scopeV2: [ScopeV2.Account, ScopeV2.BulkEditDetail],
  campaignTypes: alwaysDisplayed,
  children: [
    {
      id: 'bulk-edits-detail-page',
      name: _TL_('Detail'),
      route: 'bulkeditdetail',
      scope: [Scope.Account],
      scopeV2: [ScopeV2.Account, ScopeV2.BulkEditDetail],
      campaignTypes: alwaysDisplayed,
    },
  ],
};
