import React from 'react';
import { memoize } from '@bingads-webui-universal/primitive-utilities';
import { filterMapByRootIds } from '../site-map-utilities';
import { siteMapPropType } from '../site-map-prop-type';

const filterMapByRootIdsCached = memoize(filterMapByRootIds);

export function processRootFilter(Component, rootIds = null, useCache = false) {
  const Wrapper = ({ siteMap, ...props }) => {
    const processorFunc = useCache ? filterMapByRootIdsCached : filterMapByRootIds;
    const processedSiteMap = processorFunc(siteMap, rootIds);
    return (<Component {...props} siteMap={processedSiteMap} />);
  };

  Wrapper.propTypes = {
    siteMap: siteMapPropType.isRequired,
  };

  return Wrapper;
}
