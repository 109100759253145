import _ from 'underscore';

export const matchesField = (field, fieldSelector) => {
  if (_.isArray(fieldSelector)) {
    return fieldSelector.includes(field);
  } else if (_.isString(fieldSelector)) {
    // Handle field name match as well as comma separated set of field names
    const values = fieldSelector.split(',');
    return _.any(values, v => v.trim() === field);
  } else if (_.isObject(fieldSelector) && _.isFunction(fieldSelector.test)) {
    // Handle regex match
    return fieldSelector.test(field);
  } else if (_.isFunction(fieldSelector)) {
    return fieldSelector(field);
  }
  return false;
};

export const excludeFields = values => new RegExp(`^(?!(${values.replace(/,/g, '|')})$).*$`);

export const removeErrorsFromContext = (context, entity = '', fieldSelector = () => true, clientId = () => true, code = () => true) => ({
  ...context,
  [entity]: _.mapObject(
    context[entity] || {},
    (fieldErrors, fieldName) => (matchesField(fieldName, fieldSelector)
      ? _.filter(fieldErrors, v =>
        !(matchesField(v.clientId, clientId) && matchesField(v.errorCode, code)))
      : fieldErrors)
  ),
});

export const ignoreErrorCode = (mappedErrors, ignore) => {
  if (ignore) {
    return _.mapObject(
      mappedErrors || {},
      fieldErrors => _.filter(fieldErrors, v => !matchesField(v.errorCode, ignore))
    );
  }
  return mappedErrors;
};
