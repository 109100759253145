
export const advancedSettings = {
  id: 'advancedSettingsStandalone',
  name: _TL_('Preferences'),
  children: [
    {
      id: 'accountAdvancedSettings',
      name: _TL_('Advanced settings'),
      route: 'Account/AdvancedSettings',
    },
  ],
};
