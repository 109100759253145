import React from 'react';
import { siteMapPropType } from './site-map-prop-type';

export const defaultPropName = 'siteMap';

export function createSiteMapWrapper(
  Component,
  composedProcessor,
  newSiteMap,
  propName = defaultPropName
) {
  if (propName === defaultPropName) {
    const WrappedComponent = composedProcessor(Component);
    return props => (<WrappedComponent {...props} siteMap={newSiteMap} />);
  }

  let siteMapBackup = null;

  const processPropName = (WrappedComponent) => {
    const Wrapper = ({ siteMap, ...props }) => {
      const siteMapProp = { [propName]: siteMap };

      if (siteMapBackup) {
        siteMapProp.siteMap = siteMapBackup;
      }

      return (<WrappedComponent {...props} {...siteMapProp} />);
    };

    Wrapper.propTypes = {
      siteMap: siteMapPropType.isRequired,
    };

    return Wrapper;
  };

  const backupOriginalSiteMap = ({ siteMap, ...rest }) => {
    siteMapBackup = siteMap;
    return rest;
  };

  const WrappedComponent = composedProcessor(processPropName(Component));
  return props => (<WrappedComponent {...backupOriginalSiteMap(props)} siteMap={newSiteMap} />);
}
