import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useImperativeHandle } from 'react';
import { getFormatState } from 'roosterjs';

import { withDefaultStyles } from '@bingads-webui-react/with-default-styles';

import { getEditorRibbonStyle } from '../../styles/editor-ribbon-style';
import { useForceUpdate } from '../helper/use-force-update';
import { RibbonButton } from './ribbon-button';
import { ribbonButtons } from './ribbon-buttons';

const UnstyledRibbon = ({
  classes,
  className,
  plugin,
  forceUpdate,
  buttons,
  i18n,
}) => {
  const editor = plugin.getEditor();
  const format = editor && getFormatState(editor);

  const onButtonClicked = useCallback(() => forceUpdate(), [forceUpdate]);

  return editor ? (
    <div className={classNames('rich-text-editor-ribbon', className, classes.root)}>
      {
        buttons.map(buttonName => (
          ribbonButtons[buttonName] ?
            <RibbonButton
              i18n={i18n}
              className={buttonName}
              classes={classes}
              key={buttonName}
              plugin={plugin}
              format={format}
              button={ribbonButtons[buttonName]}
              onClicked={onButtonClicked}
            />
            :
            null
        ))
      }
    </div>
  ) : null;
};

UnstyledRibbon.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}).isRequired,
  // related ribbon plugin to access editor instance
  plugin: PropTypes.shape({}).isRequired,
  forceUpdate: PropTypes.func.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.string).isRequired,
  i18n: PropTypes.shape({
    getString: PropTypes.func,
  }).isRequired,
};

UnstyledRibbon.defaultProps = {
  className: null,
};

const RibbonBase = withDefaultStyles(
  UnstyledRibbon,
  getEditorRibbonStyle
);

export const Ribbon = React.forwardRef((props, ref) => {
  const forceUpdate = useForceUpdate();

  useImperativeHandle(ref, () => ({
    forceUpdate: () => forceUpdate(),
  }));

  return (<RibbonBase {...props} forwardedRef={ref} forceUpdate={forceUpdate} />);
});
