import { Scope, alwaysDisplayed } from '../shared';

export const accountsTransfer = {
  id: 'accounts-transfer',
  name: _TL_('Accounts Transfer'),
  scope: [Scope.Customer],
  campaignTypes: alwaysDisplayed,
  children: [
    {
      id: 'accounts-transfer',
      name: _TL_('Transfer accounts'),
      route: 'accounts/bulktransferaccounts',
      campaignTypes: alwaysDisplayed,
      scope: [Scope.Customer],
    },
  ],
};
