import { Scope, ScopeV2 } from '../shared';

ScopeV2.add('PerformanceTarget', 'ptId', ScopeV2.Account);

export const performanceTargetsOverview = {
  id: 'performance-targets-overview',
  name: _TL_('Performance Targets Overview'),
  scope: [Scope.Account, Scope.PerformanceTarget],
  scopeV2: [ScopeV2.Account, ScopeV2.PerformanceTarget],
  children: [
    {
      id: 'performance-targets-overview',
      name: _TL_('Target details'),
      scope: [Scope.Account, Scope.PerformanceTarget],
      scopeV2: [ScopeV2.Account, ScopeV2.PerformanceTarget],
      oldUIScope: [Scope.Account, Scope.PerformanceTarget],
      route: 'performancetargets/overview',
    },
    {
      id: 'performance-targets-history',
      name: _TL_('Target history'),
      scope: [Scope.Account, Scope.PerformanceTarget],
      scopeV2: [ScopeV2.Account, ScopeV2.PerformanceTarget],
      oldUIScope: [Scope.Account, Scope.PerformanceTarget],
      route: 'performancetargets/history',
    },
    {
      id: 'performance-targets-edit',
      name: _TL_('Settings'),
      scope: [Scope.Account, Scope.PerformanceTarget],
      scopeV2: [ScopeV2.Account, ScopeV2.PerformanceTarget],
      oldUIScope: [Scope.Account, Scope.PerformanceTarget],
      route: 'performancetargets/edit',
    },
  ],
};
