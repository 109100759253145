import { Scope } from '../shared';

export const audiencePlanner = {
  id: 'audience-planner',
  name: _TL_('Audience planner'),
  route: 'audienceplanner',
  checkPermission: permissions => permissions.dynamic.AIMCampaign,
};

export const keywordPlanner = {
  id: 'keyword-planner',
  name: _TL_('Keyword planner'),
  route: 'keywordplanner',
  showTag: {
    text: _TL_('BETA'),
    expDate: new Date('2023/03/31'),
  },
  tagClass: 'blueTag',
};

export const adPreview = {
  id: 'ad-preview',
  name: _TL_('Ad preview & diagnostics'),
  route: 'adpreview',
  scope: [Scope.Account, Scope.Campaign, Scope.AdGroup],
};

export const planning = {
  id: 'planning',
  name: _TL_('Planning'),
  oldUIScope: [Scope.Account],
  children: [
    keywordPlanner, {
      id: 'bid-strategies',
      name: _TL_('Bid strategies'),
      route: 'bidstrategies',
      showTag: _TL_('New'),
    },
    adPreview,
    audiencePlanner,
  ],
};
