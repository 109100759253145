import { Scope, ScopeV2 } from '../../../shared';

export const promotions = {
  id: 'mmc-promotions',
  name: _TL_('Merchant center'),
  scope: [Scope.Store],
  scopeV2: [ScopeV2.Store],
  children: [
    {
      id: 'mmc-promotions',
      name: _TL_('Promotions'),
      route: 'merchantcenter/promotions',
      scope: [Scope.Store],
      scopeV2: [ScopeV2.Store],
      searchable: false,
    },
  ],
};
