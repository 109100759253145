export const associationCreate = {
  id: 'association-create',
  name: _TL_('Create association'),
  children: [
    {
      id: 'association-create',
      name: _TL_('Create association'),
      route: 'createassociation',
    },
  ],
};
