import { CampaignType, Scope } from '../shared';

export const hotelGroups = {
  id: 'hotel-groups',
  name: _TL_('Hotel groups'),
  campaignTypes: [CampaignType.Hotel],
  scope: [Scope.Account, Scope.Campaign, Scope.AdGroup],
  checkPermission: permissions => (permissions.dynamic.HotelCampaign && !permissions.dynamic.IsRenameHotelToLodgingEnabled),
  children: [
    {
      id: 'hotel-groups',
      name: _TL_('Hotel groups'),
      route: 'hotelgroups/hierarchy',
      children: [
        {
          id: 'hierarchy',
          name: _TL_('Hotel groups'),
          route: 'hotelgroups/hierarchy',
        },
        {
          id: 'list',
          name: _TL_('List View'),
          route: 'hotelgroups/list',
        },
      ],
    },
  ],
};

export const propertyGroups = {
  id: 'property-groups',
  name: _TL_('Property groups'),
  campaignTypes: [CampaignType.Hotel],
  scope: [Scope.Account, Scope.Campaign, Scope.AdGroup],
  checkPermission: permissions => (permissions.dynamic.HotelCampaign && permissions.dynamic.IsRenameHotelToLodgingEnabled),
  children: [
    {
      id: 'property-groups',
      name: _TL_('Property groups'),
      route: 'propertygroups/hierarchy',
      children: [
        {
          id: 'hierarchy',
          name: _TL_('Property groups'),
          route: 'propertygroups/hierarchy',
        },
        {
          id: 'list',
          name: _TL_('List View'),
          route: 'propertygroups/list',
        },
      ],
    },
  ],
};
