import { Scope } from '../../shared';


export const ProductFeedPage = {
  id: 'product-feed',
  name: _TL_('Product feeds'),
  oldUIScope: [Scope.Account],
  children: [
    {
      id: 'product-feed',
      name: _TL_('Product feeds'),
      route: 'productfeed',
      checkPermission: permissions => permissions.dynamic.CoOpCampaign &&
        !permissions.dynamic.SponsoredProductAdsV2,
    }, {
      id: 'linked-store',
      name: _TL_('Linked stores'),
      route: 'linkedstore',
      checkPermission: permissions => permissions.dynamic.CoOpCampaign &&
        !permissions.dynamic.SponsoredProductAdsV2,
    },
  ],
};

export const SponsoredProductsPage = {
  id: 'sponsored-products',
  name: _TL_('Shopping for brands'),
  oldUIScope: [Scope.Account],
  route: 'feedsummary',
  checkPermission: permissions => permissions.dynamic.SponsoredProductAdsV2,
  children: [
    {
      id: 'feed-summary',
      name: _TL_('Overview'),
      route: 'feedsummary',
      checkPermission: permissions => permissions.dynamic.SponsoredProductAdsV2,
    },
  ],
};

export const MicrosoftStoreAdsPageGA = {
  id: 'ms-store-ads',
  name: _TL_('Microsoft Store Ads'),
  scope: [Scope.Account],
  children: [
    {
      id: 'ms-store-ads-seller-ids',
      name: _TL_('Microsoft Store Ads'),
      route: 'msstoreads',
      scope: [Scope.Account],
    },
  ],
};
