import { Scope, ScopeV2 } from '../../../shared';

export const feeds = {
  id: 'mmc-feeds',
  name: _TL_('Merchant center'),
  oldUIScope: [Scope.Account],
  scope: [Scope.Store, Scope.MerchantFeed],
  scopeV2: [ScopeV2.Store, ScopeV2.MerchantFeed],
  children: [
    {
      id: 'mmc-feeds',
      name: _TL_('Feeds'),
      route: 'merchantcenter/feeds',
      scope: [Scope.Store],
      scopeV2: [ScopeV2.Store],
      searchable: false,
    },
    {
      id: 'mmc-feed-details',
      name: _TL_('Feed details'),
      route: 'merchantcenter/feed-details',
      scope: [Scope.MerchantFeed],
      scopeV2: [ScopeV2.MerchantFeed],
      searchable: false,
    },
  ],
};
