import _ from 'underscore';
import {
  defaultItem,
  buildTree,
  alwaysDisplayed,
  alwaysDisplayedItem,
  defaultCCItemProperties,
  defaultSmartItemProperties,
  defaultUpItemProperties,
  adInsightItem,
} from './shared';
import { accountsPages } from './accounts/index';
import { campaignPages } from './campaign/index';
import { toolsPages, flatToolsPages, flatSetupPages, flatSmartToolsPages, } from './tools/index';
import { reportsV4Pages } from './reports-v4';
import { hotelCenterPages } from './tools/setup/hotel-center';
import { hotelCenterV2Pages } from './tools/setup/hotel-center-v2';
import { importPages, flatSmartImportPages } from './import';
import { smartCampaignPages } from './smart/index';
import { adInsightPages } from './adinsight/index';
import { standalonePages } from './standalone';
import { clientCenterPages as ccPages, clientCenterStandalonePages as ccStandalonePages } from './client-center';
import { unifiedSmartPages } from './unified-product';
import { bidStrategiesPages } from './bid-strategies';
import { sdkPages } from './ms-ads-sdk';

window.performance.siteMapLatency = {
  eventName: 'PerfMarker/SiteMapLatency',
  eventStartTime: window.performance.now(),
};

export const ccAlwaysDisplayedItem = {
  ...alwaysDisplayedItem,
  ...defaultCCItemProperties,
};

const smartItem = {
  ...alwaysDisplayedItem,
  ...defaultSmartItemProperties,
};

const unifiedSmartItem = {
  ...alwaysDisplayedItem,
  ...defaultUpItemProperties,
};

const hiddenItem = _.defaults({
  campaignTypes: alwaysDisplayed,
  searchable: false,
}, defaultItem);

const ccHiddenItem = {
  ...hiddenItem,
  ...defaultCCItemProperties,
};

export const accountsSiteMap = buildTree(accountsPages, alwaysDisplayedItem, true);
export const bidStrategiesSiteMap = buildTree(bidStrategiesPages, alwaysDisplayedItem, true);
export const campaignSiteMap = buildTree(campaignPages, defaultItem, true);
export const toolsSiteMap = buildTree(toolsPages, alwaysDisplayedItem, true);
export const flatToolsSiteMap = buildTree(flatToolsPages, hiddenItem, true);
export const flatSmartToolsSiteMap = buildTree(flatSmartToolsPages, hiddenItem, true);
export const flatSetupSiteMap = buildTree(flatSetupPages, hiddenItem, true);
export const reportsV4SiteMap = buildTree(reportsV4Pages, alwaysDisplayedItem, true);
export const importSiteMap = buildTree(importPages, alwaysDisplayedItem, true);
export const flatSmartImportSiteMap = buildTree(flatSmartImportPages, alwaysDisplayedItem, true);
export const standaloneSiteMap = buildTree(standalonePages, hiddenItem, true);
export const hotelCenterSiteMap = buildTree(hotelCenterPages, hiddenItem, true);
export const hotelCenterV2SiteMap = buildTree(hotelCenterV2Pages, hiddenItem, true);
export const clientCenterSiteMap = buildTree(ccPages, ccAlwaysDisplayedItem, true);
export const clientCenterStandAloneSiteMap = buildTree(ccStandalonePages, ccHiddenItem, true);
export const unifiedSmartSiteMap = buildTree(unifiedSmartPages, unifiedSmartItem, true);
export const sdkSiteMap = buildTree(sdkPages, hiddenItem, true);

// we need to add this smartCampaignsSiteMap to allSiteMap when smart new ui is ready.
// currently we use another smart sitemap in campaign folder when ui-next user click smart campaign
// global filter. we may use this one to replace it later.
// this one has the same search string and id, so we don't add it to allSiteMap for now
export const smartCampaignsSiteMap = buildTree(smartCampaignPages, smartItem, true);

export const adInsightSiteMap = buildTree(adInsightPages, adInsightItem, true);

export const allClientCenterSiteMap = _.union(clientCenterSiteMap, clientCenterStandAloneSiteMap);
export const allSiteMap = _.union(
  campaignSiteMap, accountsSiteMap, toolsSiteMap,
  standaloneSiteMap, reportsV4SiteMap, importSiteMap,
  clientCenterSiteMap, clientCenterStandAloneSiteMap, adInsightSiteMap,
  bidStrategiesSiteMap,
  sdkSiteMap
);

window.performance.siteMapLatency.eventEndTime = window.performance.now();
