import { Scope, isSmartPageStandalone } from '../../shared';

export const businessDataPages = [
  {
    id: 'ad-customizer-feeds',
    name: _TL_('Ad customizer feeds'),
    route: 'feeds/adcustomizer',
    checkPermission: permission => !permission.IsSmartMode && !permission.IsUnifiedSmartMode,
    children: [
      {
        id: 'ad-customizer-feeds',
        name: _TL_('Ad customizer feeds'),
        route: 'feeds/adcustomizer',
        checkPermission: permission =>
          !permission.IsSmartMode && !permission.IsUnifiedSmartMode,
      },
      {
        id: 'upload-history',
        name: _TL_('Upload history'),
        route: 'feeds/adcustomizer/uploadhistory',
      },
    ],
  },
  {
    id: 'dynamic-data-feeds',
    name: _TL_('Dynamic data feeds'),
    route: 'feeds/dynamicdata',
    checkPermission: permissions => permissions.dynamic.DynamicData
            || permissions.IsEnabledForDynamicFeedCampaign,
    children: [
      {
        id: 'dynamic-data-feeds',
        name: _TL_('Dynamic data feeds'),
        route: 'feeds/dynamicdata',
      },
      {
        id: 'upload-history',
        name: _TL_('Upload history'),
        route: 'feeds/dynamicdata/uploadhistory',
      },
    ],
  },
  {
    id: 'page-feeds',
    name: _TL_('Page feeds'),
    route: 'feeds/page',
    checkPermission: permissions => permissions.dynamic.DynamicSearchAdsV1,
    children: [
      {
        id: 'page-feeds',
        name: _TL_('Page feeds'),
        route: 'feeds/page',
      },
      {
        id: 'upload-history',
        name: _TL_('Upload history'),
        route: 'feeds/page/uploadhistory',
      },
    ],
  },
  {
    id: 'customizer-attributes',
    name: _TL_('Ad customizer attributes'),
    route: 'feeds/attribute',
    children: [
      {
        id: 'customizer-attributes',
        name: _TL_('Ad customizer attributes'),
        route: 'feeds/attribute',
      },
      {
        id: 'customizer-attributes-history',
        name: _TL_('Upload history'),
        route: 'feeds/attribute/uploadhistory',
      },
      {
        id: 'customizer-attributes-schedules',
        name: _TL_('Schedules'),
        route: 'feeds/attribute/schedules',
      },
    ],
  },
];

export const businessData = {
  id: 'business-data',
  name: _TL_('Business Data'),
  oldUIScope: [Scope.Account],
  children: [
    {
      id: 'business-data',
      name: _TL_('Business data'),
      route: 'feeds/attribute',
      checkPermission: permission =>
        !permission.IsSmartMode && !permission.IsUnifiedSmartMode
        && !isSmartPageStandalone(permission),
      children: businessDataPages,
    },
  ],
};
