import { Scope, ScopeV2 } from '../../../shared';

export const settings = {
  id: 'mmc-settings',
  name: _TL_('Merchant center'),
  scope: [Scope.Store],
  scopeV2: [ScopeV2.Store],
  children: [
    {
      id: 'mmc-settings',
      name: _TL_('Settings'),
      route: 'merchantcenter/settings',
      scope: [Scope.Store],
      scopeV2: [ScopeV2.Store],
      searchable: false,
      children: [
        {
          id: 'mmc-ftp',
          name: _TL_('FTP/SFTP'),
          route: 'merchantcenter/settings/ftp',
          scope: [Scope.Store],
          scopeV2: [ScopeV2.Store],
          searchable: false,
        },
        {
          id: 'mmc-shipping',
          name: _TL_('Shipping (US only)'),
          route: 'merchantcenter/settings/shipping',
          scope: [Scope.Store],
          scopeV2: [ScopeV2.Store],
          searchable: false,
        },
        {
          id: 'mmc-automatic-item-updates',
          name: _TL_('Automatic updates'),
          route: 'merchantcenter/settings/autoupdates',
          scope: [Scope.Store],
          scopeV2: [ScopeV2.Store],
          searchable: false,
        },
        {
          id: 'mmc-product-listings',
          name: _TL_('Product listings'),
          route: 'merchantcenter/settings/productlistings',
          scope: [Scope.Store],
          scopeV2: [ScopeV2.Store],
          searchable: false,
          checkPermission: permissions => !permissions.IsDomesticSimplifiedChineseEnabled,
        },
        {
          id: 'mmc-aggregators',
          name: _TL_('Aggregators'),
          route: 'merchantcenter/settings/aggregators',
          scope: [Scope.Store],
          scopeV2: [ScopeV2.Store],
          searchable: false,
          checkPermission: permissions => !permissions.IsDomesticSimplifiedChineseEnabled,
        },
      ],
    },
  ],
};
