import _ from 'underscore';
import URI from 'urijs';
import { createLocation, createPath } from 'history';

/**
 * NavigationObject object
 * @typedef {object} NavigationObject
 * @property {bool} [forceRefresh] the value indicating to force the navigation to
 * proceed to refresh (aka server-side redirection) if set to true
 * @property {string|object} [path] the path or location object to navigate to
 */

/**
 * pilot model for NavigationObject properties
 */
export const navigationObjectFormat = {
  forceRefresh: 'forceRefresh',
  path: 'path',
  basename: 'basename',
};

/**
 * Ensure that each basename start with a slash and do not end with a slash
 * @param {string} basename the string basename given to the router
 * @return {string} the expected basename react-router is actually using for navigation
 */
export const normalizeBasename = (basename) => {
  const addLeadingSlash = b => (b.charAt(0) === '/' ? b : `/${b}`);
  const stripTrailingSlash = b => (b.charAt(b.length - 1) === '/' ? b.slice(0, -1) : b);

  return basename ? stripTrailingSlash(addLeadingSlash(basename)) : '';
};

/**
 * We consider to add the basename only if there is a basename in param and the url is relative
 * Returns true if the relative path doesn't start with the basename
 * @param {string} path the path to navigate to
 * @param {string} basename the string basename given to the router
 * @return {boolean} true if the path doesn't start with the basename or is not relative
 */
const pathIsRelativeAndNeedBasename = (path, basename) => {
  const uri = URI(path);
  if (!basename || uri.is('absolute')) {
    return false;
  }
  const firstPathSegment = _.first(uri.segment());
  const basenameSegment = _.first(URI(basename).segment());
  return firstPathSegment !== basenameSegment;
};

/**
 * createHref exists in the source code of react-router's `history`
 * but was not externalized so it is forked here.
 * @param {object|string} locationOrPath the location object or path to navigate to
 * @param {string} basename the string basename given to the router
 * @return {string} the href string from the basename and the location object in parameter,
 * leveraging react-router's `history` createPath function
 */
export const createHref = (locationOrPath, basename) => {
  const path = _.isObject(locationOrPath) ? createPath(locationOrPath) : locationOrPath;
  if (pathIsRelativeAndNeedBasename(path, basename)) {
    return normalizeBasename(basename) + path;
  }
  return path;
};

/**
 * Override memo with the processor function callback.
 * Processor can return a boolean to force refresh or not, or return an entire NavigationObject.
 * Each processor will receive in parameter the path we are about to navigate to,
 * and the previous NavigationObject for reference.
 * @param {NavigationObject} previousNavigationObject The previous NavigationObject
 * @param {function} processor The function callback that make some navigation switch decision
 * @return {NavigationObject} The new navigationObject from the processor result
 */
export const reduceProcessor = (previousNavigationObject, processor) => {
  const result = _.isFunction(processor)
    ? processor(previousNavigationObject[navigationObjectFormat.path], { previousNavigationObject })
    : null;
  if (_.isBoolean(result)) {
    return _.extend({}, previousNavigationObject, {
      [navigationObjectFormat.forceRefresh]: result,
    });
  }
  if (_.isObject(result)) {
    const navigationObject = _.pick(result, _.values(navigationObjectFormat));
    return _.extend({}, previousNavigationObject, navigationObject);
  }
  return previousNavigationObject;
};

/**
 * Force refresh (aka server side redirect) for location object or string path with absolute URL
 * @param {object|string} path The path or location object to navigate to
 * @return {NavigationObject} The { forceRefresh:true, path } NavigationObject if path is absolute,
 * or null otherwise.
 */
export const absoluteUrlProcessor = (path) => {
  const location = createLocation(path);
  const uri = URI(location.pathname);
  if (uri.is('absolute')) {
    if (location.search) {
      uri.search(location.search);
    }
    return {
      [navigationObjectFormat.forceRefresh]: true,
      [navigationObjectFormat.path]: uri.toString(),
    };
  }
  return null;
};

/**
 * Ensure that if forceRefresh=true, we return a correct stringified Path with the basename
 * for a correct server side redirect. Server side redirect can't navigate with object in parameter
 * @param {object|string} path The path or location object to navigate to
 * @param {NavigationObject} previousNavigationObject The previous NavigationObject passed in
 * @return {NavigationObject} The { path: path.toString() } NavigationObject if forceRefresh==true
 * and path is an object, or null otherwise
 */
export const normalizeServerSideNavigationProcessor = (path, { previousNavigationObject }) => {
  if (previousNavigationObject[navigationObjectFormat.forceRefresh] === true) {
    const basename = previousNavigationObject[navigationObjectFormat.basename];
    return {
      [navigationObjectFormat.path]: createHref(path, basename),
    };
  }
  return null;
};

/**
 * A list of processors from params will be evaluated, in order of importance, to finally
 * return the last NavigationObject before proceed with the navigation.
 * This function always finish by evaluating the absoluteUrlProcessor
 * and normalizeServerSideNavigationProcessor, in this order.
 * @param {function[]} processors A list of processors passed in from the App. The order matters:
 * the last processor is overriding previous processors
 * @return {NavigationObject} The { path, forceRefresh } NavigationObject to help in the navigation
 */
export const getNavigationObject = ({ processors, path, basename }) => _.reduce(
  [...processors, ...[absoluteUrlProcessor, normalizeServerSideNavigationProcessor]],
  reduceProcessor,
  { forceRefresh: false, path, basename }
);
