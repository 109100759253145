import _ from 'underscore';
import { CampaignType, Scope } from '../shared';

export const adGroups = {
  id: 'adgroups',
  name: _TL_('Ad groups'),
  scope: [Scope.Account, Scope.Campaign],
  campaignTypes: [
    CampaignType.Default,
    CampaignType.Shopping,
    CampaignType.Audience,
    CampaignType.DynamicSearchAds,
    CampaignType.Smart,
    CampaignType.Hotel,
    CampaignType.LinkedIn,
  ],
  children: [
    {
      id: 'adgroups',
      name: _TL_('Ad groups'),
      route: 'adgroups',
      scope: [Scope.Account, Scope.Campaign],
      shortcut: 'g+j',
    }, {
      id: 'auction-insights',
      name: _TL_('Auction insights'),
      route: 'adgroupsauction',
      campaignTypes: [CampaignType.Default, CampaignType.Shopping],
      scope: [Scope.Account, Scope.Campaign],
      checkPermission: permissions => !permissions.IsHideAuctionInsightsCompetitionUI && (!permissions.IsDomesticSimplifiedChineseEnabled || (permissions.IsDomesticSimplifiedChineseEnabled && _.get(permissions, ['dynamic', 'AuctionInsightForCN'], false))),
    },
  ],
};
