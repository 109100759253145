import { Scope } from '../../shared';

export const sharedBudgetsPage = {
  id: 'shared-budgets',
  name: _TL_('Shared budgets'),
  oldUIScope: [Scope.Account],
  children: [
    {
      id: 'shared-budgets',
      name: _TL_('Shared budgets'),
      route: 'sharedbudgets',
    },
  ],
};
