import { allScopes, defaultScopes } from '../shared';

export const importCenter = {
  id: 'import-center',
  name: _TL_('Import Management'),
  scope: allScopes,
  smartAccountEnabled: true,
  children: [
    {
      id: 'import-center',
      name: _TL_('Import Management'),
      route: 'importcenter/all',
      scope: allScopes,
      smartAccountEnabled: true,
      checkPermission: permissions => permissions.dynamic.ImportCenterPrototype,
      children: [
        {
          id: 'all-imports',
          name: _TL_('All Imports'),
          route: 'importcenter/all',
          scope: allScopes,
          smartAccountEnabled: true,
          children: [
            {
              id: 'dashboard',
              name: _TL_('Dashboard'),
              route: 'importcenter/all/dashboard',
              smartAccountEnabled: true,
              scope: allScopes,
            },
            {
              id: 'recommendations',
              name: _TL_('Recommendations'),
              route: 'importcenter/all/recommendations',
              smartAccountEnabled: true,
              scope: allScopes,
            },
          ],
        },
        {
          id: 'google-imports',
          name: _TL_('Google Imports'),
          route: 'importcenter/google/import',
          checkPermission: permissions => !permissions.IsDomesticSimplifiedChineseEnabled,
          scope: allScopes,
          smartAccountEnabled: true,
          children: [
            {
              id: 'google-import',
              name: _TL_('Import'),
              route: 'importcenter/google/import',
              smartAccountEnabled: true,
              scope: allScopes,
            }, {
              id: 'google-schedule',
              name: _TL_('Schedule'),
              route: 'importcenter/google/schedule',
              smartAccountEnabled: true,
              scope: allScopes,
            }, {
              id: 'google-History',
              name: _TL_('History'),
              route: 'importcenter/google/history',
              smartAccountEnabled: true,
              scope: allScopes,
            },
          ],
        },
        {
          id: 'facebook-imports',
          name: _TL_('Meta Imports'),
          route: 'importcenter/facebook/import',
          checkPermission: permissions => permissions.dynamic.FacebookImport && !permissions.IsDomesticSimplifiedChineseEnabled,
          scope: defaultScopes,
          smartAccountEnabled: false,
          children: [
            {
              id: 'facebook-import',
              name: _TL_('Import'),
              route: 'importcenter/facebook/import',
              scope: defaultScopes,
            }, {
              id: 'facebook-schedule',
              name: _TL_('Schedule'),
              route: 'importcenter/facebook/schedule',
              scope: defaultScopes,
            }, {
              id: 'facebook-History',
              name: _TL_('History'),
              route: 'importcenter/facebook/history',
              scope: defaultScopes,
            },
          ],
        },
        {
          id: 'amazon-imports',
          name: _TL_('Amazon Imports'),
          route: 'importcenter/amazon/import',
          checkPermission: permissions => (permissions.IsAmazonImportEnabled ||
            permissions.dynamic.AmazonImport) && !permissions.IsDomesticSimplifiedChineseEnabled,
          scope: defaultScopes,
          smartAccountEnabled: false,
          children: [
            {
              id: 'amazon-import',
              name: _TL_('Import'),
              route: 'importcenter/amazon/import',
              scope: defaultScopes,
            }, {
              id: 'amazon-schedule',
              name: _TL_('Schedule'),
              route: 'importcenter/amazon/schedule',
              scope: defaultScopes,
            }, {
              id: 'amazon-History',
              name: _TL_('History'),
              route: 'importcenter/amazon/history',
              scope: defaultScopes,
            },
          ],
        },
        {
          id: 'pinterest-imports',
          name: _TL_('Pinterest Imports'),
          route: 'importcenter/pinterest/import',
          checkPermission: permissions => !permissions.IsDomesticSimplifiedChineseEnabled,
          scope: defaultScopes,
          smartAccountEnabled: false,
          children: [
            {
              id: 'pinterest-import',
              name: _TL_('Import'),
              route: 'importcenter/pinterest/import',
              scope: defaultScopes,
            }, {
              id: 'pinterest-schedule',
              name: _TL_('Schedule'),
              route: 'importcenter/pinterest/schedule',
              scope: defaultScopes,
            }, {
              id: 'pinterest-History',
              name: _TL_('History'),
              route: 'importcenter/pinterest/history',
              scope: defaultScopes,
            },
          ],
        },
        {
          id: 'file-imports',
          name: _TL_('File Imports'),
          route: 'importcenter/file/import',
          scope: defaultScopes,
          smartAccountEnabled: false,
          children: [
            {
              id: 'file-import',
              name: _TL_('Import'),
              route: 'importcenter/file/import',
              scope: defaultScopes,
            }, {
              id: 'file-History',
              name: _TL_('History'),
              route: 'importcenter/file/history',
              scope: defaultScopes,
            },
          ],
        },
      ],
    },

  ],
};
