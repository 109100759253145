import React, { memo } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

export function ErrorMessagesUnwrapped({
  messages,
  className,
}) {
  if (_.isEmpty(messages) || !_.isArray(messages)) {
    return null;
  }
  return (
    <div role="alert">
      {_.map(messages, (message, key) => (_.has(message, 'DangerousHtmlMessage')
        ? (
          <div
            key={key}
            className={className}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: message.DangerousHtmlMessage }}
          />
        ) : (
          <div key={key} className={className}>
            {message}
          </div>
        )))}
    </div>
  );
}

ErrorMessagesUnwrapped.displayName = 'ErrorMessages';

ErrorMessagesUnwrapped.propTypes = {
  className: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      DangerousHtmlMessage: PropTypes.string,
    }),
  ])),
};

ErrorMessagesUnwrapped.defaultProps = {
  className: 'error',
  messages: [],
};

export const ErrorMessages = memo(ErrorMessagesUnwrapped);
