import _ from 'underscore';
import { CampaignType, Scope, alwaysDisplayed } from '../shared';

export const campaigns = {
  id: 'campaigns',
  name: _TL_('Campaigns'),
  scope: [Scope.Account],
  campaignTypes: alwaysDisplayed,
  children: [
    {
      id: 'campaigns',
      name: _TL_('Campaigns'),
      route: 'campaigns',
      campaignTypes: alwaysDisplayed,
      shortcut: 'g+c',
      scope: [Scope.Account],
    }, {
      id: 'auction-insights',
      name: _TL_('Auction insights'),
      route: 'campaignsauction',
      campaignTypes: [CampaignType.Default, CampaignType.Shopping],
      checkPermission: permissions => !permissions.IsHideAuctionInsightsCompetitionUI && (!permissions.IsDomesticSimplifiedChineseEnabled || (permissions.IsDomesticSimplifiedChineseEnabled && _.get(permissions, ['dynamic', 'AuctionInsightForCN'], false))),
      scope: [Scope.Account],
    },
  ],
};
