import { Scope } from '../shared';

export const UKFCAVerification = {
  id: 'UKFCAVerification',
  name: _TL_('UK FCA verification'),
  children: [
    {
      id: 'UKFCAWorkflow',
      name: _TL_('UK FCA verification workflow'),
      route: 'UKFCA/Workflow',
      scope: [Scope.Account],
    },
    {
      id: 'UKFCAUpdateDomains',
      name: _TL_('UK FCA verification update domains'),
      route: 'UKFCA/UpdateDomains',
      scope: [Scope.Account],
    },
  ],
};
