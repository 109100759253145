import _ from 'underscore';

export function unpack(model, path = '') {
  return _.reduce(path.split('/'), (obj, seg) => _.result(obj, seg), model);
}

export function pack(field, path = '') {
  return _.reduceRight(path.split('/'), (obj, seg) => ({ [seg]: obj }), field);
}

export function merge(target, source) {
  _.each(_.keys(source), (key) => {
    if (_.has(target, key)) {
      merge(target[key], source[key]);
    } else {
      _.extend(target, { [key]: source[key] });
    }
  });
}
