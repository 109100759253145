import { defaultUpItemProperties, isUnifiedProductEnabled } from '../../../shared';

export const upChangeHistory = {
  id: 'esc-change-history',
  name: _TL_('Change history'),
  children: [
    {
      ...defaultUpItemProperties,
      id: 'esc-change-history',
      name: _TL_('Change history'),
      route: 'ads/change-history',
      isUnifiedProductPage: true,
      checkPermission: p => isUnifiedProductEnabled(p) && p.dynamic.IsESCChangeHistoryEnabled && p.IsInternalUser,
    },
  ],
};
