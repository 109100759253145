import { CampaignType, Scope, AdGroupType } from '../shared';

const adGroupTypes = [AdGroupType.SearchStandard, AdGroupType.SearchDynamic];

export const audiences = {
  id: 'audiences',
  name: _TL_('Audiences'),
  campaignTypes: [CampaignType.Default, CampaignType.Shopping, CampaignType.Audience,
    CampaignType.DynamicSearchAds, CampaignType.Smart, CampaignType.Hotel],
  adGroupTypes,
  smartShoppingCampaignScope: [Scope.Account],
  children: [
    {
      id: 'audiences',
      name: _TL_('Audiences'),
      route: 'audiences',
      scope: [Scope.Account, Scope.Campaign, Scope.AdGroup],
    },
  ],
};
