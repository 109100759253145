import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessages } from './error-messages';
import { withErrorMessage } from './with-error-message';

export function withError(Component, ErrorComponent = ErrorMessages) {
  class ErrorWrapper extends React.PureComponent {
    static propTypes = {
      validationProps: PropTypes.shape({
        onValidate: PropTypes.func,
        alwaysShowError: PropTypes.bool,
        showErrorOnPassiveMessages: PropTypes.bool,
        passiveMessages: PropTypes.arrayOf(PropTypes.string),
        showSingleError: PropTypes.bool,
        errorFilter: PropTypes.func,
        debounce: PropTypes.number,
        customErrorRendering: PropTypes.bool,
        messages: PropTypes.arrayOf(PropTypes.string),
      }),
    };

    static defaultProps = {
      validationProps: {
        onValidate: _.noop,
        alwaysShowError: false,
        showErrorOnPassiveMessages: false,
        passiveMessages: [],
        showSingleError: false,
        errorFilter: undefined,
        debounce: undefined,
        customErrorRendering: false,
        messages: [],
      },
    };

    render() {
      const {
        customErrorRendering, messages,
      } = this.props.validationProps;

      const errorComponent = !_.isEmpty(messages) ?
        (<ErrorComponent messages={messages} />) : null;

      return (
        <React.Fragment>
          <Component
            {...this.props}
            validationProps={this.props.validationProps}
            {...(customErrorRendering ? { renderError: errorComponent } : {})}
          />
          {!customErrorRendering && errorComponent }
        </React.Fragment>
      );
    }
  }

  return withErrorMessage(ErrorWrapper);
}
