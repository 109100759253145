
export const portfolioBid = {
  id: 'portfolio-bid-strategy',
  name: _TL_('Portfolio bid strategies'), // Show in summary bar and page title
  route: 'portfoliobidstrategy',
  checkPermission: permissions =>
    permissions.dynamic.PortfolioBidStrategy
        // `false`, because DataExclusion feature is already GAed, this page has been moved to another place
        && false,
};

export const portfolioBidStrategyPage = {
  id: 'portfolio-bid-strategy',
  name: _TL_('Portfolio bid strategies'), // Show in tools menu
  children: [
    portfolioBid,
  ],
};
