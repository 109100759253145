import { alwaysDisplayed, Scope } from '../shared';

export const settings = {
  id: 'settings',
  name: _TL_('Settings'),
  campaignTypes: alwaysDisplayed,
  smartShoppingCampaignScope: [Scope.Account, Scope.Campaign],
  children: [
    {
      id: 'campaign-settings',
      name: _TL_('Campaign settings'),
      route: 'settings',
      scope: [Scope.Campaign],
      shortcut: 'g+s',
    }, {
      id: 'adgroup-settings',
      name: _TL_('Ad group settings'),
      route: 'settings',
      scope: [Scope.AdGroup],
      shortcut: 'g+s',
    }, {
      id: 'settings',
      name: _TL_('Campaign settings'),
      route: 'settings',
      scope: [Scope.Account],
      shortcut: 'g+s',
    }, {
      id: 'account-settings',
      name: _TL_('Account level options'),
      route: 'accountsettings',
      campaignTypes: alwaysDisplayed,
      scope: [Scope.Account],
    },
  ],
};
