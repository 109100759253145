import { Scope, ScopeV2, alwaysDisplayed } from './../shared';

ScopeV2.add('MccScript', 'scriptId', ScopeV2.Customer);
ScopeV2.add('Script', 'scriptId', ScopeV2.Account);

ScopeV2.add('MccScriptLog', 'logId', ScopeV2.Customer);
ScopeV2.add('ScriptLog', 'logId', ScopeV2.Account);

export const scriptsDetail = {
  id: 'scripts-detail',
  name: '',
  campaignTypes: alwaysDisplayed,
  scope: [Scope.Customer, Scope.Account],
  children: [
    {
      id: 'scripts-create',
      name: _TL_('Create script'),
      route: 'scriptscreate',
      campaignTypes: alwaysDisplayed,
      scope: [Scope.Customer, Scope.Account],
    },
    {
      id: 'scripts-edit',
      name: _TL_('Edit script'),
      route: 'scriptsedit',
      campaignTypes: alwaysDisplayed,
      scope: [Scope.Customer, Scope.Account],
      scopeV2: [Scope.Customer, ScopeV2.MccScript, ScopeV2.Account, ScopeV2.Script],
    },
    {
      id: 'scripts-logs',
      name: _TL_('Logs Details'),
      route: 'scriptslogs',
      campaignTypes: alwaysDisplayed,
      scope: [Scope.Customer, Scope.Account],
      scopeV2: [Scope.Customer, ScopeV2.MccScriptLog, ScopeV2.Account, ScopeV2.ScriptLog],
    },
  ],
};
