import { available, scope } from './common';

export const dynamicSearch = {
  id: 'reports-v4-category-dynamicsearch',
  name: _TL_('Dynamic search ads'),
  title: _TL_('Dynamic search ads reports allow you to see a holistic view of your campaigns’ dynamic ad target, category, and search term performance.'),
  fixedRoute: 'reports-v4/category/dynamicsearch',
  route: 'reports-v4/category/dynamicsearch',
  scope,
  available,
  checkPermission: /* istanbul ignore next */ permissions =>
    !!permissions.dynamic.DynamicSearchAdsV1,
  children: [
    {
      id: 'reports-v4-dsadautotargetreport',
      name: _TL_('Dynamic search ad auto target'),
      title: _TL_('See the clicks, impressions, and other performance metrics of each of your dynamic ad targets.'),
      fixedRoute: 'reports-v4/create/dsadautotargetreport',
      route: 'reports-v4/create/dsadautotargetreport',
      searchAlias: _TL_('Dynamic search ad auto target'),
      scope,
      available,
      apiKey: 'DSAAutoTargetPerformanceReport',
      description: _TL_('To understand how your dynamic ad targets are performing and where bid adjustments may be useful.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.dynamic.DynamicSearchAdsV1,
    }, {
      id: 'reports-v4-dsadcategoryreport',
      name: _TL_('Dynamic search ad category'),
      title: _TL_('See the clicks, impressions, and other performance generated from your website.'),
      fixedRoute: 'reports-v4/create/dsadcategoryreport',
      route: 'reports-v4/create/dsadcategoryreport',
      searchAlias: _TL_('Dynamic search ad category report'),
      scope,
      available,
      apiKey: 'DSACategoryPerformanceReport',
      description: _TL_('To check the performance of your existing category targets or to find new categories worth targeting. '),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.dynamic.DynamicSearchAdsV1,
    }, {
      id: 'reports-v4-dsadsearchtermreport',
      name: _TL_('Dynamic search ad search term'),
      title: _TL_('See search terms that triggered your dynamic search ads and their corresponding headlines, final URLs, and associated performance metrics.'),
      fixedRoute: 'reports-v4/create/dsadsearchtermreport',
      route: 'reports-v4/create/dsadsearchtermreport',
      searchAlias: _TL_('Dynamic search ad search term report'),
      scope,
      available,
      apiKey: 'DSASearchQueryPerformanceReport',
      description: _TL_("To see how your ads perform against search terms. The data can help you find negative keywords (so that you're not spending money targeting the wrong customer), as well as the right keywords that create conversions. "),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.dynamic.DynamicSearchAdsV1,
    },
  ],
};
