import {
  setFontName,
  setFontSize,
  toggleBold,
  toggleItalic,
  toggleUnderline,
  toggleBullet,
  toggleNumbering,
  setIndentation,
  changeCapitalization,
  setAlignment,
  removeLink,
  toggleStrikethrough,
  setDirection,
  clearFormat,
  toggleHeader,
  setTextColor,
  setBackgroundColor,
  getDarkColor,
  Alignment,
  ClearFormatMode,
  Direction,
  Indentation,
} from 'roosterjs';

import { renderInsertLinkDialog } from './insert-link-dialog';

const BOLD_SVG = require('../icons/bold.svg');
const ITALIC_SVG = require('../icons/italic.svg');
const UNDERLINE_SVG = require('../icons/underline.svg');
const BULLETS_SVG = require('../icons/bullets.svg');
const HEADER_SVG = require('../icons/header.svg');
const NUMBERING_SVG = require('../icons/numbering.svg');
const OUTDENT_SVG = require('../icons/outdent.svg');
const INDENT_SVG = require('../icons/indent.svg');
const ALIGNLEFT_SVG = require('../icons/alignleft.svg');
const ALIGNCENTER_SVG = require('../icons/aligncenter.svg');
const ALIGNRIGHT_SVG = require('../icons/alignright.svg');
const UNLINK_SVG = require('../icons/unlink.svg');
const STRIKETHROUGH_SVG = require('../icons/strikethrough.svg');
const LTR_SVG = require('../icons/ltr.svg');
const RTL_SVG = require('../icons/rtl.svg');
const UNDO_SVG = require('../icons/undo.svg');
const REDO_SVG = require('../icons/redo.svg');
const REMOVEFORMAT_SVG = require('../icons/removeformat.svg');
const BACKCOLOR_SVG = require('../icons/backcolor.svg');
const TEXTCOLOR_SVG = require('../icons/textcolor.svg');
const CREATELINK_SVG = require('../icons/createlink.svg');
const FONTSIZE_SVG = require('../icons/fontsize.svg');
const FONTNAME_SVG = require('../icons/fontname.svg');
const CAPITALIZATION_SVG = require('../icons/capitalization.svg');

/**
 * Refer here below link for more button functionality if needed
 * https://github.com/microsoft/roosterjs/blob/master/demo/scripts/controls/ribbon/ribbonButtons.ts
 */
export const ribbonButtons = {
  bold: {
    title: _TL_('Bold'),
    image: BOLD_SVG,
    onClick: toggleBold,
    checked: format => format.isBold,
  },
  italic: {
    title: _TL_('Italic'),
    image: ITALIC_SVG,
    onClick: toggleItalic,
    checked: format => format.isItalic,
  },
  underline: {
    title: _TL_('Underline'),
    image: UNDERLINE_SVG,
    onClick: toggleUnderline,
    checked: format => format.isUnderline,
  },
  fontName: {
    title: _TL_('Font'),
    image: FONTNAME_SVG,
    onClick: setFontName,
    // No need to translate this
    dropdownItems: {
      Arial: 'Arial',
      Calibri: 'Calibri',
      'Courier New': 'Courier New',
      Tahoma: 'Tahoma',
      'Times New Roman': 'Times New Roman',
    },
  },
  fontSize: {
    title: _TL_('Font size'),
    image: FONTSIZE_SVG,
    onClick: setFontSize,
    dropdownItems: {
      '8pt': '8',
      '10pt': '10',
      '12pt': '12',
      '16pt': '16',
      '20pt': '20',
      '36pt': '36',
      '72pt': '72',
    },
  },
  textColor: {
    title: _TL_('Text color'),
    image: TEXTCOLOR_SVG,
    onClick: /* istanbul ignore next */ (editor, color) =>
      setTextColor(editor, {
        lightModeColor: color,
        darkModeColor: getDarkColor(color),
      }),
    // TODO use other component instead of dropdown
    dropdownItems: {
      '#51a7f9': 'Light Blue',
      '#6fc040': 'Light Green',
      '#f5d427': 'Light Yellow',
      '#f3901d': 'Light Orange',
      '#ed5c57': 'Light Red',
      '#b36ae2': 'Light Purple',
      '#0c64c0': 'Blue',
      '#0c882a': 'Green',
      '#dcbe22': 'Yellow',
      '#de6a19': 'Orange',
      '#c82613': 'Red',
      '#763e9b': 'Purple',
      '#174e86': 'Dark Blue',
      '#0f5c1a': 'Dark Green',
      '#c3971d': 'Dark Yellow',
      '#be5b17': 'Dark Orange',
      '#861106': 'Dark Red',
      '#5e327c': 'Dark Purple',
      '#002451': 'Darker Blue',
      '#06400c': 'Darker Green',
      '#a37519': 'Darker Yellow',
      '#934511': 'Darker Orange',
      '#570606': 'Darker Red',
      '#3b204d': 'Darker Purple',
      '#ffffff': 'White',
      '#cccccc': 'Light Gray',
      '#999999': 'Gray',
      '#666666': 'Dark Gray',
      '#333333': 'Darker Gray',
      '#000000': 'Black',
    },
  },
  backColor: {
    title: _TL_('Highlight'),
    image: BACKCOLOR_SVG,
    onClick: /* istanbul ignore next */ (editor, color) =>
      setBackgroundColor(editor, {
        lightModeColor: color,
        darkModeColor: getDarkColor(color),
      }),
    dropdownItems: {
      '#00ffff': 'Cyan',
      '#00ff00': 'Green',
      '#ffff00': 'Yellow',
      '#ff8000': 'Orange',
      '#ff0000': 'Red',
      '#ff00ff': 'Magenta',
      '#80ffff': 'Light Cyan',
      '#80ff80': 'Light Green',
      '#ffff80': 'Light Yellow',
      '#ffc080': 'Light Orange',
      '#ff8080': 'Light Red',
      '#ff80ff': 'Light Magenta',
      '#ffffff': 'White',
      '#cccccc': 'Light Gray',
      '#999999': 'Gray',
      '#666666': 'Dark Gray',
      '#333333': 'Darker Gray',
      '#000000': 'Black',
    },
  },
  capitalization: {
    title: _TL_('Change case'),
    image: CAPITALIZATION_SVG,
    onClick: changeCapitalization,
    dropdownItems: {
      sentence: 'Sentence case.',
      lowercase: 'lowercase',
      uppercase: 'UPPERCASE',
      capitalize: 'Capitalize Each Word',
    },
  },
  bullet: {
    title: _TL_('Bullet'),
    image: BULLETS_SVG,
    onClick: toggleBullet,
    checked: format => format.isBullet,
  },
  numbering: {
    title: _TL_('Numbering'),
    image: NUMBERING_SVG,
    onClick: editor => toggleNumbering(editor),
    checked: format => format.isNumbering,
  },
  outdent: {
    title: _TL_('Decrease indent'),
    image: OUTDENT_SVG,
    onClick: editor => setIndentation(editor, Indentation.Decrease),
  },
  indent: {
    title: _TL_('Increase indent'),
    image: INDENT_SVG,
    onClick: editor => setIndentation(editor, Indentation.Increase),
  },
  alignLeft: {
    title: _TL_('Align left'),
    image: ALIGNLEFT_SVG,
    onClick: editor => setAlignment(editor, Alignment.Left),
  },
  alignCenter: {
    title: _TL_('Align center'),
    image: ALIGNCENTER_SVG,
    onClick: editor => setAlignment(editor, Alignment.Center),
  },
  alignRight: {
    title: _TL_('Align right'),
    image: ALIGNRIGHT_SVG,
    onClick: editor => setAlignment(editor, Alignment.Right),
  },
  insertLink: {
    title: _TL_('Insert hyperlink'),
    image: CREATELINK_SVG,
    onClick: null,
    dropdownItems: { 0: 'dummy' },
    dropdownRenderer: renderInsertLinkDialog,
    preserveOnClickAway: true,
  },
  unlink: {
    title: _TL_('Remove hyperlink'),
    image: UNLINK_SVG,
    onClick: removeLink,
    isDisabled: (_editor, format) => !format.canUnlink,
  },
  strikethrough: {
    title: _TL_('Strikethrough'),
    image: STRIKETHROUGH_SVG,
    onClick: toggleStrikethrough,
    checked: format => format.isStrikeThrough,
  },
  header: {
    title: _TL_('Header'),
    image: HEADER_SVG,
    onClick: (editor, value) => toggleHeader(editor, parseInt(value, 10)),
    dropdownItems: {
      0: 'No header',
      1: 'Header 1',
      2: 'Header 2',
      3: 'Header 3',
      4: 'Header 4',
      5: 'Header 5',
      6: 'Header 6',
    },
  },
  ltr: {
    title: _TL_('Left-to-right'),
    image: LTR_SVG,
    onClick: editor => setDirection(editor, Direction.LeftToRight),
  },
  rtl: {
    title: _TL_('Right-to-left'),
    image: RTL_SVG,
    onClick: editor => setDirection(editor, Direction.RightToLeft),
  },
  undo: {
    title: _TL_('Undo'),
    image: UNDO_SVG,
    onClick: editor => editor.undo(),
    isDisabled: (_editor, format) => !format.canUndo,
  },
  redo: {
    title: _TL_('Redo'),
    image: REDO_SVG,
    onClick: editor => editor.redo(),
    isDisabled: (_editor, format) => !format.canRedo,
  },
  clearFormat: {
    title: _TL_('Remove formatting'),
    image: REMOVEFORMAT_SVG,
    onClick: (editor) => {
      if (!editor.getSelectionTraverser()) {
        return;
      }

      clearFormat(editor, ClearFormatMode.AutoDetect);
    },
  },
  // Disabled this for the moment since we remove all formate before pasting.
  // paste: {
  //   title: _TL_('Paste Again'),
  //   onClick: (editor) => {
  //     editor.focus();
  //     const { data } = getLastClipboardData(editor);
  //     editor.paste(data, true);
  //   },
  //   isDisabled: editor => !getLastClipboardData(editor).data,
  // },
  clear: {
    title: _TL_('Clear'),
    onClick: (editor) => {
      editor.addUndoSnapshot(() => {
        editor.setContent('');
      });
    },
  },
};

export const RibbonButtons = {
  Bold: 'bold',
  Italic: 'italic',
  Underline: 'underline',
  Font: 'fontName',
  FontSize: 'fontSize',
  TextColor: 'textColor',
  Highlight: 'backColor',
  ChangeCase: 'capitalization',
  Bullet: 'bullet',
  Numbering: 'numbering',
  DecreaseIndent: 'outdent',
  IncreaseIndent: 'indent',
  AlignLeft: 'alignLeft',
  AlignCenter: 'alignCenter',
  AlignRight: 'alignRight',
  InsertHyperlink: 'insertLink',
  RemoveHyperlink: 'unlink',
  Strikethrough: 'strikethrough',
  Header: 'header',
  Ltr: 'ltr',
  Rtl: 'rtl',
  Undo: 'undo',
  Redo: 'redo',
  RemoveFormatting: 'clearFormat',
  Paste: 'paste',
  Clear: 'clear',
};
