import { ScopeV2 } from '../shared';

export const adCustomizerFeedsItems = {
  id: 'ad-customizer-feeds-items',
  name: _TL_('Ad customizer feed items'),
  scopeV2: [ScopeV2.Feed],
  children: [
    {
      id: 'ad-customizer-feeds-items',
      name: _TL_('Ad customizer feed items'),
      scopeV2: [ScopeV2.Feed],
      route: 'feeds/adcustomizeritems',
    },
    {
      id: 'schedules',
      name: _TL_('Schedules'),
      scopeV2: [ScopeV2.Feed],
      route: 'feeds/adcustomizeritems/schedules',
    },
    {
      id: 'upload-history',
      name: _TL_('Upload history'),
      scopeV2: [ScopeV2.Feed],
      route: 'feeds/adcustomizeritems/uploadhistory',
    },
  ],
};
