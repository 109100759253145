import { Scope } from '../shared';

export const mobileApp = {
  id: 'app',
  name: _TL_('App'),
  oldUIScope: [Scope.Account],
  children: [
    {
      id: 'mobile-app',
      name: _TL_('Microsoft Advertising mobile app'),
      route: 'advertisingmobile',
      externalLink: 'https://go.microsoft.com/fwlink/?linkid=2173918',
    },
  ],
};
