import { Scope } from '../shared';
import { billingAccountLinkPermissions } from './billing-payments';

export const billingGroupCreation = {
  id: 'billingGroupCreationStandalone',
  name: _TL_('Billing group creation'),
  accountLinkPermissions: billingAccountLinkPermissions,
  children: [
    {
      id: 'billingGroupCreation',
      name: _TL_('Billing group creation'),
      route: 'billingGroupCreation',
      scope: [Scope.Customer],
      checkPermission: permissions => permissions.dynamic.ConsolidatedBillingV2,
    },
  ],
};
