import { Scope, alwaysDisplayed } from '../shared';

export const automatedRule = {
  id: 'rules',
  name: _TL_('Automated rules'),
  scope: [Scope.Account],
  campaignTypes: alwaysDisplayed,
  children: [
    {
      id: 'automated-rules',
      name: _TL_('Automated rules'),
      route: 'automatedruleoperation',
      scope: [Scope.Account],
      campaignTypes: alwaysDisplayed,
    }, {
      id: 'rule-history',
      name: _TL_('Logs'),
      route: 'automatedrulehistory',
      scope: [Scope.Account],
      campaignTypes: alwaysDisplayed,
    },
  ],
};

