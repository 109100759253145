import { Scope, alwaysDisplayed, isSmartPageStandalone } from '../shared';
import { bulkEdit } from './bulk-edits';
import { automatedRule } from './rules';
import { scriptsPage } from './scripts-page';
import { bulkUploads } from './bulk-uploads';

export const bulkActionsPages = [
  bulkEdit,
  automatedRule,
  scriptsPage,
  bulkUploads,
  {
    id: 'mcm-campaign-control',
    name: _TL_('MCM Campaign Control'),
    route: 'mcmcampaigncontrol',
    scope: [Scope.Customer, Scope.Account],
    campaignTypes: alwaysDisplayed,
    checkPermission: permissions => permissions.IsInternalUser,
  },
];

export const advertisingEditor = {
  id: 'advertising-editor',
  name: _TL_('Microsoft Advertising Editor'),
  description: _TL_('Manage accounts and campaigns in bulk and offline with this powerful desktop tool.'),
  route: 'advertisingeditor',
  externalLink: 'http://go.microsoft.com/fwlink/?LinkID=193932',
};

export const advertisingMobile = {
  id: 'advertising-mobile',
  name: _TL_('Microsoft Advertising mobile app'),
  description: _TL_('Manage and monitor your campaigns on the go with Android or iOS.'),
  route: 'advertisingmobile',
  externalLink: 'https://go.microsoft.com/fwlink/?linkid=2173918',
};

export const smartPage = {
  id: 'expert-my-website',
  name: _TL_('Smart Pages'),
  route: 'smartpage',
  externalLink: `${window.location.origin}/campaign/sp/landing${window.location.search}`,
  scope: [Scope.Account],
  checkPermission: permissions => permissions.dynamic.PMaxLite && !isSmartPageStandalone(permissions)
        && !permissions.IsUnifiedSmartMode && !permissions.IsSmartMode,
};

export const bulkActions = {
  id: 'bulk-actions',
  name: _TL_('Bulk actions & apps'),
  scope: [Scope.Customer, Scope.Account],
  children: [...bulkActionsPages, advertisingEditor, advertisingMobile, smartPage],
};
