export const copilotReport = {
  id: 'copilot-report',
  name: _TL_('Copilot report summary'),
  children: [
    {
      id: 'copilot-report',
      name: _TL_('Copilot report summary'),
      route: 'copilotreport',
      checkPermission: permissions => permissions.dynamic.AdsCopilotReportingInsights,
    },
  ],
};
