import { shareOfVoicePage } from './share-of-voice.js';
import { bookingReportPage } from './booking-report.js';

const reportsPages = [shareOfVoicePage, bookingReportPage];

export const reportPages = {
  id: 'property-center-reports',
  name: _TL_('Reports'),
  route: 'propertycenter/sharedofvoice',
  children: reportsPages,
};
