import { assetsPage, performancePage } from '../configs/tools/shared-library/creative-studio-asset-library';
import { Scope } from '../configs/shared';

export const assetLibrary = {
  id: 'asset-library',
  name: _TL_('Asset Library'),
  description: _TL_('Manage all of your creative assets in one place.'),
  oldUIScope: [Scope.Account],
  scope: [Scope.Account],
  route: 'assetlibrary',
  children: [
    assetsPage,
    {
      ...performancePage,
      searchable: true,
    },
  ],
  checkPermission: permissions => !permissions.dynamic.AdsStudioMVP,
};
