export const getInsertLinkDialogStyle = () => ({
  root: {
    width: '300px',
  },
  title: {
    whiteSpace: 'nowrap',
  },
  buttonRow: {
    textAlign: 'center',
  },
  button: {
    width: '80px',
    margin: '10px',
  },
  inputItem: {
    marginBottom: '10px',
  },
});
