import _ from 'underscore';
import React from 'react';
import { memoize } from '@bingads-webui-universal/primitive-utilities';
import { siteMapPropType } from '../site-map-prop-type';
import { CampaignType } from '../configs/shared';
import { filterMapItem } from './filter-map-item';

const filterMapItemBase = (siteMap) => {
  const filter = (mapItem) => {
    if ((!_.isEmpty(mapItem.campaignTypes) && !_.contains(mapItem.campaignTypes, CampaignType.All)) ||
      _.isFunction(mapItem.campaignTypes)
    ) {
      return false;
    }

    if (_.isFunction(mapItem.currentCampaign)) {
      return false;
    }

    if (!_.isEmpty(mapItem.adGroupTypes)) {
      return false;
    }

    if (!_.isEmpty(mapItem.hotelCampaignScope)) {
      return false;
    }
    return true;
  };

  return filterMapItem(siteMap, filter);
};

const filterMapItemCached = memoize(filterMapItemBase);

export function processCampaignUIContext(Component) {
  const Wrapper = ({ siteMap, ...props }) => {
    const processed = filterMapItemCached(siteMap);

    return (<Component {...props} siteMap={processed} />);
  };

  Wrapper.propTypes = {
    siteMap: siteMapPropType.isRequired,
  };

  return Wrapper;
}
