import { CampaignType, Scope, AdGroupType } from '../shared';

export const assetsReport = {
  id: 'assets-report',
  name: _TL_('Assets'),
  campaignTypes: [CampaignType.Default],
  adGroupTypes: [AdGroupType.SearchStandard, AdGroupType.SearchDynamic],
  scope: [Scope.Account, Scope.Campaign, Scope.AdGroup],
  checkPermission: permissions => permissions.dynamic.RSAAssetCampaignAdgroupLevel,
  children: [
    {
      id: 'assets-report',
      name: _TL_('Assets'),
      route: 'assets-report',
    },
  ],
};
