import { CampaignType, AdGroupType, Scope } from '../shared';

export const keywords = {
  id: 'keywords',
  name: _TL_('Keywords'),
  campaignTypes: [CampaignType.Default, CampaignType.Shopping],
  adGroupTypes: [AdGroupType.SearchStandard, AdGroupType.SearchDynamic],
  smartShoppingCampaignScope: [Scope.Account],
  children: [
    {
      id: 'keywords',
      name: _TL_('Keywords'),
      route: 'keywords',
      campaignTypes: [CampaignType.Default],
      shortcut: 'g+k',
    }, {
      id: 'negative-keywords',
      name: _TL_('Negative keywords'),
      route: 'negative-keywords',
      campaignTypes: [CampaignType.Default, CampaignType.Shopping],
      adGroupTypes: [AdGroupType.SearchStandard, AdGroupType.SearchDynamic],
    }, {
      id: 'negative-keywords-lists',
      name: _TL_('Negative keywords lists'),
      route: 'negativekeywordslists',
      campaignTypes: [CampaignType.Default, CampaignType.Shopping],
      adGroupTypes: [AdGroupType.SearchStandard, AdGroupType.SearchDynamic],
    }, {
      id: 'search-terms',
      name: _TL_('Search terms'),
      route: 'searchterms',
      exact: false,
      campaignTypes: [CampaignType.Default, CampaignType.Shopping],
    }, {
      id: 'auction-insights',
      name: _TL_('Auction insights'),
      route: 'keywordsauction',
      campaignTypes: [CampaignType.Default, CampaignType.Shopping],
      checkPermission: permissions => !permissions.IsHideAuctionInsightsCompetitionUI && !permissions.IsDomesticSimplifiedChineseEnabled,
    },
  ],
};
