import { ScopeV2 } from '../shared';

ScopeV2.add('PortfolioBidStrategy', 'portfolioId', ScopeV2.Account);

export const portfolioBidStrategyDetails = {
  id: 'portfolio-bid-strategy-details',
  name: _TL_('Portfolio bid strategy details'),
  scopeV2: [ScopeV2.PortfolioBidStrategy],
  children: [
    {
      id: 'portfolio-bid-strategy-performance',
      name: _TL_('Performance'),
      route: 'portfoliobidstrategy/performance',
      checkPermission: permissions => permissions.dynamic.PortfolioBidStrategy,
      scopeV2: [ScopeV2.PortfolioBidStrategy],
    },
    {
      id: 'portfolio-bid-strategy-settings',
      name: _TL_('Settings'),
      route: 'portfoliobidstrategy/settings',
      checkPermission: permissions => permissions.dynamic.PortfolioBidStrategy,
      scopeV2: [ScopeV2.PortfolioBidStrategy],
    },
  ],
};
