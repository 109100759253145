import { defaultScope, defaultScopeV2 } from './merchant-shared';
import { homePage } from './merchant-center';
import { feeds } from './feeds';
import { importFeed } from './import';
import { promotions } from './promotions';
import { settings } from './settings';
import { stores } from './stores';
import { storeSummary } from './summary';
import { products } from './products';

export const merchantCenterPages = [
  homePage,
  stores,
  storeSummary,
  feeds,
  importFeed,
  promotions,
  settings,
  products,
];

export const merchantCenter = {
  id: 'merchant-center',
  name: _TL_('Merchant Center'),
  scope: defaultScope,
  scopeV2: defaultScopeV2,
  children: merchantCenterPages,
};
