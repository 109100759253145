/* istanbul ignore file */
import _ from 'underscore';
import urijs from 'urijs';
import { Scope } from '../configs/shared';

export const getCurrentScope = (
  currentCampaignId,
  currentAdGroupId,
  currentAccountId,
  currentStoreId,
  currentFeedId
) => {
  if (currentStoreId && currentFeedId) {
    return Scope.MerchantFeed;
  }

  if (currentStoreId) {
    return Scope.Store;
  }

  if (currentAdGroupId) {
    return Scope.AdGroup;
  }

  if (currentCampaignId) {
    return Scope.Campaign;
  }

  if (currentAccountId) {
    return Scope.Account;
  }

  return Scope.Customer;
};

// todo: [rayduan] refactor below methods so that they are not coupled to features
const SCOPE_TO_KEY = {
  [Scope.Account]: 'aid',
  [Scope.Campaign]: 'campaignId',
  [Scope.AdGroup]: 'adGroupId',
  [Scope.Store]: 'storeId',
  [Scope.MerchantFeed]: 'feedId',
};

function trimScopeInternal({
  urlQuery,
  currentScope,
  leastSupportedScope,
}) {
  // i.e. currentScope = 1 = account scope
  // leastSupportedScope = 0 = customer scope
  if (currentScope > leastSupportedScope) {
    const queryToRemove = _.range(leastSupportedScope + 1, currentScope + 1)
      .map(scopeNumber => SCOPE_TO_KEY[scopeNumber]);

    return urijs(urlQuery).removeQuery(queryToRemove).toString();
  }

  // No need to trim scope, return url query as it is
  return urlQuery;
}

export function trimScope(supportedScopes, scopeService, siteMapId) {
  /* eslint-disable object-curly-newline */
  const {
    history,
    values: {
      uid,
      cid,
      aid,
      campaignId,
      adGroupId,
      storeId,
      feedId,
    },
  } = scopeService;
  if (siteMapId === 'import-google' || siteMapId === 'import-file') {
    return scopeService.stringify(_.omit({
      uid,
      cid,
      aid,
    }, value => !value));
  }

  const currentScope = getCurrentScope(campaignId, adGroupId, aid, storeId, feedId);
  const leastSupportedScope = _.isEmpty(supportedScopes) ? Scope.Account : _.max(supportedScopes);

  return trimScopeInternal({
    urlQuery: history.location.search, currentScope, leastSupportedScope,
  });
}
