import { Scope, ScopeV2, alwaysDisplayed } from '../shared';

ScopeV2.add('RuleDetail', 'executionId', ScopeV2.Account);

export const ruleDetail = {
  id: 'rule-detail',
  name: _TL_('Details'),
  scope: [Scope.Account],
  scopeV2: [ScopeV2.Account, ScopeV2.RuleDetail],
  campaignTypes: alwaysDisplayed,
  children: [
    {
      id: 'rule-detail',
      name: _TL_('Details'),
      route: 'automatedruledetail',
      scope: [Scope.Account],
      scopeV2: [ScopeV2.Account, ScopeV2.RuleDetail],
      campaignTypes: alwaysDisplayed,
    },
  ],
};
