import _ from 'underscore';
import URI from 'urijs';
import { createBrowserHistory } from 'history';
import { getNavigationObject, navigationObjectFormat } from './history-processors';
import { serverSidePush, serverSideReplace } from './history-navigation';

/**
 * Creates a history object compatible with React Router <Router /> that handles both
 * server-side (full-page redirect) and client-side (React Router) routing.
 * This function extends and adds properties defined on the React Router default
 * browser history API.
 * @param {object} options          - Configuration options for the history object
 * @param {string} options.basename - Base path for routing that is passed to the React
 *                                    Router browser history
 * @param {function[]} processors   - A list of processors passed in from the App. The order
 * matters, i.e the last processor is always overriding previous values.
 * Each processors received the args (path, options = { previousNavigationObject })
 * @returns {object}                - History object compatible with React Router
 */
export const createWebHistory = ({ basename, processors = [] } = {}) => {
  const history = createBrowserHistory({ basename });
  const clientSidePush = history.push;
  const clientSideReplace = history.replace;

  /**
   * Store the basename for public reference where needed.
   */
  Object.defineProperty(history, 'basename', { get: () => basename });

  const serverOrClientSideNavigation = ({
    serverSideNavigation,
    clientSideNavigation,
    path,
    state,
  }) => {
    const navigationObject = getNavigationObject({ processors, path, basename });
    if (navigationObject[navigationObjectFormat.forceRefresh] === true) {
      serverSideNavigation(navigationObject[navigationObjectFormat.path]);
    } else {
      clientSideNavigation(navigationObject[navigationObjectFormat.path], _.extend({
        previousRoute: window.location.href,
      }, state));
    }
  };

  /**
   * Updated push method that performs a server-side redirect if the path given is an absolute URL
   * and a client-side redirect otherwise.
   * @param {string | object} path - a string indicating the navigation URL, or an object
   *                                 describing a path per the history API
   * @param {object} state         - state to send along with the history location object
   * @returns {void}
   */
  history.push = (path, state) => serverOrClientSideNavigation({
    serverSideNavigation: serverSidePush,
    clientSideNavigation: clientSidePush,
    path,
    state,
  });

  history.replace = (path, state) => serverOrClientSideNavigation({
    serverSideNavigation: serverSideReplace,
    clientSideNavigation: clientSideReplace,
    path,
    state,
  });

  /**
   * Patch the current search query in the URL according to the given params.
   * @param {object} params - patch updates to the current query params in the URL
   * @returns {void}
   */
  history.setSearch = (params) => {
    const search = URI(history.location.search)
      .setSearch(params)
      .query();
    history.push({ search });
  };

  return history;
};
