import { alwaysDisplayed, allScopes, defaultScopes, ScopeV2 } from '../shared';
import { importCenter } from './import-center';

ScopeV2.add('TaskItemId', 'taskItemId', ScopeV2.Account);
ScopeV2.add('TaskItemId', 'taskItemId', ScopeV2.Customer);
ScopeV2.add('ImportId', 'importId', ScopeV2.Account);
ScopeV2.add('ImportId', 'importId', ScopeV2.Customer);
ScopeV2.add('ImportStep', 'importStep', ScopeV2.TaskItemId);
ScopeV2.add('OpportunityId', 'opportunityId', ScopeV2.Account);
ScopeV2.add('OpportunityId', 'opportunityId', ScopeV2.Customer);
ScopeV2.add('OpportunityType', 'opportunityType', ScopeV2.OpportunityId);
ScopeV2.add('Source', 'source', ScopeV2.OpportunityType);
ScopeV2.add('ImportType', 'importType', ScopeV2.Account);
ScopeV2.add('ImportScheduleRecSrc', 'recsrc', ScopeV2.ImportType);
ScopeV2.add('DidSwitchToExpertFromImport', 'didSwitchToExpertFromImport', ScopeV2.Account);

const scopeV2 = [ScopeV2.Customer, ScopeV2.Account, ScopeV2.TaskItemId,
  ScopeV2.ImportId, ScopeV2.ImportStep, ScopeV2.DidSwitchToExpertFromImport];

const importScheduleScopeV2 = [ScopeV2.Customer, ScopeV2.Account,
  ScopeV2.ImportType, ScopeV2.ImportScheduleRecSrc];

export const googleImport = {
  id: 'import-google',
  name: _TL_('Import from Google Ads'),
  checkPermission: permissions => !permissions.IsDomesticSimplifiedChineseEnabled,
  route: 'googleimport',
  scope: allScopes,
  scopeV2,
};

export const facebookImport = {
  id: 'import-facebook',
  name: _TL_('Import from Meta Ads'),
  checkPermission: permissions => permissions.dynamic.FacebookImport && !permissions.IsDomesticSimplifiedChineseEnabled,
  route: 'facebookimport',
  campaignTypes: alwaysDisplayed,
  scope: defaultScopes,
  scopeV2,
};

export const amazonImport = {
  id: 'import-amazon',
  name: _TL_('Import from Amazon Ads'),
  checkPermission: permissions => (permissions.IsAmazonImportEnabled ||
    permissions.dynamic.AmazonImport) && !permissions.IsDomesticSimplifiedChineseEnabled,
  route: 'amazonimport',
  campaignTypes: alwaysDisplayed,
  scope: defaultScopes,
  scopeV2,
  showTag: {
    text: 'New',
    expDate: new Date('2024/12/31'),
  },
};

export const pinterestImport = {
  id: 'import-pinterest',
  name: _TL_('Import from Pinterest Ads'),
  checkPermission: permissions => !permissions.IsDomesticSimplifiedChineseEnabled,
  route: 'pinterestimport',
  campaignTypes: alwaysDisplayed,
  scope: defaultScopes,
  scopeV2,
  showTag: {
    text: 'New',
    expDate: new Date('2023/12/31'),
  },
};

export const fileImport = {
  id: 'import-file',
  name: _TL_('Import from file'),
  route: 'fileimport',
  scope: defaultScopes,
  scopeV2,
};

export const importSchedule = {
  id: 'import-schedule',
  name: _TL_('Import schedule & history'),
  route: 'importschedule',
  campaignTypes: alwaysDisplayed,
  scope: allScopes,
  scopeV2: importScheduleScopeV2,
};

export const importPages = [
  googleImport,
  facebookImport,
  amazonImport,
  pinterestImport,
  fileImport,
  importSchedule,
  importCenter,
];

export const flatSmartImportPages = [
  googleImport,
  importSchedule,
  importCenter,
];
