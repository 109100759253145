import { available, scope } from './common';
import { Scope } from '../../shared';

const accountLevelScopes = scope.filter(_scope => _scope !== Scope.Customer);

export const targeting = {
  id: 'reports-v4-category-targeting',
  name: _TL_('Targeting'),
  title: _TL_('See how your campaigns are doing among the people and places who are seeing your ads.'),
  fixedRoute: 'reports-v4/category/targeting',
  route: 'reports-v4/category/targeting',
  scope,
  available,
  children: [
    {
      id: 'reports-v4-ageandgenderreport',
      name: _TL_('Age and gender'),
      // Duplicate
      title: _TL_('See total impressions and clicks for each campaign or ad group, organized by gender and age group.'),
      fixedRoute: 'reports-v4/create/ageandgenderreport',
      route: 'reports-v4/create/ageandgenderreport',
      searchAlias: _TL_('Age and gender report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'AgeGenderAudienceReport',
      description: _TL_('To discover how your campaigns and ad groups are resonating with different age groups and genders.'),
    }, {
      id: 'reports-v4-genrereport',
      name: _TL_('Genre'),
      // Duplicate
      title: _TL_('Genre.'),
      fixedRoute: 'reports-v4/create/dealgenrereport',
      route: 'reports-v4/create/dealgenrereport',
      searchAlias: _TL_('Genre report'),
      scope: accountLevelScopes,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'GenrePerformanceReport',
      description: _TL_('To find out which genre targets have the best ad performance. You can then validate whether your genre targeting is successful and identify opportunities for improvements.'),
      checkPermission: /* istanbul ignore next */  permissions =>
        permissions.dynamic.AIMCampaign && permissions.dynamic.XandrVideoCampaigns && permissions.dynamic.GenreTargets,
    }, {
      id: 'reports-v4-professionaldemographicsreport',
      name: _TL_('Professional demographics'),
      title: _TL_('See total impressions and clicks for each campaign or ad group, organized by company, industry, and job function.'),
      fixedRoute: 'reports-v4/create/professionaldemographicsreport',
      route: 'reports-v4/create/professionaldemographicsreport',
      searchAlias: _TL_('Professional demographics report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'ProfessionalDemographicsAudienceReport',
      description: _TL_('To discover how your campaigns and ad groups are resonating with different companies, industries, and job functions.'),
      checkPermission: /* istanbul ignore next */  permissions =>
        !!permissions.dynamic.LinkedInTargeting || !!permissions.dynamic.AIMCampaign,
    }, {
      id: 'reports-v4-userlocationreport',
      name: _TL_('User location'),
      // Duplicate
      title: _TL_('See total impressions and clicks for each ad group, organized by the locations where your ads were shown.'),
      fixedRoute: 'reports-v4/create/userlocationreport',
      route: 'reports-v4/create/userlocationreport',
      searchAlias: _TL_('User location report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'UserLocationPerformanceReport',
      description: _TL_('To see where your traffic is coming from broken out by the physical location and the location people are searching for. You can then validate whether your location targeting strategy is successful, and identify opportunities to improve.'),
    }, {
      id: 'reports-v4-geographicreport',
      name: _TL_('Geographic'),
      // Duplicate
      title: _TL_('See total impressions and clicks for each ad group, organized by the locations where your ads were shown.'),
      fixedRoute: 'reports-v4/create/geographicreport',
      route: 'reports-v4/create/geographicreport',
      searchAlias: _TL_('Geographic report'),
      scope,
      available,
      apiKey: 'GeographicPerformanceReport',
      description: _TL_('To see where your traffic is coming from: the physical location of the people searching for your ad or the locations people are searching for. You can then validate whether your location targeting strategy is successful and identify opportunities to improve.'),
    },
  ],
};
