/* istanbul ignore file */
export const ProcessedReconReportsPage = {
  id: 'processedreconreports',
  name: _TL_('Processed Reconciliation Reports'),
  route: 'hotelcenter/processedreconreports',
  checkPermission: /* istanbul ignore next */ (permissions) => {
    const {
      ReconciliationReport, PayPerStay, HotelAds,
    } = permissions.dynamic;
    return ReconciliationReport && PayPerStay && HotelAds;
  },
};
