import { Scope, alwaysDisplayed } from '../shared';

export const bulkEdit = {
  id: 'bulk-edits',
  name: _TL_('Bulk edits'),
  scope: [Scope.Account],
  campaignTypes: alwaysDisplayed,
  children: [
    {
      id: 'bulk-edits',
      name: _TL_('Bulk edits'),
      route: 'bulkeditoperation',
      scope: [Scope.Account],
      campaignTypes: alwaysDisplayed,
    },
  ],
};
