import { Scope } from '../configs/shared';

export const create = {
  id: 'create',
  name: _TL_('Create'),
  description: _TL_(''),
  route: 'createcampaign/objective',
  fixed: true,
  oldUIScope: [Scope.Account],
  scope: [Scope.Account],
};
