import { Scope, ScopeV2, alwaysDisplayed } from '../shared';

const scope = [Scope.Customer, Scope.Account, Scope.Campaign, Scope.AdGroup];

const scopeV2 = [ScopeV2.Customer, ScopeV2.Account, ScopeV2.Campaign, ScopeV2.AdGroup];

export const audienceCreate = {
  id: 'audience-create',
  name: _TL_('Create audience'),
  scope,
  scopeV2,
  campaignTypes: alwaysDisplayed,
  children: [
    {
      id: 'audience-create',
      name: _TL_('Create audience'),
      route: 'audience/create',
    },
  ],
};

export const audienceEdit = {
  id: 'audience-edit',
  name: _TL_('Edit audience'),
  scope,
  scopeV2,
  campaignTypes: alwaysDisplayed,
  children: [
    {
      id: 'audience-edit',
      name: _TL_('Edit audience'),
      route: 'audience/edit',
    },
  ],
};
