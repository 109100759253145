import _ from 'underscore';
import { CampaignType, Scope } from '../shared';

const isExperiment = campaign => campaign && campaign.ExperimentId > 0;
const isSearchCampaignWithDSASettings = (campaign, permissions) => {
  const isDSAMixedModeCampaignEnabled = _.result(permissions, 'IsDSAMixedModeCampaignEnabled', false);
  if (!isDSAMixedModeCampaignEnabled) {
    return false;
  }

  const domainName = _.chain(campaign)
    .result('CampaignSettings')
    .result('0')
    .result('DomainName')
    .value();

  if (domainName) {
    return true;
  }

  return false;
};

export const experiments = {
  id: 'experiments',
  name: _TL_('Experiments'),
  scope: [Scope.Account, Scope.Campaign],
  campaignTypes: [CampaignType.Default],
  currentCampaign: (campaign, permissions) =>
    (_.isEmpty(campaign) || !isExperiment(campaign)) &&
    !isSearchCampaignWithDSASettings(campaign, permissions),
  children: [
    {
      id: 'experiments',
      name: _TL_('Experiments'),
      route: 'experiments',
      scope: [Scope.Account, Scope.Campaign],
    },
  ],
};
