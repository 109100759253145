import _ from 'underscore';
import { defaultScopes } from '../../../shared';
import { feedsPages } from './feeds';
import { reportPages } from './reports';


const overview = {
  id: 'property-center-overview',
  name: _TL_('Overview'),
  route: 'propertycenter/overview',
  searchAlias: _TL_('Property center'),
};
const logos = {
  id: 'property-center-logos',
  name: _TL_('Logos'),
  route: 'propertycenter/logos',
};
const setup = {
  id: 'property-center-setup',
  name: _TL_('Setup'),
  route: 'propertycenter/setup',
  searchable: false,
};

export const hotelCenterV2Pages = [
  overview,
  feedsPages,
  // comment out the following route for API not ready now
  // {
  //   id: 'property-center-matching',
  //   name: _TL_('Matching'),
  //   route: 'propertycenter/matching',
  // },
  logos,
  setup,
  reportPages,
];

export const flatHotelCenterV2Pages = [
  overview,
  ...feedsPages.children,
  // comment out the following route for API not ready now
  // {
  //   id: 'hotel-center-v2-matching',
  //   name: _TL_('Matching'),
  //   route: 'propertycenter/matching',
  // },
  logos,
  setup,
  reportPages,
];

const hotelCenterScope = {
  scope: defaultScopes,
  checkPermission: permissions => permissions.dynamic.HotelCenterV2,
};

function addScopeAndPermission(items) {
  _.each(items, (item) => {
    _.defaults(item, hotelCenterScope);
    if (item.children) {
      addScopeAndPermission(item.children);
    }
  });
}

addScopeAndPermission(hotelCenterV2Pages);

export const hotelCenterV2 = {
  id: 'property-center',
  name: _TL_('Property center'),
  route: 'propertycenter/overview',
  scope: defaultScopes,
  checkPermission: permissions => permissions.dynamic.HotelCenterV2,
  children: [{
    id: 'property-center',
    name: _TL_('Property center'),
    route: 'propertycenter/overview',
    children: hotelCenterV2Pages,
    showTag: {
      text: _TL_('New'),
      expDate: new Date('2024/12/31'),
    },
    checkPermission: permissions => permissions.dynamic.HotelCenterV2,
  }],
};
