import { alwaysDisplayed, CampaignType } from '../shared';

export const changeHistory = {
  id: 'change-history',
  name: _TL_('Change history'),
  campaignTypes: type => (type !== CampaignType.App) && (type !== CampaignType.LinkedIn),
  children: [
    {
      id: 'change-history',
      name: _TL_('Change history'),
      route: 'changehistory',
      campaignTypes: alwaysDisplayed,
    },
  ],
};
