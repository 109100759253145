import { buildTree, alwaysDisplayedItem } from '../configs/shared';
import { accountMap } from './account-map';
import { advertise } from './advertise';
import { allAccounts } from './all-accounts';
import { assetLibrary } from './asset-library';
import { audiences } from './audiences';
import { billing } from './billing';
import { brands } from './brands';
import { budgetAndBid } from './budget-bid';
import { bulkActions, advertisingEditor, advertisingMobile } from './bulk-actions';
import { businessData } from './business-data';
import { businessLocation } from './business-location';
import { clarity } from './clarity';
import { conversions } from './conversions';
import { create } from './create';
import { hotel, hotelV2 } from './hotels';
import { imports } from './imports';
import { keywords } from './keywords';
import { labels } from './labels';
import { merchantCenter } from './merchant-center';
import { microsoftStoreAds } from './microsoft-store-ads';
import { performanceTargets } from './performance-targets';
import { preview } from './preview';
import { productFeed } from './product-feed';
import { reports } from './reports';
import { settings } from './setttings';
import { smartPage } from './smart-page';
import { websiteExclusion } from './website-exluction-list';
import { brandSafetyControls } from './brand-safety-controls';
import { brandLists } from './brand-lists';
import { liftMeasurement } from './lift-measurement';
import { adStudio } from './ad-studio';

const pages = {
  create,
  advertise,
  audiences,
  conversions,
  keywords,
  assetLibrary,
  imports,
  businessData,
  smartPage,
  merchantCenter,
  brands,
  productFeed,
  hotel,
  hotelV2,
  businessLocation,
  microsoftStoreAds,
  brandLists,
  budgetAndBid,
  bulkActions,
  advertisingEditor,
  advertisingMobile,
  labels,
  preview,
  performanceTargets,
  websiteExclusion,
  brandSafetyControls,
  accountMap,
  reports,
  liftMeasurement,
  clarity,
  allAccounts,
  billing,
  settings,
  adStudio,
};

const navRefreshPages = Object.values(pages);

const navRefreshPageIds = {};
Object.keys(pages).forEach((key) => {
  navRefreshPageIds[key] = pages[key].id;
});

export { navRefreshPageIds };

export const navRefreshSiteMap = buildTree(navRefreshPages, alwaysDisplayedItem, true, undefined, true);
