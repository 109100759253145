import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { createMemoryHistory, createHashHistory } from 'history';

import { Router as ReactRouter } from 'react-router-dom';

import { createWebHistory } from './create-web-history';
import { identityParseRoute } from './identity-parse-route';

const routerConfigKeys = ['parseRoute'];

/**
 * React context storing the router configuration object.
 */
const RouterContext = React.createContext({
  [routerConfigKeys]: _.noop,
});

export const RouterConsumer = RouterContext.Consumer;

/**
 * Wrapper around React Router that allows some customization to the default router
 * behavior.
 * @param {object} props - Router configuration options
 * @returns {object}     - Configured router component
 */
export const Router = (props) => {
  const routerConfig = _.pick(props, routerConfigKeys);
  const remainingProps = _.omit(props, routerConfigKeys);

  return (
    <RouterContext.Provider value={routerConfig}>
      <ReactRouter {...remainingProps} />
    </RouterContext.Provider>
  );
};

/*
 * The public API for a <Router> that stores location in memory for testing purpose.
 */
export const MemoryRouter = (props) => {
  const history = createMemoryHistory(props);
  const routerConfig = _.pick(props, routerConfigKeys);
  return <Router history={history} {...routerConfig}>{props.children}</Router>;
};


/*
 * The public API for a <Router> that uses window.location.hash.
 */
export const HashRouter = (props) => {
  const history = createHashHistory(props);
  const routerConfig = _.pick(props, routerConfigKeys);
  return <Router history={history} {...routerConfig}>{props.children}</Router>;
};

Router.propTypes = {
  /**
   * Function that parses the route props provided by React Router into the desired props
   * to send to route-consuming components.
   */
  parseRoute: PropTypes.func,
  /**
   * History object used by React Router for managing history and navigation.
   */
  history: PropTypes.shape(),
};

Router.defaultProps = {
  parseRoute: identityParseRoute,
  history: createWebHistory(),
};

MemoryRouter.propTypes = {
  parseRoute: PropTypes.func,
  children: PropTypes.node,
};

MemoryRouter.defaultProps = {
  parseRoute: identityParseRoute,
  children: null,
};

HashRouter.propTypes = {
  parseRoute: PropTypes.func,
  children: PropTypes.node,
};

HashRouter.defaultProps = {
  parseRoute: identityParseRoute,
  children: null,
};
