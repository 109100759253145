import { audiencePlanner } from '../configs/tools/planning';
import { audiencesLibraryPages } from '../configs/tools/shared-library/audiences-library';

export const audiences = {
  id: 'audience-planner',
  name: _TL_('Audiences'),
  description: _TL_('Manage and plan audience targeting.'),
  children: [
    audiencesLibraryPages,
    audiencePlanner,
  ],
};
