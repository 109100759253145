import { Scope, ScopeV2 } from '../shared';

// The actual scope for assets page is ad scope. Correcting them in scopeV2
ScopeV2.add('Ad', 'adId', ScopeV2.AdGroup);

export const assets = {
  id: 'responsive-search-ad',
  name: _TL_('Responsive search ad'),
  scope: [Scope.AdGroup],
  scopeV2: [ScopeV2.AdGroup, ScopeV2.Ad],
  children: [
    {
      id: 'assets',
      name: _TL_('Assets'),
      route: 'assets',
      searchable: false,
      scope: [Scope.AdGroup],
      scopeV2: [ScopeV2.AdGroup, ScopeV2.Ad],
    }, {
      id: 'combinations',
      name: _TL_('Combinations'),
      route: 'combinations',
      searchable: false,
      scope: [Scope.AdGroup],
      scopeV2: [ScopeV2.AdGroup, ScopeV2.Ad],
    },
  ],
};
