import { Scope } from '../../shared';

export const businessLocationFeed = [
  {
    id: 'business-location-feed',
    name: _TL_('Business location feed'),
    route: 'businesslocationfeed',
    checkPermission: permissions => permissions.dynamic.GoogleMyBusinessImport ||
        permissions.dynamic.LocalInventoryAd,
  },
];

export const businessLocationFeedPages = {
  id: 'business-location-feed',
  name: _TL_('Business location feed'),
  oldUIScope: [Scope.Account],
  children: businessLocationFeed,
};
