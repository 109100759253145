import { alwaysDisplayed } from '../shared';

export const smartCampaignPages = [{
  id: 'smart-campaigns',
  name: _TL_('Smart Campaigns'),
  campaignTypes: alwaysDisplayed,
  children: [
    {
      id: 'smart-campaigns',
      name: _TL_('Smart Campaigns'),
      route: '',
      campaignTypes: alwaysDisplayed,
    },
  ],
}];
