import React from 'react';
import { ScopeContext } from './scope-context';

export function withScope(Component) {
  return props => (
    <ScopeContext.Consumer>
      {({ scope }) => (
        <Component {...props} scope={scope} />
      )}
    </ScopeContext.Consumer>
  );
}
