import { Scope, ScopeV2 } from '../../shared';

export const labelsPage = {
  id: 'labels',
  name: _TL_('Labels'),
  scope: permissions => (permissions.IsAccountLabelsEnabled ?
    [Scope.Account, Scope.Customer] : [Scope.Account]),
  scopeV2: permissions => (permissions.IsAccountLabelsEnabled ?
    [ScopeV2.Account, ScopeV2.Customer] : [ScopeV2.Account]),
  children: [
    {
      id: 'labels',
      name: _TL_('Labels'),
      route: 'labels',
      checkPermission: permissions => !permissions.IsAccountLabelsEnabled,
      scope: [Scope.Account],
    }, {
      id: 'labels',
      name: _TL_('Labels'),
      route: 'labels',
      checkPermission: permissions => permissions.IsAccountLabelsEnabled,
      scope: [Scope.Customer, Scope.Account],
    },
    {
      id: 'labels-report',
      name: _TL_('Labels report'),
      scope: permissions => (permissions.IsAccountLabelsEnabled ?
        [Scope.Account, Scope.Customer] : [Scope.Account]),
      scopeV2: permissions => (permissions.IsAccountLabelsEnabled ?
        [ScopeV2.Account, ScopeV2.Customer] : [ScopeV2.Account]),
      children: [
        {
          id: 'labels-campaigns',
          name: _TL_('Campaigns'),
          route: 'labelscampaign',
          ariaDescription: _TL_('Labels report by campaigns'),
          scope: [Scope.Account],
        },
        {
          id: 'labels-ad-groups',
          name: _TL_('Ad groups'),
          route: 'labelsadgroup',
          ariaDescription: _TL_('Labels report by ad groups'),
          scope: [Scope.Account],
        },
        {
          id: 'labels-keywords',
          name: _TL_('Keywords'),
          route: 'labelskeyword',
          ariaDescription: _TL_('Labels report by keywords'),
          scope: [Scope.Account],
        },
        {
          id: 'labels-ads',
          name: _TL_('Ads'),
          route: 'labelsad',
          ariaDescription: _TL_('Labels report by ads'),
          scope: [Scope.Account],
        },
        {
          id: 'labels-account',
          name: _TL_('Accounts'),
          route: 'labelsaccount',
          ariaDescription: _TL_('Labels report by accounts'),
          checkPermission: permissions => permissions.IsAccountLabelsEnabled,
          scope: [Scope.Customer],
        },
      ],
    },
  ],
};
