import { Scope, alwaysDisplayed } from '../shared';

export const accountsUnlinking = {
  id: 'accounts-unlinking',
  name: _TL_('Account unlinking'),
  scope: [Scope.Customer],
  campaignTypes: alwaysDisplayed,
  children: [
    {
      id: 'accounts-unlinking',
      name: _TL_('Bulk unlink accounts'),
      route: 'accounts/bulkunlinkaccounts',
      campaignTypes: alwaysDisplayed,
      scope: [Scope.Customer],
    },
  ],
};
