import React, { useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { getErrorFromRawFn } from '@bingads-webui-react/error-mapper';
import { I18nContext } from '@bingads-webui-react/i18n-context';
import { removeErrorsFromContext, ignoreErrorCode } from '../helpers/server-error-utils';

/*
  Server error entity context
*/
export const ServerErrorEntityContext = React.createContext();
export const ServerErrorEntity = React.memo((props) => {
  const { entity, children } = props;
  return (
    <ServerErrorEntityContext.Provider value={entity}>
      {children}
    </ServerErrorEntityContext.Provider>
  );
});

ServerErrorEntity.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  entity: PropTypes.string.isRequired,
};

/*
  Server error context
*/
export const ServerErrorContext = React.createContext({});
export const ServerErrorGroup = React.memo((props) => {
  const {
    children,
    rawErrorToMessageMapper,
    instrumentation,
  } = props;
  const [errors, setErrors] = useState({});
  const i18n = useContext(I18nContext);
  const mapErrors = rawErrorToMessageMapper || getErrorFromRawFn(i18n, instrumentation);

  // server error code is not unique for now, so we temporarily depend on server error messages
  // when MT fixed this error code issue, we should not use server error message any more
  const setServerError = useCallback(({
    error, entity = '', field, useServerErrorMessage = false, ignore,
  }) => {
    setErrors(e => ({
      ...e,
      [entity]: ignoreErrorCode(mapErrors(error, entity, field, useServerErrorMessage), ignore),
    }));
  }, [mapErrors]);

  const removeErrors = useCallback(({
    entity = '',
    fieldSelector = () => true,
    clientId = () => true,
    code = () => true,
  }) => {
    setErrors(e => removeErrorsFromContext(e, entity, fieldSelector, clientId, code));
  }, []);

  return (
    <ServerErrorContext.Provider value={{
      errors,
      setServerError,
      removeErrors,
    }}
    >
      {children}
    </ServerErrorContext.Provider>
  );
});


ServerErrorGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  rawErrorToMessageMapper: PropTypes.func,
  instrumentation: PropTypes.shape({ logError: PropTypes.func }),
};

ServerErrorGroup.defaultProps = {
  rawErrorToMessageMapper: null,
  instrumentation: null,
};

