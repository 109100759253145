import { Scope } from '../../shared';

export const liftMeasurement = {
  id: 'lift-measurement',
  name: _TL_('Lift measurement'),
  scope: [Scope.Account],
  checkPermission: permissions => !!permissions.IsInternalUser && permissions.dynamic.LiftMeasurement,
  children: [
    {
      id: 'lift-measurement-landing-page',
      name: _TL_('Lift measurement'),
      scope: [Scope.Account],
      children: [
        {
          id: 'lift-measurement-landing-page',
          name: _TL_('Lift measurement'),
          route: 'liftmeasurement',
          scope: [Scope.Account],
          searchable: true,
        },
        {
          id: 'lift-measurement-creation',
          name: _TL_('Create lift measurement'),
          route: 'liftmeasurement/create',
          scope: [Scope.Account],
          searchable: false,
        },
        {
          id: 'lift-measurement-edit',
          name: _TL_('Lift measurement settings'),
          route: 'liftmeasurement/edit',
          scope: [Scope.Account],
          searchable: false,
        },
        {
          id: 'lift-measurement-results',
          name: _TL_('Lift measurement results'),
          route: 'liftmeasurement/results',
          scope: [Scope.Account],
          searchable: false,
        },
      ],
    },
  ],
};
