import { Scope } from '../shared';

export const sdkCampaignOverview = {
  id: 'sdk-campaign-overview',
  scope: [Scope.Campaign],
  available: permissions => permissions.dynamic.WebUIVnext,
  children: [
    {
      id: 'sdk-campaign-overview',
      name: _TL_('Campaign Overview'),
      route: 'sdkcampaignoverview',
    },
  ],
};

export const sdkCampaignSettings = {
  id: 'sdk-campaign-settings',
  scope: [Scope.Campaign],
  available: permissions => permissions.dynamic.WebUIVnext,
  children: [
    {
      id: 'sdk-budget-settings',
      name: _TL_('Edit budget'),
      route: 'sdkbudgetsettings',
    },
    {
      id: 'sdk-target-settings',
      name: _TL_('Edit targets'),
      route: 'sdktargetsettings',
    },
    {
      id: 'sdk-assets-settings',
      name: _TL_('Edit assets'),
      route: 'sdkassetssettings',
    },
  ],
};

export const sdkCampaignCreation = {
  id: 'sdk-campaign-creation',
  name: _TL_('Create campaign'),
  scope: [Scope.Account, Scope.Campaign],
  available: permissions => permissions.dynamic.WebUIVnext,
  route: 'sdkcampaigncreation',
};
