import _ from 'underscore';
import React from 'react';

export function AdapterFactory({
  convertEventToValue = _.identity,
  defaultValue = '',
  valueToInject = 'value',
  functionToInject = 'onChange',
}) {
  return class DefaultAdapter {
    constructor(PrimitiveComponent) {
      this.component = PrimitiveComponent;
    }

    getValue(props) {
      return props[valueToInject];
    }

    onChange = (eventObject, ...args) => {
      const value = convertEventToValue(eventObject, args);
      this.onChangeParam(value);
      if (_.isFunction(this.props.onChange)) {
        this.props.onChange(eventObject, args);
      }
    };

    renderComponent(props, value, onChange) {
      const PrimitiveComponent = this.component;
      this.onChangeParam = onChange;
      this.props = props;
      const injectedProps = {
        [valueToInject]: value || defaultValue,
        [functionToInject]: this.onChange,
      };

      // When the value is not set, we set it to '',
      // as we always want the input to be controlled
      return (<PrimitiveComponent
        {...props}
        {...injectedProps}
      />);
    }
  };
}
