import { Scope } from '../../../shared';

export const stores = {
  id: 'mmc-stores',
  name: _TL_('Merchant center'),
  scope: [Scope.Account],
  children: [
    {
      id: 'mmc-stores',
      name: _TL_('Stores'),
      route: 'merchantcenter/stores',
      scope: [Scope.Account],
      exact: false,
    },
    {
      id: 'mmc-create-store',
      name: _TL_('Create store'),
      route: 'merchantcenter/create-store',
      scope: [Scope.Account],
      searchable: false,
    },
    {
      id: 'mmc_placeholder_stores',
      name: _TL_('Placeholder Stores'),
      route: 'merchantcenter/placeholderStores',
      scope: [Scope.Account],
      exact: false,
    },
  ],
};
