import { Scope, ScopeV2, CampaignType } from '../shared';

ScopeV2.add('AssetGroup', 'assetGroupId', ScopeV2.Campaign);

export const appAdsAssetGroups = {
  id: 'appads-asset-group',
  name: _TL_('Asset groups'),
  scope: [Scope.Campaign],
  scopeV2: [ScopeV2.AssetGroup],
  campaignTypes: [CampaignType.App],
  children: [
    {
      id: 'appads-asset-group',
      name: _TL_('Asset groups'),
      route: 'appadsassetgroups',
      searchable: false,
      checkPermission: permissions => permissions.dynamic.AppAdsCampaignPhaseOne,
    },
  ],
};


export const appAdsAssetGroupsCreation = {
  id: 'appads-asset-group-create',
  name: _TL_('Create asset group'),
  scope: [Scope.Campaign],
  scopeV2: [ScopeV2.Campaign],
  campaignTypes: [CampaignType.App],
  children: [
    {
      id: 'appads-asset-group-create',
      name: _TL_('Create asset group'),
      route: 'appadsassetgroups/create',
      checkPermission: permissions => permissions.dynamic.AppAdsCampaignPhaseOne,
    },
  ],
};

export const appAdsAssetGroupsEdit = {
  id: 'appads-asset-group-edit',
  name: _TL_('Edit asset group'),
  scope: [Scope.Campaign],
  scopeV2: [ScopeV2.AssetGroup],
  campaignTypes: [CampaignType.App],
  children: [
    {
      id: 'appads-asset-group-edit',
      name: _TL_('Edit asset group'),
      route: 'appadsassetgroups/edit',
      checkPermission: permissions => permissions.dynamic.AppAdsCampaignPhaseOne,
    },
  ],
};
