import { Scope } from '../../shared';

export const conversionGoals = {
  id: 'conversion-goals',
  name: _TL_('Conversion goals'),
  oldUIScope: [Scope.Account],
  children: [{
    id: 'conversion-goals',
    name: _TL_('Conversion goals'),
    route: 'conversiongoals',
  }, {
    id: 'conversion-value-rules',
    name: _TL_('Conversion value rules'),
    route: 'conversionvaluerules',
    checkPermission: permissions => permissions.dynamic.ConvValueRules,
  }],
};
