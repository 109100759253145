import { defaultUpItemProperties, isUnifiedProductEnabled, isSmartPageStandalone } from '../../../shared';

export const upClarity = {
  id: 'up-clarity',
  name: _TL_('Microsoft Clarity'),
  children: [
    {
      ...defaultUpItemProperties,
      id: 'up-clarity',
      name: _TL_('Microsoft Clarity'),
      externalLink: 'https://clarity.microsoft.com',
      isUnifiedProductPage: true,
      checkPermission: p => isUnifiedProductEnabled(p) && !isSmartPageStandalone(p)
                            && !p.dynamic.SCv2 && p.IsESCClarityEnabled,
    },
  ],
};
