import _ from 'underscore';
import React from 'react';
import { ScopeContext } from '../common/scope-context';
import { DefaultParamProvider } from '../common/default-param-provider';

class Updater extends React.PureComponent {
  static getDerivedStateFromProps({ scope, defaultScope }) {
    return {
      scope,
      defaultScope,
    };
  }

  state = {
    scope: {},
    defaultScope: {},
  };

  componentDidMount() {
    this.updateScope();
  }

  componentDidUpdate() {
    this.updateScope();
  }

  updateScope() {
    const { scope, defaultScope } = this.state;

    if (_.find(defaultScope, (val, key) => !_.has(scope.values, key))) {
      scope.set(_.defaults(scope.values, defaultScope));
      scope.save();
    }
  }

  render() {
    return null;
  }
}

export const ScopeUpdater = ({ defaults }) => (
  <ScopeContext.Consumer>
    { ({ scope }) => (<Updater scope={scope} defaultScope={defaults} />) }
  </ScopeContext.Consumer>
);

ScopeUpdater.propTypes = {
  defaults: DefaultParamProvider.propTypes.defaults,
};

ScopeUpdater.defaultProps = {
  defaults: DefaultParamProvider.defaultProps.defaults,
};
