import { Scope, defaultCCItemProperties, CampaignType } from '../../shared';

export const settings = {
  id: 'my-settings',
  name: _TL_('Preferences'),
  oldUIScope: [Scope.Account],
  campaignTypes: [CampaignType.None],
  children: [
    {
      ...defaultCCItemProperties,
      id: 'my-settings',
      name: _TL_('Preferences'),
      route: 'Settings',
      checkPermission: permission => permission.IsManagementDashboardEnabled,
      campaignTypes: [CampaignType.None],
    },
  ],
};
