import { Scope } from '../../shared';

export const negativeKeyword = {
  id: 'negative-keyword-lists-library',
  name: _TL_('Negative keyword lists'),
  route: 'negativekeywordlistslibrary',
  scope: [Scope.Account],
};

export const negativeKeywordListsLibraryPage = {
  id: 'negative-keyword-lists-library',
  name: _TL_('Negative keyword lists'),
  oldUIScope: [Scope.Account],
  children: [
    negativeKeyword,
  ],
};
