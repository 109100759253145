import { available, scope } from './common';
import { billingAccountLinkPermissions } from '../../client-center/billing-payments';

export const billingAndBudget = {
  id: 'reports-v4-category-billingandbudget',
  name: _TL_('Billing and budget'),
  title: _TL_('See how you are spending your budget, and your most recent billing statement.'),
  fixedRoute: 'reports-v4/category/billingandbudget',
  route: 'reports-v4/category/billingandbudget',
  scope,
  available,
  children: [
    {
      id: 'reports-v4-budgetreport',
      name: _TL_('Budget'),
      title: _TL_('See how you’ve spent your budget over a specific date range.'),
      fixedRoute: 'reports-v4/create/budgetreport',
      route: 'reports-v4/create/budgetreport',
      searchAlias: _TL_('Budget report'),
      scope,
      available,
      apiKey: 'BudgetSummaryReport',
      description: _TL_('To help control and manage your advertising spend for a particular month.'),
      accountLinkPermissions: billingAccountLinkPermissions,
    }, {
      id: 'reports-v4-customerinvoicereport',
      name: _TL_('Manager account invoice billing'),
      title: _TL_('See your detailed billing history.'),
      fixedRoute: 'reports-v4/create/customerinvoicereport',
      route: 'reports-v4/create/customerinvoicereport',
      searchAlias: _TL_('Manager account invoice billing report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'CustomerInvoiceReport',
      description: _TL_('See your detailed billing history.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.IsInternalUser,
    }, {
      id: 'reports-v4-billingstatementreport',
      name: _TL_('Billing statement'),
      title: _TL_('See your most recent billing statement.'),
      fixedRoute: 'reports-v4/create/billingstatementreport',
      route: 'reports-v4/create/billingstatementreport',
      searchAlias: _TL_('Billing statement report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'BillingStatementReport',
      description: _TL_('To view an overall summary of your billing information.'),
      accountLinkPermissions: billingAccountLinkPermissions,
    }, {
      id: 'reports-v4-insertionorderreport',
      name: _TL_('Insertion order (IO)'),
      title: _TL_('A summary of your IO expenditures over a specified timeframe.'),
      fixedRoute: 'reports-v4/create/insertionorderreport',
      route: 'reports-v4/create/insertionorderreport',
      searchAlias: _TL_('Insertion order report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'InsertionOrderReport',
      description: _TL_('To view your budget changes over time and your IO expenditures for reconciliation purposes.'),
      accountLinkPermissions: billingAccountLinkPermissions,
      checkPermission: /* istanbul ignore next */ permissions => !!permissions.dynamic.InsertionOrderReport,
    },
  ],
};
