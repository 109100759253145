import { available, scope } from './common';

export const hotel = {
  id: 'reports-v4-category-hotel',
  name: _TL_('Hotel'),
  title: _TL_('Hotel'),
  fixedRoute: 'reports-v4/category/hotel',
  route: 'reports-v4/category/hotel',
  scope,
  available,
  checkPermission: /* istanbul ignore next */ permissions =>
    (permissions.dynamic.HotelCampaign && !permissions.dynamic.IsRenameHotelToLodgingEnabled),
  children: [
    {
      id: 'reports-v4-hoteldimensionreport',
      name: _TL_('Hotel dimension'),
      title: _TL_('Hotel dimension'),
      fixedRoute: 'reports-v4/create/hoteldimensionreport',
      route: 'reports-v4/create/hoteldimensionreport',
      searchAlias: _TL_('Hotel dimension report'),
      scope,
      available,
      apiKey: 'HotelDimensionPerformanceReport',
      description: _TL_('To figure out which of your hotels are triggering ads and getting most clicks and optimize the ones not performing so well.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.HotelCampaign,
    },
    {
      id: 'reports-v4-hotelgroupreport',
      name: _TL_('Hotel group'),
      title: _TL_('Hotel group'),
      fixedRoute: 'reports-v4/create/hotelgroupperformancereport',
      route: 'reports-v4/create/hotelgroupperformancereport',
      searchAlias: _TL_('Hotel group report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'HotelGroupPerformanceReport',
      description: _TL_('To figure out which of your hotels are triggering ads and getting most clicks and optimize the ones not performing so well.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.HotelCampaign,
    },
    {
      id: 'reports-v4-matchingreport',
      name: _TL_('Matching'),
      title: _TL_('Matching'),
      fixedRoute: 'reports-v4/create/matchingreport',
      route: 'reports-v4/create/matchingreport',
      searchAlias: _TL_('Matching report'),
      scope,
      scopeFilterLevel: ['Account', 'Campaign'],
      available,
      apiKey: 'UIMatchingReport',
      description: _TL_('To see issues which may be preventing your hotels from serving. '),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.HotelCampaign,
    },
    {
      id: 'reports-v4-pricefeedstatusreport',
      name: _TL_('Price feed status'),
      title: _TL_('Price feed status'),
      fixedRoute: 'reports-v4/create/pricefeedstatusreport',
      route: 'reports-v4/create/pricefeedstatusreport',
      searchAlias: _TL_('Price feed status report'),
      scope,
      scopeFilterLevel: ['Account', 'Campaign'],
      available,
      apiKey: 'UIPriceFeedStatusReport',
      description: _TL_('To track the status of your pricing updates (transactions).'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.HotelCampaign,
    },
  ],
};

export const property = {
  id: 'reports-v4-category-property',
  name: _TL_('Property'),
  title: _TL_('Property'),
  fixedRoute: 'reports-v4/category/property',
  route: 'reports-v4/category/property',
  scope,
  available,
  checkPermission: /* istanbul ignore next */ permissions =>
    (permissions.dynamic.HotelCampaign && permissions.dynamic.IsRenameHotelToLodgingEnabled),
  children: [
    {
      id: 'reports-v4-propertydimensionreport',
      name: _TL_('Property dimension'),
      title: _TL_('Property dimension'),
      fixedRoute: 'reports-v4/create/propertydimensionreport',
      route: 'reports-v4/create/propertydimensionreport',
      searchAlias: _TL_('Property dimension report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'HotelDimensionPerformanceReport',
      description: _TL_('To figure out which of your properties are triggering ads and getting most clicks and optimize the ones not performing so well.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        (permissions.dynamic.HotelCampaign && permissions.dynamic.IsRenameHotelToLodgingEnabled),
    },
    {
      id: 'reports-v4-propertygroupreport',
      name: _TL_('Property group'),
      title: _TL_('Property group'),
      fixedRoute: 'reports-v4/create/propertygroupperformancereport',
      route: 'reports-v4/create/propertygroupperformancereport',
      searchAlias: _TL_('Property group report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'HotelGroupPerformanceReport',
      description: _TL_('To figure out which of your properties are triggering ads and getting most clicks and optimize the ones not performing so well.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        (permissions.dynamic.HotelCampaign && permissions.dynamic.IsRenameHotelToLodgingEnabled),
    },
    {
      id: 'reports-v4-matchingreport',
      name: _TL_('Matching'),
      title: _TL_('Matching'),
      fixedRoute: 'reports-v4/create/matchingreport',
      route: 'reports-v4/create/matchingreport',
      searchAlias: _TL_('Matching report'),
      scope,
      apexViewName: 'reports-v4_create',
      scopeFilterLevel: ['Account', 'Campaign'],
      available,
      apiKey: 'UIMatchingReport',
      description: _TL_('To see issues which may be preventing your hotels from serving. '),
      checkPermission: /* istanbul ignore next */ permissions =>
        (permissions.dynamic.HotelCampaign && permissions.dynamic.IsRenameHotelToLodgingEnabled),
    },
    {
      id: 'reports-v4-pricefeedstatusreport',
      name: _TL_('Price feed status'),
      title: _TL_('Price feed status'),
      fixedRoute: 'reports-v4/create/pricefeedstatusreport',
      route: 'reports-v4/create/pricefeedstatusreport',
      searchAlias: _TL_('Price feed status report'),
      scope,
      apexViewName: 'reports-v4_create',
      scopeFilterLevel: ['Account', 'Campaign'],
      available,
      apiKey: 'UIPriceFeedStatusReport',
      description: _TL_('To track the status of your pricing updates (transactions).'),
      checkPermission: /* istanbul ignore next */ permissions =>
        (permissions.dynamic.HotelCampaign && permissions.dynamic.IsRenameHotelToLodgingEnabled),
    },
  ],
};
