import { Scope } from '../shared';

export const homeRoot = {
  id: 'home-root',
  route: 'home',
  default: true,
  scope: [Scope.Account],
  metadata: {
    title: _TL_('Home'),
  },
};
