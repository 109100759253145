import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { callWithDebounce } from '../helpers/utilities';

export function withErrorMessage(Component) {
  return class ErrorWrapper extends React.Component {
    static propTypes = {
      validationProps: PropTypes.shape({
        onValidate: PropTypes.func,
        alwaysShowError: PropTypes.bool,
        showErrorOnPassiveMessages: PropTypes.bool,
        passiveMessages: PropTypes.arrayOf(PropTypes.string),
        showSingleError: PropTypes.bool,
        errorFilter: PropTypes.func,
        debounce: PropTypes.number,
        customErrorRendering: PropTypes.bool,
      }),
    };

    static defaultProps = {
      validationProps: {
        onValidate: _.noop,
        alwaysShowError: false,
        showErrorOnPassiveMessages: false,
        passiveMessages: [],
        showSingleError: false,
        errorFilter: undefined,
        debounce: undefined,
        customErrorRendering: false,
      },
    };

    state = {
      messages: [],
      isDirty: false,
    };

    onValidate = (item) => {
      if (_.isFunction(this.props.validationProps.onValidate)) {
        this.props.validationProps.onValidate(item);
      }
      this.setErrorStateWithDebounce({
        messages: item.messages,
        isDirty: item.isDirty,
      });
    };

    setErrorState = (error) => {
      this.setState(error);
    };

    setErrorStateWithDebounce = callWithDebounce(this.setErrorState, this.props.validationProps.debounce);

    getErrorMessages = (showSingleError, errorFilter) => {
      const errorMessages = _.isFunction(errorFilter) ?
        [..._.filter(this.state.messages, errorFilter), ..._.result(this.props.validationProps, 'passiveMessages', [])] :
        [...this.state.messages, ..._.result(this.props.validationProps, 'passiveMessages', [])];
      return showSingleError ? errorMessages.slice(0, 1) : errorMessages;
    };

    render() {
      const {
        passiveMessages, showErrorOnPassiveMessages, alwaysShowError, showSingleError, errorFilter,
      } = this.props.validationProps;

      const shouldRenderError = alwaysShowError ||
        this.state.isDirty ||
        !_.isEmpty(passiveMessages);

      const messages = shouldRenderError ? this.getErrorMessages(showSingleError, errorFilter) : [];
      const validationProps = _.defaults({
        onValidate: this.onValidate,
        forceErrorClassName: showErrorOnPassiveMessages ? !_.isEmpty(passiveMessages) : undefined,
        messages,
      }, this.props.validationProps);

      return (
        <Component
          {...this.props}
          validationProps={validationProps}
        />
      );
    }
  };
}
