import { Scope } from '../../shared';

export const seasonalityAdjustment = {
  id: 'seasonality-adjustment',
  name: _TL_('Seasonality adjustment'),
  route: 'seasonalityadjustment',
  checkPermission: permissions =>
    permissions.dynamic.SeasonalityAdjustment
        // `false`, because DataExclusion feature is already GAed, this page has been moved to another place
        && false,
};

export const seasonalityAdjustmentPage = {
  id: 'seasonality-adjustment',
  name: _TL_('Seasonality adjustment'),
  oldUIScope: [Scope.Account],
  children: [
    seasonalityAdjustment,
  ],
};

