import {
  importSchedule,
  googleImport,
  facebookImport,
  amazonImport,
  pinterestImport,
  fileImport,
} from '../configs/import/imports';
import { importCenter } from '../configs/import/import-center';

export const imports = {
  id: 'import',
  name: _TL_('Import'),
  description: _TL_('Import your campaigns and data from Google Ads, Facebook Ads, and/or Pinterest Ads.'),
  scope: importSchedule.scope,
  children: [
    importSchedule,
    googleImport,
    facebookImport,
    amazonImport,
    pinterestImport,
    fileImport,
    importCenter,
  ],
};
