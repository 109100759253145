import { Scope, isSmartPageStandalone } from '../shared';

export const budgetCenterCampaignBudgetTool = {
  id: 'budget-center-campaign-budget-tool',
  name: _TL_('Campaign budget tool'),
  scope: [Scope.Customer],
  children: [
    {
      id: 'budget-center-campaign-budget-tool',
      name: _TL_('Campaign budget tool'),
      route: 'campaignbudgettool',
      scope: [Scope.Customer],
      searchable: true,
      checkPermission: permissions => !isSmartPageStandalone(permissions) && !permissions.IsUnifiedSmartMode && !permissions.IsSmartMode,
    },
  ],
};
