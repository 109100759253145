import { Scope, ScopeV2 } from '../../../shared';

export const storeSummary = {
  id: 'mmc-store-summary',
  name: _TL_('Merchant center'),
  scope: [Scope.Store],
  scopeV2: [ScopeV2.Store],
  children: [
    {
      id: 'mmc-store-summary',
      name: _TL_('Summary'),
      searchAlias: _TL_('Store summary'),
      route: 'merchantcenter/store-summary',
      scope: [Scope.Store],
      scopeV2: [ScopeV2.Store],
      searchable: false,
      children: [
        {
          id: 'mmc-store-summary-productissues',
          name: _TL_('Product issues'),
          route: 'merchantcenter/store-summary/productissues',
          scope: [Scope.Store],
          scopeV2: [ScopeV2.Store],
          searchable: false,
        },
        {
          id: 'mmc-store-summary-feedissues',
          name: _TL_('Feed issues'),
          route: 'merchantcenter/store-summary/feedissues',
          scope: [Scope.Store],
          scopeV2: [ScopeV2.Store],
          searchable: false,
        },
        {
          id: 'mmc-store-summary-storeissues',
          name: _TL_('Store issues'),
          route: 'merchantcenter/store-summary/storeissues',
          scope: [Scope.Store],
          scopeV2: [ScopeV2.Store],
          searchable: false,
        },
        {
          id: 'mmc-store-summary-productperf',
          name: _TL_('Product performance'),
          route: 'merchantcenter/store-summary/productperf',
          scope: [Scope.Store],
          scopeV2: [ScopeV2.Store],
          searchable: false,
        },
        {
          id: 'mmc-store-summary-diagnostics',
          name: _TL_('Diagnostics'),
          route: 'merchantcenter/store-summary/diagnostics',
          scope: [Scope.Store],
          scopeV2: [ScopeV2.Store],
          searchable: false,
          checkPermission: permissions => permissions.dynamic.MMCDynamicTab,
        },
      ],
    },
    {
      id: 'mmc-store-summary-productissuedetails',
      name: _TL_('Product issue details'),
      searchAlias: _TL_('Product issue details'),
      route: 'merchantcenter/store-summary/productissuedetails',
      scope: [Scope.Store],
      scopeV2: [ScopeV2.Store],
      searchable: false,
    },
    {
      id: 'mmc-store-summary-policyviolationdetails',
      name: _TL_('Policy violation details'),
      searchAlias: _TL_('Policy violation details'),
      route: 'merchantcenter/store-summary/policyviolationdetails',
      scope: [Scope.Store],
      scopeV2: [ScopeV2.Store],
      searchable: false,
    },
    {
      id: 'mmc-store-summary-reviewrejections',
      name: _TL_('Review rejections'),
      searchAlias: _TL_('Review rejections'),
      route: 'merchantcenter/store-summary/reviewrejections',
      scope: [Scope.Store],
      scopeV2: [ScopeV2.Store],
      searchable: false,
    },
  ],
};
