export const negativeKeywordsListsDetail = {
  id: 'negative-keywords-lists-detail',
  name: _TL_('Negative Keywords Lists Detail'),
  children: [
    {
      id: 'negative-keywords-lists-negative-keywords',
      name: _TL_('Negative Keywords'),
      route: 'negativekeywordslistsdetails/negativekeywords',
    },
    {
      id: 'negative-keywords-lists-details-campaigns',
      name: _TL_('Campaigns in this list'),
      route: 'negativekeywordslistsdetails/campaignassociation',
    },
  ],
};
