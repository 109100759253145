import { Scope, alwaysDisplayed } from '../shared';

export const merchanteCenterStoreCreate = {
  id: 'mmc-create-store',
  name: _TL_('Create store'),
  scope: [Scope.Account],
  campaignTypes: alwaysDisplayed,
  children: [
    {
      id: 'mmc-create-store',
      name: _TL_('Create store'),
      route: 'merchantcenter/create-store',
      scope: [Scope.Account],
    },
  ],
};

