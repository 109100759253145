import { keywordPlanner } from '../configs/tools/planning';
import { negativeKeyword } from '../configs/tools/shared-library/negative-keyword-lists-library';

export const keywords = {
  id: 'keywords-planning',
  name: _TL_('Keywords'),
  description: _TL_('Manage keyword and negative keyword lists.'),
  children: [
    keywordPlanner,
    negativeKeyword,
  ],
};
