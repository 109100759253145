import { allScopes, alwaysDisplayed } from '../../shared';

export const shared = {
  id: 'reports-v4-my-reports-shared',
  name: _TL_('Reports shared with me'),
  title: _TL_('View reports that shared with me'),
  route: 'reports-v4/my-reports/shared',
  scope: allScopes,
  campaignTypes: alwaysDisplayed,
};
