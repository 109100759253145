import { CampaignType, AdGroupType, Scope } from '../shared';

const campaignTypes = [CampaignType.Default, CampaignType.Shopping, CampaignType.Audience,
  CampaignType.DynamicSearchAds, CampaignType.Smart, CampaignType.Hotel];
const adGroupTypes = [AdGroupType.SearchStandard, AdGroupType.SearchDynamic];

export const adSchedule = {
  id: 'ad-schedule',
  name: _TL_('Ad schedule'),
  campaignTypes,
  adGroupTypes,
  smartShoppingCampaignScope: [Scope.Account, Scope.Campaign],
  hotelCampaignScope: [Scope.Account, Scope.Campaign],
  children: [
    {
      id: 'ad-schedule',
      name: _TL_('Ad schedule'),
      children: [
        {
          id: 'ad-schedule',
          name: _TL_('Ad schedule'),
          route: 'adschedule',
        },
        {
          id: 'ad-schedule-campaign',
          name: _TL_('Ad schedule'),
          route: 'adschedule/campaign',
          searchable: false,
        },
        {
          id: 'ad-schedule-adgroup',
          name: _TL_('Ad schedule'),
          route: 'adschedule/adgroup',
          searchable: false,
        },
      ],
    }, {
      id: 'adschedule-day',
      name: _TL_('Day'),
      route: 'adscheduleday',
    }, {
      id: 'adschedule-hour',
      name: _TL_('Hour'),
      route: 'adschedulehour',
    }, {
      id: 'adschedule-date',
      name: _TL_('Date'),
      route: 'adscheduledate',
    }, {
      id: 'adschedule-week',
      name: _TL_('Week'),
      route: 'adscheduleweek',
    }, {
      id: 'adschedule-month',
      name: _TL_('Month'),
      route: 'adschedulemonth',
    }, {
      id: 'adschedule-quarter',
      name: _TL_('Quarter'),
      route: 'adschedulequarter',
    }, {
      id: 'adschedule-year',
      name: _TL_('Year'),
      route: 'adscheduleyear',
    },
  ],
};
