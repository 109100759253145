export const adgroupCreate = {
  id: 'adgroup-create',
  name: _TL_('New ad group'),
  children: [
    {
      id: 'adgroup-create',
      name: _TL_('Create an ad group'),
      route: 'createadgroup',
    },
  ],
};
