import _ from 'underscore';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

const RibbonDropdownItem = ({
  classes,
  editor,
  itemName,
  displayName,
  onClick,
  i18n,
}) => {
  const handleOnClick = useCallback(() => {
    editor.stopShadowEdit();
    onClick(editor, itemName);
  }, [onClick, editor, itemName]);

  const handleOnMouseOver = useCallback(() => {
    editor.startShadowEdit();
    onClick(editor, itemName);
  }, [onClick, editor, itemName]);

  return (
    <div
      className={classes.dropdownItem}
      tabIndex="0"
      role="button"
      key={itemName}
      onClick={handleOnClick}
      onKeyDown={() => { }}
      onMouseOver={handleOnMouseOver}
      onFocus={handleOnMouseOver}
    >
      {i18n.getString(displayName)}
    </div>
  );
};

export const RibbonDropdown = ({
  classes,
  editor,
  button,
  onHideDropdown,
  i18n,
}) => (
  <div className={classes.dropdown}>
    {
      Object.keys(button.dropdownItems).map(key =>
      (
        button.dropdownRenderer ?
          <div key={key}>
            {
              button.dropdownRenderer(
                editor,
                i18n,
                onHideDropdown,
                key,
                button.dropdownItems[key]
              )
            }
          </div>
          :
          <RibbonDropdownItem
            i18n={i18n}
            key={key}
            classes={classes}
            editor={editor}
            itemName={key}
            displayName={button.dropdownItems[key]}
            onClick={button.onClick}
          />
      ))
    }
  </div>
);

RibbonDropdownItem.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  editor: PropTypes.shape({}).isRequired,
  itemName: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  i18n: PropTypes.shape({
    getString: PropTypes.func,
  }).isRequired,
};

RibbonDropdownItem.defaultProps = {
  onClick: _.noop,
};

RibbonDropdown.propTypes = {
  i18n: PropTypes.shape({
    getString: PropTypes.func,
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
  editor: PropTypes.shape({}).isRequired,
  button: PropTypes.shape({}).isRequired,
  onHideDropdown: PropTypes.func.isRequired,
};
