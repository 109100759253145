import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { DummyNode } from '../validation-node/dummy-node';
import { withValidationInternal } from './with-validation';

export function withCustomValidation(Component, methodName = 'updateValidation') {
  class ValidationWrapper extends React.PureComponent {
    static propTypes = {
      validationProps: PropTypes.shape({
        onValidate: PropTypes.func,
      }),
    };

    static defaultProps = {
      validationProps: {
        onValidate: _.noop,
      },
    };

    constructor(props) {
      super(props);
      this.itemNode = new DummyNode({ parent: _.result(props.validationProps, 'parent'), initialNodeValue: _.result(props.validationProps, 'initialValue') });
    }

    componentDidMount() {
      this.itemNode.register();
    }

    componentWillUnmount() {
      this.itemNode.unregister();
      _.result(this.props.validationProps, 'notifyDataChange');
    }

    updateValidation = (messages, data = null, shouldDirty = true) => {
      if (!_.isEqual(messages, this.itemNode.accessor.messages) ||
        (data !== null && !_.isEqual(data, this.itemNode.accessor.data))) {
        this.itemNode.trigger(messages, data, shouldDirty);
        if (_.isFunction(this.props.validationProps.onValidate)) {
          this.props.validationProps.onValidate(this.itemNode.accessor);
        }
      }
    };

    triggerValidate = () => {
      if (_.isFunction(this.props.validationProps.onValidate)) {
        this.props.validationProps.onValidate(this.itemNode.accessor);
      }
    };

    injectedProps = { [methodName]: this.updateValidation };

    render() {
      return (<Component {...this.injectedProps} {...this.props} />);
    }
  }

  return withValidationInternal(ValidationWrapper);
}
