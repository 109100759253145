import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { ScopeContext } from './scope-context';
import { QueryParam } from './query-param';

const isScopeChanged = (search, scope) => !scope.isEqual(search);

const getNewState = (
  defaults,
  location,
  history,
  allowedKeys,
  paramFormatter,
  saveScope
) => {
  const scope = new QueryParam(location, history, saveScope, paramFormatter, allowedKeys);
  _.defaults(scope.values, defaults);

  return {
    scope,
    saveScope,
  };
};

export class DefaultParamProvider extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      defaults,
      location,
      history,
      allowedKeys,
      paramFormatter,
    } = props;

    this.state = getNewState(
      defaults,
      location,
      history,
      allowedKeys,
      paramFormatter,
      this.saveScope
    );
  }

  static getDerivedStateFromProps(props, state) {
    const {
      defaults,
      location,
      history,
      allowedKeys,
      paramFormatter,
      updateOnPathChange,
    } = props;

    const scopeChanged = isScopeChanged(location.search, state.scope);
    const pathChanged = location.pathname !== state.scope.location.pathname;

    if (scopeChanged || (updateOnPathChange && pathChanged)) {
      return getNewState(
        defaults,
        location,
        history,
        allowedKeys,
        paramFormatter,
        state.saveScope
      );
    }
    return null;
  }

  saveScope = (scope, pathname = this.props.location.pathname) => {
    const {
      defaults,
      location,
      history,
      allowedKeys,
      paramFormatter,
      updateOnPathChange,
    } = this.props;

    const newLocation = {
      pathname,
      search: scope.stringify(),
    };

    const scopeChanged = isScopeChanged(location.search, scope);
    const pathChanged = pathname !== location.pathname;

    if (scopeChanged || pathChanged) {
      history.push(newLocation);

      const newState = getNewState(
        defaults,
        newLocation,
        history,
        allowedKeys,
        paramFormatter,
        this.saveScope
      );

      const scopeChangeAccepted = newState.scope.isEqual(history.location.search);
      const pathChangeAccepted = newState.scope.location.pathname === history.location.pathname;

      if ((scopeChanged && scopeChangeAccepted) || (pathChanged && pathChangeAccepted && updateOnPathChange)) {
        this.setState(newState);
        // if changes were needed return `true` to signal that the request could be processed
        return true;
      }

      // if changes were needed return `false` to signal that the request could NOT be processed, e.g. due to navigation rejection
      return false;
    }

    // if no changes were needed return `true` to signal that the request could be processed
    return true;
  };

  render() {
    return (
      <ScopeContext.Provider value={{ scope: this.state.scope }}>
        {this.props.children}
      </ScopeContext.Provider>
    );
  }
}

DefaultParamProvider.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
  }),
  defaults: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  children: PropTypes.node.isRequired,
  allowedKeys: PropTypes.arrayOf(PropTypes.string),
  paramFormatter: PropTypes.func,
  updateOnPathChange: PropTypes.bool,
};

DefaultParamProvider.defaultProps = {
  location: undefined,
  history: undefined,
  defaults: {},
  allowedKeys: [],
  paramFormatter: _.identity,
  updateOnPathChange: true,
};
