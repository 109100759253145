import { Scope, alwaysDisplayed } from '../shared';

export const accountLinking = {
  id: 'account-linking',
  name: _TL_('Account linking'),
  scope: [Scope.Customer],
  campaignTypes: alwaysDisplayed,
  children: [
    {
      id: 'account-linking',
      name: _TL_('Link to an existing account'),
      route: 'accounts/sendlinkrequest',
      campaignTypes: alwaysDisplayed,
      scope: [Scope.Customer],
    },
    {
      id: 'account-linking-resend',
      name: _TL_('Link to an existing account'),
      route: 'accounts/resendlinkrequest',
      campaignTypes: alwaysDisplayed,
      scope: [Scope.Customer],
    },
  ],
};
