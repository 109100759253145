import { siteExclusionListsAccountLevelViewListsPage } from '../configs/tools/shared-library/site-exclusion-lists-account-level-view-lists';
import { siteExclusionListsCustomerLevelCreateListsPage } from '../configs/tools/shared-library/site-exclusion-lists-customer-level-create-lists';
import { alwaysDisplayedItem, buildTree, Scope, defaultScopes } from '../configs/shared';

const websiteExclusion = {
  id: 'website-exclusion',
  name: _TL_('Website exclusions'),
  description: _TL_('Keep ads from showing on specific websites using account level website exclusion lists.'),
  scope: [Scope.Customer, ...defaultScopes],
  checkPermission: permissions => !permissions.dynamic.BrandSafety,
  children: [
    siteExclusionListsAccountLevelViewListsPage,
    siteExclusionListsCustomerLevelCreateListsPage,
  ],
};

const tree = buildTree([websiteExclusion], alwaysDisplayedItem, true, undefined, true)[0];

export { tree as websiteExclusion };
