import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { withScope, QueryParam } from '@bingads-webui-react/scope-service';
import { withPermissions } from '@bingads-webui-react/hoc-with-permissions';
import { memoize } from '@bingads-webui-universal/primitive-utilities';
import { getCurrentScope } from './processor-helpers';
import { filterMapItem } from './filter-map-item';
import { siteMapPropType } from '../site-map-prop-type';

const processSitemapWithScope = (siteMap, scope, permissions) => {
  const scopeFilter = item => (_.isFunction(item.scope)
    ? _.contains(item.scope(permissions), scope)
    : _.contains(item.scope, scope));
  return filterMapItem(siteMap, scopeFilter);
};

const processSitemapWithScopeCached = memoize(processSitemapWithScope);

export function processScope(Component, scope = null, useCache = false, skipRoot = false) {
  const Wrapper = ({ siteMap, ...props }) => {
    const {
      scope: {
        values: {
          aid,
          campaignId,
          adGroupId,
          storeId,
          feedId,
        },
      },
      permissions,
    } = props;
    const currentScope = getCurrentScope(campaignId, adGroupId, aid, storeId, feedId);
    const permissionsWithDynamic = _.defaults(permissions, { dynamic: {} });
    const scopeToCheck = scope === null ? currentScope : scope;

    const processorFunc = useCache ? processSitemapWithScopeCached : processSitemapWithScope;
    let processedSiteMap;

    if (skipRoot) {
      processedSiteMap = _.map(siteMap, item =>
        (item.processChildScope ? ({ ...item, children: processorFunc(item.children, scopeToCheck, permissionsWithDynamic) })
          : item));
    } else {
      processedSiteMap = processorFunc(siteMap, scopeToCheck, permissionsWithDynamic);
    }

    return (<Component {...props} siteMap={processedSiteMap} />);
  };

  Wrapper.propTypes = {
    siteMap: siteMapPropType.isRequired,
    scope: PropTypes.instanceOf(QueryParam).isRequired,
    permissions: PropTypes.objectOf(PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.objectOf(PropTypes.bool),
      PropTypes.number,
    ])).isRequired,
  };

  return withPermissions(withScope(Wrapper));
}
