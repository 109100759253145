import _ from 'underscore';
import { BaseNode } from './base-node';
import { merge } from './viewmodel-util';

const defaultValidator = (changed, node) =>
  _.reduce(node.items, (messages, item) => messages.concat(item.messages), []);

const defaultConvertor = {
  fromJSON: (json, node) => {
    _.each(node.items, (item) => {
      item.fromJSON(json);
    });
  },
  toJSON: node =>
    _.reduce(node.items, (result, item) => {
      merge(result, item.toJSON());
      return result;
    }, {}),
};

export class GroupNode extends BaseNode {
  constructor(params) {
    super(_.defaults(params, {
      validator: defaultValidator,
      convertor: defaultConvertor,
    }));
    this.items = [];
  }

  notifyChildChange(field) {
    this.validate(field);
    this.parent.notifyChildChange(this);
  }

  registerChild(newItem) {
    this.items.push(newItem);
    this.notifyChildChange(newItem);
  }

  unregisterChild(item) {
    this.items = _.without(this.items, item);
    this.notifyChildChange(item);
  }
}
