import { Scope } from '../../shared';

export const modeledConversions = {
  id: 'modeled-conversions',
  name: _TL_('Conversion diagnostics​'),
  oldUIScope: [Scope.Account],
  scope: [Scope.Account],
  children: [
    {
      id: 'modeled-conversions',
      name: _TL_('Conversion diagnostics'),
      route: 'conversionsdiagnostic',
      scope: [Scope.Account],
      checkPermission: permissions => permissions.dynamic.AllowModeledConversions,
    },
  ],
};
