import _ from 'underscore';
import { PluginEventType, GetContentMode } from 'roosterjs';

// This will be inserted when click empty format for empty content
const ZERO_WIDTH_SPACE = '\u200B';

export class ContentStatePlugin {
  constructor(options) {
    this.options = options;
  }

  initialize(editor) {
    this.editor = editor;
  }

  dispose() {
    this.editor = null;
  }

  registerObs = (obs) => {
    this.stateObserver = obs;
  };

  onPluginEvent(event) {
    if (this.stateObserver) {
      // Paste or change by key press
      if (
        event.eventType === PluginEventType.Input ||
        event.eventType === PluginEventType.ContentChanged ||
        event.eventType === PluginEventType.KeyUp ||
        event.eventType === PluginEventType.KeyDown ||
        event.eventType === PluginEventType.CompositionEnd ||
        event.eventType === PluginEventType.EditorReady
      ) {
        // We don't trigger bubble event to consumer when it is in init phase
        const shouldBubble = !(event.eventType === PluginEventType.EditorReady);

        const rawContent = this.editor.getContent(GetContentMode.PlainText);
        // Empty content still wrap with tags or special empty char
        if (_.isEmpty(rawContent) || rawContent === ZERO_WIDTH_SPACE) {
          this.stateObserver('', shouldBubble);
        } else {
          if (this.options.pasteAsText) {
            const imgNodes = this.editor.queryElements('img');
            if (!_.isEmpty(imgNodes)) {
              imgNodes.forEach(node => this.editor.deleteNode(node));
            }
          }
          this.stateObserver(this.editor.getContent(), shouldBubble);
        }
      }
    }
  }
}
