/* eslint-disable max-len */
import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { ItemNode } from '../validation-node/item-node';
import { GroupNode } from '../validation-node/group-node';
import { unpack } from '../validation-node/viewmodel-util';
import { createSchemaField } from '../validation-node/field-creator';
import { withDefaultI18n, i18nPropType } from './with-default-i18n';

const getFieldValue = (convertor, field, data) => {
  const fieldData = unpack(data, field);

  return _.isUndefined(fieldData) ? fieldData : convertor.fromModel(fieldData);
};

// get the string value processed by i18n, may be differnt from original string (e.g. rounding)
const getProcessedValue = (value, convertor) => convertor.fromModel(convertor.toModel(value));

const getNewState = (props, state = {}) => {
  const {
    schema, i18n, field, data, primitiveAdapter, convertor = {},
  } = props.validationProps;
  const fieldConvertor = _.defaults(convertor, createSchemaField(schema, i18n, field).conversion);
  const fieldValue = getFieldValue(fieldConvertor, field, data);

  if (!_.isUndefined(fieldValue) && fieldValue !== state.source) {
    // field prop change detected, update state
    return {
      value: getProcessedValue(state.value, fieldConvertor) === fieldValue ?
        state.value : fieldValue,
      source: fieldValue,
    };
  }

  const propValue = primitiveAdapter.getValue(props);
  const processed = _.isUndefined(propValue) ?
    propValue : getProcessedValue(propValue, fieldConvertor);

  if (!_.isUndefined(propValue) && processed !== state.source) {
    // value prop change detected, update state
    return {
      value: propValue,
      source: processed,
    };
  }

  return null;
};

class ValidationItemInternal extends React.Component {
  static propTypes = {
    validationProps: PropTypes.shape({
      onValidate: PropTypes.func,
      i18n: i18nPropType.isRequired,
      parent: PropTypes.instanceOf(GroupNode),
      schema: PropTypes.shape({
        type: PropTypes.string,
      }),
      field: PropTypes.string,
      validator: PropTypes.func,
      convertor: PropTypes.shape({
        fromJSON: PropTypes.func,
        toJSON: PropTypes.func,
      }),
      errorProcessor: PropTypes.func,
      primitiveAdapter: PropTypes.shape({
        getValue: PropTypes.func,
        renderComponent: PropTypes.func,
      }).isRequired,
      errorClassName: PropTypes.string,
      data: PropTypes.object, // eslint-disable-line
      skipValidation: PropTypes.bool,
    }),
    className: PropTypes.string,
  };

  static defaultProps = {
    validationProps: {
      onValidate: _.noop,
      parent: undefined,
      schema: undefined,
      field: undefined,
      validator: undefined,
      convertor: undefined,
      errorProcessor: undefined,
      skipValidation: false,
    },
    className: undefined,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return getNewState(nextProps, prevState);
  }

  constructor(props) {
    super(props);

    this.itemNode = new ItemNode(props.validationProps);

    this.state = getNewState(props) || { value: this.itemNode.default };
  }

  componentDidMount() {
    this.itemNode.register();
    this.triggerValidate();
  }

  componentDidUpdate(prevProps, prevState) {
    const didSkipValidationChanged = this.props.validationProps.skipValidation !== prevProps.validationProps.skipValidation;
    if (!_.isEqual(prevState.value, this.state.value) || didSkipValidationChanged) {
      if (didSkipValidationChanged) {
        this.itemNode.setSkipValidation(this.props.validationProps.skipValidation);
      }
      this.triggerValidate();
    }
  }

  componentWillUnmount() {
    this.itemNode.unregister();
    _.result(this.props.validationProps, 'notifyDataChange');
  }

  setText = (text) => {
    this.setState({
      value: text,
    });
    this.itemNode.setDirty();
  };

  getClassName() {
    const { className, validationProps } = this.props;
    const { errorClassName, forceErrorClassName } = _.defaults(validationProps, { errorClassName: 'error' });
    const isItemNodeInvalid = !this.itemNode.isValid && this.itemNode.isDirty;
    if (!_.isEmpty(errorClassName) && (isItemNodeInvalid || forceErrorClassName)) {
      return _.isEmpty(className) ? errorClassName : `${className} ${errorClassName}`;
    }
    return className;
  }

  triggerValidate() {
    if (_.isFunction(this.props.validationProps.onValidate)) {
      this.props.validationProps.onValidate(this.itemNode.accessor);
    }
  }

  render() {
    const { validationProps: { primitiveAdapter } } = this.props;
    const componentProps = _.omit(this.props, 'validationProps');

    this.itemNode.value = this.state.value;
    _.extend(componentProps, { className: this.getClassName() });
    return primitiveAdapter.renderComponent(componentProps, this.state.value, this.setText);
  }
}

export const ValidationItem = withDefaultI18n(ValidationItemInternal);
