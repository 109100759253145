import { Scope, ScopeV2, alwaysDisplayed } from '../shared';

ScopeV2.add('Budget', 'budgetId', ScopeV2.Account);

export const sharedBudgetsEditPage = {
  id: 'shared-budgets-edit',
  name: _TL_('Edit shared budget'),
  scopeV2: [ScopeV2.Account, ScopeV2.Budget],
  oldUIScope: [Scope.Account],
  campaignTypes: alwaysDisplayed,
  children: [
    {
      id: 'shared-budgets-edit',
      name: _TL_('Edit shared budget'),
      scopeV2: [ScopeV2.Account, ScopeV2.Budget],
      route: 'sharedbudgets/edit',
      campaignTypes: alwaysDisplayed,
    },
  ],
};
