import { Scope } from '../../shared';

export const performanceTargets = {
  id: 'performance-targets',
  name: _TL_('Performance Targets'),
  route: 'performancetargets',
  scope: [Scope.Account],
};

export const performanceTargetsPage = {
  id: 'performance-targets',
  name: _TL_('Performance targets'),
  oldUIScope: [Scope.Account],
  children: [
    performanceTargets,
  ],
};
