import { Scope } from '../shared';

export const XandrAccountSettings = {
  id: 'XandrAccountSettingsStandalone',
  name: _TL_('Xandr Account Settings'),
  children: [
    {
      id: 'XandrAccountSettings',
      name: _TL_('Xandr Account Settings'),
      route: 'Xandr/AccountSettings',
      scope: [Scope.Account],
      checkPermission: permissions => permissions.dynamic.XandrAccountSettings,
    },
  ],
};
