import { Scope } from '../shared';

export const queryInsights = {
  id: 'query-insights-details',
  name: _TL_('Search insights details'),
  children: [
    {
      id: 'account-optimization-details',
      name: _TL_('Account optimization details'),
      route: 'accountoptimization/details',
      searchable: false,
      checkPermission: permissions => permissions.IsQueryInsightsEnabled,
      scope: [Scope.Account],
    },
    {
      id: 'new-opportunities-details',
      name: _TL_('New opportunities details'),
      route: 'newopportunities/details',
      searchable: false,
      checkPermission: permissions => permissions.IsQueryInsightsEnabled,
      scope: [Scope.Account],
    },
  ],
};
