import {
  defaultReportsPage,
  myReportsPage,
  editReportPage,
  // buildReportsPage,
} from '../configs/reports-v4';
import { allScopes, alwaysDisplayed, alwaysDisplayedItem, buildTree } from '../configs/shared';


const reports = {
  id: 'reports-v4',
  name: _TL_('Reporting'),
  description: _TL_('View performance data with instant reports or by creating custom reports.'),
  route: 'reports-v4',
  fixedRoute: 'reports-v4',
  scope: allScopes,
  campaignTypes: alwaysDisplayed,
  children: [
    defaultReportsPage,
    myReportsPage,
    {
      ...editReportPage,
      searchable: false,
    },
    // buildReportsPage,
  ],
};

const tree = buildTree([reports], alwaysDisplayedItem, true, undefined, true)[0];

export { tree as reports };
