import { Scope, isSmartPageStandalone } from '../../shared';

export const budgetCenter = {
  id: 'budget-center',
  name: _TL_('Budget center'),
  scope: [Scope.Account, Scope.Campaign],
  children: [
    {
      id: 'budget-center',
      name: _TL_('Budget center'),
      route: 'budgetcenter',
      scope: [Scope.Account, Scope.Campaign],
      showTag: {
        text: _TL_('New'),
        expDate: new Date('2023/12/31'),
      },
      checkPermission: permissions => !isSmartPageStandalone(permissions) && !permissions.IsUnifiedSmartMode && !permissions.IsSmartMode,
    },
  ],
};
