import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

export function verifyRouter(Component) {
  const ComponentWithRouter = withRouter(Component);
  const RouterWrapper = (props) => {
    const { location, history } = props;
    if (_.isEmpty(location) || _.isEmpty(history)) {
      return (<ComponentWithRouter {...props} />);
    }

    return (<Component {...props} />);
  };

  RouterWrapper.propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
  };

  RouterWrapper.defaultProps = {
    location: undefined,
    history: undefined,
  };

  return RouterWrapper;
}
