import { ScopeV2 } from '../shared';

export const dynamicDataFeedsItems = {
  id: 'dynamic-data-feeds-items',
  name: _TL_('Dynamic data feed items'),
  scopeV2: [ScopeV2.Feed],
  children: [
    {
      id: 'dynamic-data-feeds-items',
      name: _TL_('Dynamic data feed items'),
      scopeV2: [ScopeV2.Feed],
      route: 'feeds/dynamicdataitems',
      checkPermission: permissions => permissions.dynamic.DynamicData
        || permissions.IsEnabledForDynamicFeedCampaign,
    },
    {
      id: 'schedules',
      name: _TL_('Schedules'),
      scopeV2: [ScopeV2.Feed],
      route: 'feeds/dynamicdataitems/schedules',
      checkPermission: permissions => permissions.dynamic.DynamicData
        || permissions.IsEnabledForDynamicFeedCampaign,
    },
    {
      id: 'upload-history',
      name: _TL_('Upload history'),
      scopeV2: [ScopeV2.Feed],
      route: 'feeds/dynamicdataitems/uploadhistory',
      checkPermission: permissions => permissions.dynamic.DynamicData
        || permissions.IsEnabledForDynamicFeedCampaign,
    },
  ],
};
