import { Scope, alwaysDisplayed } from '../shared';

export const pendingTransferRequest = {
  id: 'pending-transfer-request',
  name: _TL_('View pending transfer request'),
  scope: [Scope.Customer],
  campaignTypes: alwaysDisplayed,
  children: [
    {
      id: 'pending-transfer-request',
      name: _TL_('View pending transfer request'),
      route: 'accounts/pendingtransferrequest',
      campaignTypes: alwaysDisplayed,
      scope: [Scope.Customer],
    },
  ],
};
