import { defaultUpItemProperties, isUnifiedProductEnabled, isSmartPageStandalone } from '../../../shared';

const upTermsAndConditionLink = 'https://about.ads.microsoft.com/en-us/resources/policies/microsoft-advertising-agreement';

export const upTermCondition = {
  id: 'up-term-condition',
  name: _TL_('Terms and conditions'),
  children: [
    {
      ...defaultUpItemProperties,
      id: 'up-term-condition',
      name: _TL_('Terms and conditions'),
      externalLink: upTermsAndConditionLink,
      checkPermission: p => isUnifiedProductEnabled(p) || isSmartPageStandalone(p),
    },
  ],
};
