import _ from 'underscore';
import { CampaignType, AdGroupType } from '../shared';

export const dynamicAdTargets = {
  id: 'dsa',
  name: _TL_('Dynamic ad targets'),
  campaignTypes: (campaignType) => {
    const allowedCampaignTypes = [CampaignType.Default];

    const result = _.some(allowedCampaignTypes, type => (type === campaignType) ||
      (type & campaignType)); // eslint-disable-line no-bitwise
    return result;
  },
  adGroupTypes: [AdGroupType.SearchDynamic],
  currentCampaign: (campaign, permissions) => {
    const isDSAMixedModeCampaignEnabled = permissions.IsDSAMixedModeCampaignEnabled;
    if (!isDSAMixedModeCampaignEnabled) {
      return true;
    }

    return _.isEmpty(campaign) ||
      // if current campaign is search campaign with DSA settings added,
      // the dynamic ad targets pages should be available.
      (_.result(CampaignType, campaign.CampaignType) === CampaignType.Default &&
      _.some(campaign.CampaignSettings, CampaignSettings => _.has(CampaignSettings, 'DomainName')) && permissions.IsDSAMixedModeCampaignEnabled);
  },
  mixedModeAdGroup: (adgroup, _item, permissions) => {
    if (!permissions.IsDSAMixedModeCampaignEnabled) {
      return true;
    }

    if (_.isEmpty(adgroup)) {
      return true;
    }

    return adgroup.AdGroupType === 'SearchDynamic';
  },
  checkPermission: permissions => permissions.dynamic.DynamicSearchAdsV1 || permissions.IsDynamicSearchAdsV1Enabled,
  children: [
    {
      id: 'dsa',
      name: _TL_('Dynamic ad targets'),
      route: 'dynamicadtargets/targets',
    }, {
      id: 'negative-dsa',
      name: _TL_('Negative dynamic ad targets'),
      children: [
        {
          id: 'negative-dsa-campaign',
          name: _TL_('Negative dynamic ad targets'),
          route: 'dynamicadtargets/negative/campaign',
        }, {
          id: 'negative-dsa-adgroup',
          name: _TL_(''), // global search duplicate names
          route: 'dynamicadtargets/negative/adgroup',
        },
      ],
    }, {
      id: 'dsa-search-terms',
      name: _TL_('Search terms'),
      route: 'dynamicadtargets/searchterms',
    },
  ],
};
