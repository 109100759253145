import _ from 'underscore';
import * as Configs from './configs/index';
import { processPermissions } from './processors/permission-processor';
import { processScope } from './processors/scope-processor';
import { processScopeV2 } from './processors/scope-processor-v2';
import { processPath } from './processors/path-processor';
import { processCustomMapper } from './processors/custom-mapper-processor';
import { processRootFilter } from './processors/root-filter-processor';
import { processCampaignUIContext } from './processors/campaign-ui-context-processor';
import { processCcuiContext } from './processors/ccui-context-processor';
import { processAccountLinkPermission } from './processors/account-link-permission-processor';
import { createSiteMapWrapper, defaultPropName } from './site-map-wrapper';
import { navRefreshSiteMap } from './configs-v2';
import { processSharedLibrary } from './processors/shared-library-processor';

const {
  accountsSiteMap, campaignSiteMap, toolsSiteMap, flatToolsSiteMap, importSiteMap,
  reportsSiteMap, reportsV4SiteMap, hotelCenterSiteMap, hotelCenterV2SiteMap, standaloneSiteMap, flatSetupSiteMap,
  allSiteMap, allClientCenterSiteMap, bidStrategiesSiteMap,
  clientCenterSiteMap, clientCenterStandAloneSiteMap,
  smartCampaignsSiteMap, unifiedSmartSiteMap, adInsightSiteMap, flatSmartToolsSiteMap,
  flatSmartImportSiteMap,
} = Configs;

const processPermissionsCached = _.partial(processPermissions, _, true);
const processScopeCached = _.partial(processScope, _, _, true);
const processScopeSkipRootCached = _.partial(processScope, _, _, true, true);
const processPathCached = _.partial(processPath, _, true);
const processAccountLinkPermissionCached = _.partial(processAccountLinkPermission, _, true);

export const processor = {
  processPermissions: processPermissionsCached,
  processPermissionsCached,
  processScope: processScopeCached,
  processScopeCached,
  processPath: processPathCached,
  processCampaignUIContext,
  processPathCached,
  processCustomMapper,
  processScopeSkipRoot: processScopeSkipRootCached,
  processRootFilter,
  processScopeV2,
  processScopeV2Cached: _.partial(processScopeV2, _, _, true),
  processCcuiContext,
  processAccountLinkPermission,
  processAccountLinkPermissionCached,
  processSharedLibrary,
};

export const processorSet = {
  basic: [processPermissionsCached, processAccountLinkPermissionCached, processPathCached],
  default: [processPermissionsCached, processAccountLinkPermissionCached, processScopeCached, processPathCached],
  all: [processPermissionsCached, processAccountLinkPermissionCached, processScopeCached, processPathCached, processCustomMapper],
  navRefresh: [processPermissionsCached, processAccountLinkPermissionCached, processScopeSkipRootCached, processSharedLibrary, processPathCached, processCustomMapper],
};

export function withSiteMap(
  Component, processors = processorSet.default,
  siteMap = allSiteMap, rootId = null, propName = defaultPropName
) {
  const composedProcessor = rootId === null ? _.compose(...processors) :
    _.compose(...processors, _.partial(processRootFilter, _, rootId));

  return createSiteMapWrapper(Component, composedProcessor, siteMap, propName);
}

export const withAccountsSiteMap = _.partial(withSiteMap, _, _, accountsSiteMap);
export const withBidStrategiesSiteMap = _.partial(withSiteMap, _, _, bidStrategiesSiteMap);
export const withCampaignSiteMap = _.partial(withSiteMap, _, _, campaignSiteMap);
export const withToolsSiteMap = _.partial(withSiteMap, _, _, toolsSiteMap);
export const withFlatToolsSiteMap = _.partial(withSiteMap, _, _, flatToolsSiteMap);
export const withFlatSmartToolsSiteMap = _.partial(withSiteMap, _, _, flatSmartToolsSiteMap);
export const withFlatSetupSiteMap = _.partial(withSiteMap, _, _, flatSetupSiteMap);
export const withImportSiteMap = _.partial(withSiteMap, _, _, importSiteMap);
export const withFlatSmartImportSiteMap = _.partial(withSiteMap, _, _, flatSmartImportSiteMap);
export const withReportsSiteMap = _.partial(withSiteMap, _, _, reportsSiteMap);
export const withReportsV4SiteMap = _.partial(withSiteMap, _, _, reportsV4SiteMap);
export const withHotelCenterSiteMap = _.partial(withSiteMap, _, _, hotelCenterSiteMap);
export const withHotelCenterV2SiteMap = _.partial(withSiteMap, _, _, hotelCenterV2SiteMap);
export const withStandaloneSiteMap = _.partial(withSiteMap, _, _, standaloneSiteMap);
export const withAllClientCenterSiteMap =
  _.partial(withSiteMap, _, processorSet.basic, allClientCenterSiteMap);
export const withClientCenterSiteMap = _.partial(withSiteMap, _, _, clientCenterSiteMap);
export const withClientCenterStandaloneSiteMap =
  _.partial(withSiteMap, _, _, clientCenterStandAloneSiteMap);
export const withSmartSiteMap =
  _.partial(withSiteMap, _, _, smartCampaignsSiteMap);
export const withUnifiedSmartSiteMap = _.partial(withSiteMap, _, _, unifiedSmartSiteMap);
export const withAdInsightSiteMap = _.partial(withSiteMap, _, _, adInsightSiteMap);
export { allSiteMap };
export { Configs };
export { navRefreshSiteMap };
