import { overview } from './overview';
import { recommendations } from './recommendations';
import { campaigns } from './campaigns';
import { adGroups } from './adgroups';
import { products } from './products';
import { adsAndExtensions } from './ads-and-extensions';
import { assets } from './assets';
import { landingPages } from './landing-pages';
import { dynamicAdTargets } from './dynamic-ad-targets';
import { feedsItems } from './feeds-items';
import { keywords } from './keywords';
import { productGroups } from './product-groups';
import { hotelGroups, propertyGroups } from './hotel-groups';
import { hotels, properties } from './hotels';
import { audiences } from './audiences';
import { demographics } from './demographics';
import { settings } from './settings';
import { locations } from './locations';
import { adSchedule } from './ad-schedule';
import { devices } from './devices';
import { changeHistory } from './change-history';
import { competition } from './competition';
import { experiments } from './experiments';
import { smartCampaigns } from './smart-campaigns';
import { queryInsights } from './query-insights';
import { assetGroups, appAdsAssetGroups } from './asset-groups';
import { listingGroups } from './listing-groups';
import { assetsReport } from './assets-report';

export const campaignPages = [
  overview,
  recommendations,
  queryInsights,
  campaigns,
  adGroups,
  assetGroups,
  appAdsAssetGroups,
  listingGroups,
  products,
  adsAndExtensions,
  assetsReport,
  assets,
  landingPages,
  keywords,
  dynamicAdTargets,
  feedsItems,
  productGroups,
  hotelGroups,
  propertyGroups,
  hotels,
  properties,
  audiences,
  demographics,
  settings,
  locations,
  adSchedule,
  devices,
  changeHistory,
  competition,
  experiments,
  smartCampaigns,
];
