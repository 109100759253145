import { Scope } from '../configs/shared';
import { performanceTargets as page } from '../configs/tools/shared-library/performance-targets';
import { performanceTargetsCreate } from '../configs/standalone/performance-targets-create';
import { performanceTargetsOverview } from '../configs/standalone/performance-targets-overview';


// those are not shown in the L2 menu. But add them to sitemap so that L1 item is highlighted
const createRoutes = performanceTargetsCreate.children.map(child => ({
  ...child,
  searchable: false,
}));

const overviewRoutes = performanceTargetsOverview.children.map(child => ({
  ...child,
  searchable: false,
}));

export const performanceTargets = {
  id: 'performance-targets',
  name: _TL_('Performance targets'),
  description: _TL_('Set targets and review performance for groups of campaigns.'),
  oldUIScope: [Scope.Account],
  children: [
    page,
    ...createRoutes,
    ...overviewRoutes,
  ],
};
