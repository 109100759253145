import { alwaysDisplayed } from '../shared';

export const smartCampaigns = {
  id: 'smart-campaigns',
  name: _TL_('Smart Campaigns'),
  campaignTypes: alwaysDisplayed,
  isSmartPage: true,
  children: [
    {
      id: 'smart-campaigns',
      name: _TL_('Smart Campaigns'),
      route: 'smartcampaigns',
      campaignTypes: alwaysDisplayed,
      isSmartPage: true,
    },
  ],
};
