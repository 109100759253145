import { buildTree } from '../configs/shared';
import { ccAlwaysDisplayedItem } from '../configs';
import {
  billingSummary,
  transactions,
  paymentMethods,
  adjustments,
  balance,
  insertionOrders,
  billingSettings,
  xandrBaf,
} from '../configs/client-center/billing-payments';
import { couponDetails, couponDetailsCustomer } from '../configs/client-center/billing-payments-standalone';

// children are dynamically generated. So we hide them from nav menus
const billingSettingsProcessed = {
  ...billingSettings,
  children: billingSettings.children.map(child => ({
    ...child,
    searchable: false,
  })),
};

const billing = {
  id: 'billing',
  name: _TL_('Billing'),
  description: _TL_('Manage your billing and payments.'),
  route: 'billing',
  fixed: true,
  tail: true,
  processChildScope: true,
  children: [
    billingSummary,
    transactions,
    paymentMethods,
    adjustments,
    xandrBaf,
    balance,
    insertionOrders,
    billingSettingsProcessed,
    couponDetails,
    couponDetailsCustomer,
  ],
};

const tree = buildTree([billing], ccAlwaysDisplayedItem, true, undefined, true)[0];

export { tree as billing };
