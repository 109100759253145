import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { QueryParam } from './query-param';
import { verifyRouter } from './verify-router';

function withQueryStringInternal(Component, propName = 'queryParams', processor = _.identity) {
  const WrappedComponent = (props) => {
    const { location, history, ...rest } = props;
    const saveScope = (scope, pathname = location.pathname) => {
      if (pathname !== location.pathname || !scope.isEqual(location.search)) {
        history.push({
          pathname,
          search: scope.stringify(),
        });
      }
    };
    const queryParam = new QueryParam(location, history, saveScope, processor);
    const queryProp = { [propName]: queryParam };

    return (<Component
      {...queryProp}
      {...rest}
    />);
  };

  WrappedComponent.propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired,
    }),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
  };

  WrappedComponent.defaultProps = {
    location: {},
    history: {},
  };

  return WrappedComponent;
}

export const withQueryString = _.compose(verifyRouter, withQueryStringInternal);
