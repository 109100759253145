import { Scope } from '../shared';

export const optOutForMinors = {
  id: 'optout-for-minors',
  name: _TL_('Opt out for minors'),
  scope: [Scope.Account],
  children: [
    {
      id: 'optout-for-minors',
      name: _TL_('Opt out for minors'),
      route: 'optoutforminors',
      scope: [Scope.Account],
      searchable: false,
    },
  ],
};
