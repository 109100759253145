import { bulkActionsPages, advertisingEditor, advertisingMobile } from '../configs/tools/bulk-actions';

export const bulkActions = {
  id: 'bulk-actions',
  name: _TL_('Bulk actions'),
  description: _TL_('Make bulk edits by using automated rules, bulk upload, and/or scripts.'),
  scope: bulkActionsPages[0].scope,
  children: bulkActionsPages,
};

export { advertisingEditor, advertisingMobile };
