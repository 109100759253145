import _ from 'underscore';
import { CampaignType, Scope, AdGroupType } from '../shared';

const adGroupTypes = [AdGroupType.SearchStandard, AdGroupType.SearchDynamic];
const shouldServeOnMSAN = campaignSettings => campaignSettings && campaignSettings[0] && campaignSettings[0].ShouldServeOnMSAN;

const demographicsChildrens = [
  {
    id: 'age',
    name: _TL_('Age'),
    route: 'demographics/age',
  }, {
    id: 'gender',
    name: _TL_('Gender'),
    route: 'demographics/gender',
  }, {
    id: 'company',
    name: _TL_('Company'),
    route: 'demographics/company',
    currentCampaign: campaign => _.isEmpty(campaign) ||
      shouldServeOnMSAN(campaign.CampaignSettings) ||
      (campaign.CampaignSubType !== 'DisplayAds' && campaign.CampaignSubType !== 'BrandAwarenessVideoAds'),
    checkPermission: permissions => !permissions.IsDomesticSimplifiedChineseEnabled,
  }, {
    id: 'industry',
    name: _TL_('Industry'),
    route: 'demographics/industry',
    currentCampaign: campaign => _.isEmpty(campaign) ||
      shouldServeOnMSAN(campaign.CampaignSettings) ||
      (campaign.CampaignSubType !== 'DisplayAds' && campaign.CampaignSubType !== 'BrandAwarenessVideoAds'),
    checkPermission: permissions => !permissions.IsDomesticSimplifiedChineseEnabled,
  }, {
    id: 'job-function',
    name: _TL_('Job function'),
    route: 'demographics/jobfunction',
    currentCampaign: campaign => _.isEmpty(campaign) ||
      shouldServeOnMSAN(campaign.CampaignSettings) ||
      (campaign.CampaignSubType !== 'DisplayAds' && campaign.CampaignSubType !== 'BrandAwarenessVideoAds'),
    checkPermission: permissions => !permissions.IsDomesticSimplifiedChineseEnabled,
  },
];

const demographicsWithLevels = _.map(demographicsChildrens, child => ({
  id: child.id,
  name: child.name,
  currentCampaign: child.currentCampaign,
  checkPermission: child.checkPermission,
  children: [
    child,
    {
      id: `${child.id}-campaign`,
      name: child.name,
      route: `${child.route}/campaign`,
      apexViewName: child.route,
      checkPermission: permissions => permissions.dynamic.LinkedInTargeting,
      searchable: false,
    }, {
      id: `${child.id}-adgroup`,
      name: child.name,
      route: `${child.route}/adgroup`,
      apexViewName: child.route,
      searchable: false,
      currentCampaign: child.currentCampaign,
    },
  ],
}));

export const demographics = {
  id: 'demographics',
  name: _TL_('Demographics'),
  smartShoppingCampaignScope: [Scope.Account],
  hotelCampaignScope: [],
  campaignTypes: [CampaignType.Default, CampaignType.Shopping, CampaignType.Audience,
    CampaignType.DynamicSearchAds, CampaignType.Smart, CampaignType.Hotel],
  adGroupTypes,
  checkPermission: permissions => permissions.dynamic.AIMCampaign ||
    permissions.dynamic.LinkedInTargeting,
  currentCampaign: (campaign, permissions) => permissions.dynamic.LinkedInTargeting ||
    _.isEmpty(campaign) || _.result(CampaignType, campaign.CampaignType) === CampaignType.Audience,
  children: demographicsWithLevels,
};
