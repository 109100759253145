import _ from 'underscore';
import { Scope } from '../shared';

export const auctionInsights = {
  id: 'accounts-auction-insights',
  name: _TL_('Auction insights'),
  scope: [Scope.Customer],
  children: [
    {
      id: 'accounts-auction-insights',
      name: _TL_('Auction insights'),
      route: 'accounts/auctioninsights',
      scope: [Scope.Customer],
      checkPermission: permissions => permissions.IsMCCAuctionInsightsEnabledInMenu && (!permissions.IsDomesticSimplifiedChineseEnabled || (permissions.IsDomesticSimplifiedChineseEnabled && _.get(permissions, ['dynamic', 'AuctionInsightForCN'], false))),
    },
  ],
};
