import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { StateStore } from '@bingads-webui/state-store';
import { withStateStore } from '@bingads-webui/with-state-store';
import { siteMapPropType } from '../site-map-prop-type';
import { filterMapItem } from './filter-map-item';

export const customMapperStore = new StateStore({});

const cloneMap = _.partial(filterMapItem, _, () => true);

export function processCustomMapper(Component) {
  const Wrapper = ({ siteMap, store, ...props }) => {
    const customProcessors = _.isEmpty(store.state) ? _.identity :
      _.compose(..._.toArray(store.state), cloneMap);
    const processedSiteMap = customProcessors(siteMap);

    return (<Component {...props} siteMap={processedSiteMap} />);
  };

  Wrapper.propTypes = {
    siteMap: siteMapPropType.isRequired,
    store: PropTypes.instanceOf(StateStore).isRequired,
  };

  return withStateStore(Wrapper, customMapperStore);
}
