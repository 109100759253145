import { isValidElementType } from 'react-is';

export const PropTypesExt = {
  component:
    (props, propName, componentName) => {
      if (props[propName] && !isValidElementType(props[propName])) {
        return new Error(`Invalid prop ${propName} supplied to ${componentName}`);
      }
      return null;
    },
};
