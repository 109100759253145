import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { withPermissions } from '@bingads-webui-react/hoc-with-permissions';
import { memoize } from '@bingads-webui-universal/primitive-utilities';
import { siteMapPropType } from '../site-map-prop-type';

const getScope = (item, permissions) =>
  (_.isFunction(item.scope) ? item.scope(permissions) : item.scope);
// todo: [rayduan] merge scope with V2 when we finish migration
const getScopeV2 = (item, permissions) =>
  (_.isFunction(item.scopeV2) ? item.scopeV2(permissions) : item.scopeV2);
const getTag = (item, permissions) =>
  (_.isFunction(item.showTag) ? item.showTag(permissions) : item.showTag);

function filterByPermissions(siteMap, permissions) {
  return _.reduce(siteMap, (result, item) => {
    if (item.checkPermission(permissions)) {
      if (!_.isEmpty(item.children)) {
        const children = filterByPermissions(item.children, permissions);
        if (!_.isEmpty(children)) {
          const route = item.route || _.result(_.first(children), 'route');
          const available = _.some(children, child => child.available);
          const showTag = getTag(item, permissions);
          const scope = getScope(item, permissions);
          const scopeV2 = getScopeV2(item, permissions);
          result.push(_.defaults({
            children, available, route, showTag, scope, scopeV2,
          }, item));
        }
      } else {
        const available = _.isFunction(item.available) ?
          item.available(permissions) === true : item.available;
        const showTag = getTag(item, permissions);
        const scope = getScope(item, permissions);
        const scopeV2 = getScopeV2(item, permissions);
        result.push(_.defaults({
          available, showTag, scope, scopeV2,
        }, item));
      }
    }
    return result;
  }, []);
}

const filterByPermissionsCached = memoize(filterByPermissions);

export function processPermissions(Component, useCache = false) {
  const Wrapper = ({ siteMap, ...props }) => {
    const permissions = _.defaults(props.permissions, { dynamic: {} });
    const processorFunc = useCache ? filterByPermissionsCached : filterByPermissions;
    const processedSiteMap = processorFunc(siteMap, permissions);

    return (<Component {...props} siteMap={processedSiteMap} />);
  };

  Wrapper.propTypes = {
    siteMap: siteMapPropType.isRequired,
    permissions: PropTypes.objectOf(PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.objectOf(PropTypes.bool),
      PropTypes.number,
    ])).isRequired,
  };

  return withPermissions(Wrapper);
}
