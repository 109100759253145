import _ from 'underscore';
import { CampaignType, Scope } from '../shared';

const campaignTypes = [CampaignType.Default, CampaignType.Shopping, CampaignType.Audience,
  CampaignType.DynamicSearchAds, CampaignType.Smart, CampaignType.Hotel];

export const locations = {
  id: 'locations',
  name: _TL_('Locations'),
  campaignTypes: type => (type !== CampaignType.App) && (type !== CampaignType.None) && (type !== CampaignType.LinkedIn),
  smartShoppingCampaignScope: [Scope.Account, Scope.Campaign],
  hotelCampaignScope: [Scope.Account, Scope.Campaign],
  children: [
    {
      id: 'targeted',
      name: _TL_('Targeted'),
      children: [
        {
          id: 'targeted',
          name: _TL_('Targeted'),
          route: 'locations',
        },
        {
          id: 'targeted-campaign',
          name: _TL_('Targeted'),
          route: 'locations/campaign',
          searchable: false,
        },
        {
          id: 'targeted-adgroup',
          name: _TL_('Targeted'),
          route: 'locations/adgroup',
          searchable: false,
        },
      ],
    }, {
      id: 'excluded',
      name: _TL_('Excluded'),
      currentCampaign: (campaign, permissions) => {
        if (_.isEmpty(campaign)) {
          return true;
        }

        if (campaign.CampaignSubType === 'BrandAwarenessVideoAds' || campaign.CampaignSubType === 'DisplayAds') {
          if (!_.result(permissions, ['dynamic', 'XandrNegativeTarget'], false)) {
            return false;
          }
        }

        return true;
      },
      children: [
        {
          id: 'excluded',
          name: _TL_('Excluded'),
          route: 'excluded',
        },
        {
          id: 'excluded-campaign',
          name: _TL_('Excluded'),
          route: 'excluded/campaign',
          searchable: false,
        },
        {
          id: 'excluded-adgroup',
          name: _TL_('Excluded'),
          route: 'excluded/adgroup',
          searchable: false,
        },
      ],
    }, {
      id: 'geographic-report',
      name: _TL_('Geographic report'),
      route: 'geographicreport',
      campaignTypes,
    }, {
      id: 'user-location-report',
      name: _TL_('User location report'),
      route: 'userlocationreport',
      campaignTypes,
    },
  ],
};
