import { Scope } from '../shared';

export const performanceTargetsCreate = {
  id: 'performance-targets-create',
  name: _TL_('Create Performance Targets'),
  oldUIScope: [Scope.Account],
  children: [
    {
      id: 'performance-targets-create',
      name: _TL_('Create Performance Targets'),
      route: 'performancetargets/create',
    },
  ],
};
