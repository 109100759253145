export const userManagement = {
  id: 'users',
  name: _TL_('User management'),
  route: 'Users',
};

export const directManager = {
  id: 'managers',
  name: _TL_('Direct managers'),
  route: 'Managers',
};

export const accountAccess = {
  id: 'accountAccess',
  name: _TL_('Account access'),
  route: 'Users',
  fixedRoute: 'Users',
  children: [
    directManager,
    userManagement,
  ],
};
