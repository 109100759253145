import { accounts } from './accounts';
import { accountsSummary } from './accounts-summary';
import { overview } from './overview';
import { recommendations } from './recommendations';
import { auctionInsights } from './auction-insights';

export const accountsPages = [
  accounts,
  overview,
  accountsSummary,
  recommendations,
  auctionInsights,
];
