import { Scope } from '../shared';

export const adsRoot = {
  id: 'ads-root',
  route: 'ads',
  default: true,
  scope: [Scope.Account],
  metadata: {
    title: _TL_('Ads'),
  },
};
