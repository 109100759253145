const ribbonButtonSharedStyle = {
  backgroundColor: 'transparent',
  '&:not([disabled])': {
    '&:hover': {
      backgroundColor: 'rgba(244, 244, 244, 1)',
    },
    '&.checked': {
      backgroundColor: 'rgba(218, 218, 218, 1)',
    },
    cursor: 'pointer',
  },
};

export const getEditorRibbonStyle = () => ({
  // Ribbon root styles
  root: {},
  // Ribbon button wrapper styles
  buttonContainer: {
    position: 'relative',
    width: '32px',
    height: '32px',
    overflow: 'visible',
  },
  // Ribbon image button styles
  imgButton: {
    ...ribbonButtonSharedStyle,
    borderWidth: 0,
    borderRadius: '4px',
    margin: '2px',
    padding: 0,
    '&:disabled': {
      filter: 'opacity(60%)',
    },
  },
  // Ribbon text only button styles
  textButton: {
    ...ribbonButtonSharedStyle,
    height: '32px',
    verticalAlign: 'top',
    borderWidth: '0',
    borderRadius: '3px',
  },
  // Ribbton button dropdown list container styles
  dropdown: {
    zIndex: 1,
    border: '1px solid white',
    backgroundColor: 'white',
    display: 'inline-block',
    padding: '4px',
    boxShadow: '1px 1px 3px gray',
    position: 'absolute',
    top: '14px',
    left: '0',
  },
  // Ribbton button dropdown item styles
  dropdownItem: {
    cursor: 'pointer',
    padding: '2px',
    whiteSpace: 'nowrap',
    minWidth: '60px',
    '&:hover': {
      backgroundColor: 'rgba(244, 244, 244, 1)',
    },
  },
});
