import _ from 'underscore';
import { ViewmodelSchema } from '@bingads-webui/viewmodel-schema';
import { unpack } from './viewmodel-util';

const emptyField = {
  default: null,
  conversion: {
    fromModel: value => value,
    toModel: value => value,
  },
  validation: {
    validate: () => [],
  },
};

export function createSchemaField(schema, i18n, field) {
  if (_.isEmpty(schema) || _.isEmpty(field)) {
    return emptyField;
  }

  const vmSchema = new ViewmodelSchema(schema, i18n, [field]);

  const vmField = unpack(vmSchema, field);

  return {
    default: vmField.default,
    conversion: vmField.conversion,
    validation: {
      validate: value => _.reduce(vmField.validation, (errors, validator, name) => {
        if (!validator.isValid(value)) {
          _.extend(errors, { [name]: validator.message });
        }
        return errors;
      }, {}),
    },
  };
}
