import { Scope } from '../shared';

export const accounts = {
  id: 'accounts',
  scope: [Scope.Customer],
  children: [
    {
      id: 'accounts',
      route: 'accounts',
      scope: [Scope.Customer],
      available: permissions => permissions.dynamic.WebUIVnext,
      searchable: false,
    },
  ],
};
