import React, { useState, useCallback, useMemo } from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import { createLink } from 'roosterjs';

import { withDefaultStyles } from '@bingads-webui-react/with-default-styles';
import { TextField } from '@fluentui/react/lib/TextField';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';

import { getInsertLinkDialogStyle } from '../../styles/insert-link-dialog-style';

const urlInputId = 'ribbon-insert-link-dialog-url-input';
const displayTextInputId = 'ribbon-insert-link-dialog-url-display-text';

const UnstyledInsertLinkDialog = ({
  classes,
  editor,
  displayText,
  url,
  onDismiss,
  i18n,
}) => {
  const [currentUrl, setCurrentUrl] = useState(url);
  const [currentDisplayText, setCurrentDisplayText] = useState(displayText);

  const onHandleDismiss = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.nativeEvent) {
      // For react 16
      e.nativeEvent.stopImmediatePropagation();
    }
    onDismiss(e);
  }, [onDismiss]);

  const onOk = useCallback((e) => {
    createLink(
      editor,
      currentUrl/* target url */,
      currentUrl /* alt text */,
      currentDisplayText /* display text */
    );
    onHandleDismiss(e);
  }, [currentDisplayText, currentUrl, editor, onHandleDismiss]);

  const dialogContentProps = useMemo(() => ({
    type: DialogType.normal,
    title: i18n.getString(_TL_('Insert an external link')),
    closeButtonAriaLabel: i18n.getString(_TL_('Close')),
  }), [i18n]);

  return (
    <Dialog
      className="ribbon-insert-link-dialog"
      hidden={false}
      onDismiss={onHandleDismiss}
      dialogContentProps={dialogContentProps}
      modalProps={{ isBlocking: true }}
    >
      <div className={classes.root}>
        <div className={classes.inputItem}>
          <label htmlFor={urlInputId} className={classes.title}>
            {i18n.getString(_TL_('Url'))}:
            <TextField
              id={urlInputId}
              defaultValue={currentUrl}
              onChange={(e, val) => setCurrentUrl(val)}
            />
          </label>
        </div>
        <div className={classes.inputItem}>
          <label htmlFor={displayTextInputId} className={classes.title}>
            {i18n.getString(_TL_('Display text'))}:
            <TextField
              id={displayTextInputId}
              defaultValue={currentDisplayText}
              onChange={(e, val) => setCurrentDisplayText(val)}
            />
          </label>
        </div>
      </div>
      <DialogFooter>
        <PrimaryButton
          className={classes.button}
          onClick={onOk}
          disabled={!currentUrl || !currentDisplayText}
          text={i18n.getString(_TL_('OK'))}
        />
        <DefaultButton
          className={classes.button}
          onClick={onHandleDismiss}
          text={i18n.getString(_TL_('Cancel'))}
        />
      </DialogFooter>
    </Dialog>
  );
};

UnstyledInsertLinkDialog.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  editor: PropTypes.shape({}).isRequired,
  displayText: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    getString: PropTypes.func,
  }).isRequired,
};

export const InsertLinkDialog = withDefaultStyles(
  UnstyledInsertLinkDialog,
  getInsertLinkDialogStyle
);

export const renderInsertLinkDialog = (editor, i18n, onDismiss = _.noop) => {
  const cursorElement = editor.getElementAtCursor();
  const selectionTraverser = editor.getSelectionTraverser();
  const selectionEle = selectionTraverser ? selectionTraverser.currentInlineElement : null;

  let anchor = null;
  let displayText = '';
  let url = '';

  if (cursorElement) {
    anchor = cursorElement.tagName === 'A' ? cursorElement : cursorElement.querySelector('a[href]');
  }

  if (anchor) {
    displayText = anchor.innerText;
    url = anchor.href;
  } else if (selectionEle) {
    displayText = selectionEle.getTextContent();
  }

  return (
    <InsertLinkDialog
      editor={editor}
      i18n={i18n}
      onDismiss={onDismiss}
      url={url}
      displayText={displayText}
    />
  );
};
