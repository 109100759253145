import { Scope, ScopeV2 } from '../shared';

export const conversionValueRulesCreate = {
  id: 'conversion-value-rules-create',
  name: _TL_('Conversion value rules create'),
  oldUIScope: [Scope.Customer],
  children: [
    {
      id: 'conversion-value-rules-create',
      name: _TL_('Conversion value rules create'),
      route: 'conversionvaluerules/create',
      checkPermission: permissions => permissions.dynamic.ConvValueRules,
    },
  ],
};

ScopeV2.add('CVRL', 'cvrlId', ScopeV2.Customer);

export const conversionValueRulesEdit = {
  id: 'conversion-value-rules-edit',
  name: _TL_('Conversion value rules edit'),
  scopeV2: [Scope.Customer, Scope.CVRL],
  oldUIScope: [Scope.Customer],
  children: [
    {
      id: 'conversion-value-rules-edit',
      name: _TL_('Conversion value rules edit'),
      route: 'conversionvaluerules/edit',
      checkPermission: permissions => permissions.dynamic.ConvValueRules,
    },
  ],
};
