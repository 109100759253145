import { Scope } from '../shared';

export const recommendationAutoApplySettings = {
  id: 'recommendation-auto-apply-settings',
  name: _TL_('Auto Apply Settings'),
  scope: [Scope.Account, Scope.Campaign, Scope.AdGroup],
  children: [{
    id: 'recommendation-auto-apply-settings',
    name: _TL_('Auto Apply Settings'),
    route: 'recommendations/AutoApplySettings',
    scope: [Scope.Account, Scope.Campaign, Scope.AdGroup],
  }],
};
