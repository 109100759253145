
import { Scope } from '../shared';

export const bidStrategiesPortfolio = {
  id: 'portfolio-bid-strategy',
  name: _TL_('Portfolio bid strategies'),
  route: 'bidstrategies/portfoliobidstrategy',
  checkPermission: permissions =>
    permissions.dynamic.PortfolioBidStrategy,
};

export const portfolioBidStrategyPage = {
  id: 'portfolio-bid-strategy',
  name: _TL_('Portfolio bid strategies'),
  oldUIScope: [Scope.Account],
  children: [
    bidStrategiesPortfolio,
  ],
};
