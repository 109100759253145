import { Scope } from '../shared';

export const overview = {
  id: 'accounts-overview',
  name: _TL_('Overview'),
  scope: [Scope.Customer],
  children: [
    {
      id: 'accounts-overview',
      name: _TL_('Overview'),
      scope: [Scope.Customer],
      children: [
        {
          id: 'accounts-overview',
          name: _TL_('Overview'),
          route: 'accounts/overview',
          scope: [Scope.Customer],
          available: true,
        },
        {
          id: 'accounts-overview-expert',
          name: _TL_('Overview'),
          route: 'accounts/overview/expert',
          scope: [Scope.Customer],
          available: permissions => permissions.dynamic.UnifiedProductPhase2,
          checkPermission: permissions => permissions.dynamic.UnifiedProductPhase2,
          searchable: false,
        },
        {
          id: 'accounts-overview-smart',
          name: _TL_('Overview'),
          route: 'accounts/overview/smart',
          scope: [Scope.Customer],
          available: permissions => permissions.dynamic.UnifiedProductPhase2,
          checkPermission: permissions => permissions.dynamic.UnifiedProductPhase2,
          searchable: false,
        },
      ],
    },
  ],
};
