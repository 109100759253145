import { Scope } from '../shared';

export const advancedControlsPage = {
  id: 'advanced-controls',
  name: _TL_('Advanced controls'),
  oldUIScope: [Scope.Account],
  children: [
    {
      id: 'seasonality-adjustment',
      name: _TL_('Seasonality adjustments'),
      route: 'bidstrategies/seasonalityadjustment',
      checkPermission: permissions =>
        permissions.dynamic.SeasonalityAdjustment,
    },
    {
      id: 'data-exclusions',
      name: _TL_('Data exclusions'),
      route: 'bidstrategies/dataexclusions',
    },
  ],
};
