import { Scope } from '../configs/shared';
import { advancedControlsPage } from '../configs/bid-strategies/advanced-controls';
import { bidStrategiesPortfolio } from '../configs/bid-strategies/portfolio-bid-strategy';
import { sharedBudgetsPage } from '../configs/tools/shared-library/shared-budgets';
import { budgetCenter } from '../configs/tools/setup/budget-center';
import { portfolioBid } from '../configs/tools/shared-library/portfolio-bid-strategy';
import { seasonalityAdjustment } from '../configs/tools/shared-library/seasonality-adjustment';

export const budgetAndBid = {
  id: 'budget-and-bid',
  name: _TL_('Bids & budgets'),
  description: _TL_('Optimize your campaigns with bids and budgets.'),
  oldUIScope: [Scope.Account],
  children: [
    sharedBudgetsPage,
    budgetCenter,
    advancedControlsPage,
    bidStrategiesPortfolio,
    portfolioBid,
    seasonalityAdjustment,
  ],
};
