import { uetTags } from './uet-tags';
import { conversionGoals } from './conversion-goals';
import { offlineConversions } from './offline-conversions';
import { clarityWebsite } from './clarity-website';
import { modeledConversions } from './modeled-conversions';

export { offlineConversions, uetTags, conversionGoals, };

export const conversionTracking = [
  uetTags,
  conversionGoals,
  offlineConversions,
  clarityWebsite,
  modeledConversions,
];
