import { Scope, alwaysDisplayed } from '../shared';

export const accountCreate = {
  id: 'account-create',
  name: _TL_('New account'),
  scope: [Scope.Customer],
  campaignTypes: alwaysDisplayed,
  children: [
    {
      id: 'account-create',
      name: _TL_('Create a new account'),
      route: 'accounts/create',
      campaignTypes: alwaysDisplayed,
      scope: [Scope.Customer],
    },
  ],
};
