import { Scope, ScopeV2 } from '../shared';

export const conversionGoalsCreate = {
  id: 'conversion-goals-create',
  name: _TL_('Conversion goals create'),
  oldUIScope: [Scope.Account],
  children: [
    {
      id: 'conversion-goals-create',
      name: _TL_('Conversion goals create'),
      route: 'conversiongoals/create',
    },
  ],
};

ScopeV2.add('EnterFlow', 'inflow', ScopeV2.Account);

export const conversionGoalsAutoCreate = {
  id: 'conversion-goals-autocreate',
  name: _TL_('Conversion goals auto create'),
  scopeV2: [ScopeV2.Account, ScopeV2.EnterFlow],
  oldUIScope: [Scope.Account],
  children: [
    {
      id: 'conversion-goals-auto-create',
      name: _TL_('Conversion auto goals create'),
      route: 'conversiongoals/autocreate',
      checkPermission: permissions => permissions.dynamic.AllowAutoGoalWizard,
      searchable: false,
    },
  ],
};

ScopeV2.add('Goal', 'goalId', ScopeV2.Account);

export const conversionGoalsEdit = {
  id: 'conversion-goals-edit',
  name: _TL_('Conversion goals edit'),
  scopeV2: [ScopeV2.Account, ScopeV2.Goal],
  oldUIScope: [Scope.Account],
  children: [
    {
      id: 'conversion-goals-edit',
      name: _TL_('Conversion goals edit'),
      route: 'conversiongoals/edit',
    },
  ],
};
