import { PluginEventType } from 'roosterjs';

export class ContentFilterPlugin {
  initialize(editor) {
    this.editor = editor;
  }

  dispose() {
    this.editor = null;
  }

  getEditor() {
    return this.editor;
  }

  onPluginEvent(event) {
    if (event.eventType === PluginEventType.BeforeCutCopy) {
      const { clonedRoot } = event;
      // Remove default white color for wrapped div
      clonedRoot.style.backgroundColor = 'unset';
    } else if (event.eventType === PluginEventType.BeforePaste) {
      const { fragment } = event;

      const elements = fragment.querySelectorAll('a');
      // eslint-disable-next-line no-param-reassign
      elements.forEach((ele) => { ele.target = '_blank'; });
    }
  }
}
