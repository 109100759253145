import _ from 'underscore';
import queryString from 'query-string';
import { cloneDeep } from '@bingads-webui-universal/primitive-utilities';

export const $current = '$current';

function filterByKeys(params, allowedKeys) {
  return _.isEmpty(allowedKeys) ? params : _.pick(params, allowedKeys);
}

export class QueryParam {
  static get Empty() {
    return new QueryParam({}, { saveScope: _.noop });
  }

  constructor(location, history, saveScope, processor = _.identity, allowedKeys = []) {
    this.processor = processor;
    this.saveScope = saveScope;
    this.allowedKeys = allowedKeys;
    this.location = location;
    this.history = history;
    this.parse();
    this.saveState();
  }

  append = (params) => {
    const allowedParams = filterByKeys(params, this.allowedKeys);

    this.values = _.defaults(_.omit(allowedParams, val => val === $current), this.values);
  }

  remove = (keys) => {
    const removeKeys = _.isArray(keys) ? keys : [keys];
    const filteredKeys = _.isEmpty(this.allowedKeys) ?
      keys : _.intersection(_.flatten(removeKeys), this.allowedKeys);

    this.values = _.omit(this.values, filteredKeys);
  }

  set = (params) => {
    this.values = this.getMerged(params);
  }

  getMerged = (params) => {
    const allowedParams = filterByKeys(params, this.allowedKeys);
    const newParams = _.mapObject(allowedParams, (val, key) => (val === $current ?
      _.result(this.values, key, '') : val));
    const unmanagedParams = _.isEmpty(this.allowedKeys) ?
      {} : _.omit(this.values, this.allowedKeys);

    return _.extend(unmanagedParams, newParams);
  }

  parse = (query = this.location.search) => {
    this.values = this.processor(queryString.parse(query));
  }

  stringify = (values = this.values) => queryString.stringify(values, { sort: false })

  isEqual = search => _.isEqual(queryString.parse(this.stringify()), queryString.parse(search))

  save = (pathname) => {
    if (this.saveScope(this, pathname)) {
      this.saveState();
      return true;
    }

    this.restoreState();
    return false;
  };

  saveState = () => {
    this.prevState = cloneDeep(this.values);
  };

  restoreState = () => {
    if (this.prevState) {
      this.values = this.prevState;
    }
  };
}
